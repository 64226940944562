import React from "react";
import { Stack, Button } from "@mui/material";

const HeroLinks = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Stack direction="row" spacing={4} sx={{ pt: 4 }}>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={() => scrollToSection("features")}
      >
        Features
      </Button>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={() => scrollToSection("pricing")}
      >
        Pricing
      </Button>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={() => scrollToSection("connections")}
      >
        Connections
      </Button>
    </Stack>
  );
};

export default HeroLinks;
