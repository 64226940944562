import React from "react";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import CalendarEventTopStrip from "./CalendarEventTopStrip";
import moment from "moment";

const CalendarEvent = ({ event, setOpenWorkoutView, setSelectedEvent, marginBottom }) => {
  const theme = useTheme();

  const eventStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    width: "100%",
    borderRadius: "5px",
    padding: "0",
    backgroundColor:
      event.eventtype === "activity"
        ? theme.palette.eventActivityBackground.main
        : theme.palette.eventOtherBackground.main,
    cursor: "pointer",
    marginBottom: marginBottom,
    transition: "transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease",
    overflow: "hidden",
  };

  const hoverStyle = {
    backgroundColor:
      event.eventtype === "activity"
        ? theme.palette.eventActivityBackground.hover
        : theme.palette.eventOtherBackground.hover,
    transform: "scale(1.02)",
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
  };

  const handleEventClick = () => {
    const formatDuration = (durationInSeconds) => {
      if (!durationInSeconds) return null;
      const duration = moment.duration(durationInSeconds, 'seconds');
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      return `${hours > 0 ? `${hours}h ` : ""}${minutes}m`;
    };

    setSelectedEvent({
      ...event,
      duration: formatDuration(
        event.eventtype === "activity" ? event.movingTime : event.duration * 60
      ),
      type:
        event.eventtype === "pastworkout" || !event.eventtype
          ? "workout"
          : event.eventtype,
      loads: {
        training_load: event.training_load,
        power_load: Math.round(event.tss),
        pace_load: Math.round(event.pace_load),
        heart_rate_load: Math.round(event.heart_rate_load),
      },
    });
    setOpenWorkoutView(true);
  };

  const InfoStrip = ({ text, color }) => (
    <div
      style={{
        backgroundColor: color,
        color: theme.palette.text.primary,
        padding: "1px 4px",
        fontSize: "0.6rem",
        textAlign: "center",
        fontWeight: "bold",
        textTransform: "uppercase",
      }}
    >
      {text}
    </div>
  );
  const loadValue = Math.round(event.training_load || event.load_edited || 0);
  const loadText = `Load: ${loadValue}`;

  return (
    <div
      style={eventStyle}
      onClick={handleEventClick}
      onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = eventStyle.backgroundColor;
        e.currentTarget.style.transform = "none";
        e.currentTarget.style.boxShadow = "none";
      }}
    >
      <CalendarEventTopStrip event={event} />
      {/* Only show InfoStrip if loadValue is not zero */}
      {loadValue > 0 && (
        <InfoStrip
          text={loadText}
          color={theme.palette.grey[200]}
        />
      )}
      <div style={{ padding: "4px" }}>
        <Typography
          variant="caption"
          display="block"
          component="div"
          style={{
            fontSize: "0.75rem",
            color: theme.palette.text.secondary,
          }}
        >
          {event.title}
        </Typography>
      </div>
    </div>
  );

};

export default CalendarEvent;