import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const ChatProgress = ({ currentStep, totalSteps, currentField }) => {
  const progress = (currentStep / totalSteps) * 100;
  
  const getStepName = (field) => {
    const names = {
      name: 'Name',
      age: 'Age',
      weight: 'Weight',
      number: 'Phone',
      gender: 'Gender'
    };
    return names[field] || field;
  };

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="body2" color="text.secondary">
          Setting up your profile: {getStepName(currentField)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {currentStep}/{totalSteps}
        </Typography>
      </Box>
      <LinearProgress 
        variant="determinate" 
        value={progress}
        sx={{
          height: 8,
          borderRadius: 4,
          '& .MuiLinearProgress-bar': {
            borderRadius: 4
          }
        }}
      />
    </Box>
  );
};

export default ChatProgress;
