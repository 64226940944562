import React from 'react';
import { Box, Typography, ButtonBase, Grid, styled } from '@mui/material';
import { PersonStanding, Bike, Timer, Repeat } from 'lucide-react';

const DisciplineButton = styled(ButtonBase, {
  shouldComponentUpdate: (prop) => prop !== 'isSelected'
})(({ theme, isSelected }) => ({
  width: '100%',
  padding: '12px',
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.divider}`,
  backgroundColor: isSelected ? `${theme.palette.primary.main}10` : theme.palette.background.paper,
  transition: 'all 0.2s ease',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  '&:hover': {
    backgroundColor: isSelected ? `${theme.palette.primary.main}15` : theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    backgroundColor: theme.palette.action.disabledBackground,
  }
}));

const DisciplineSelector = ({ selectedDiscipline, onChange, disabled }) => {
  const disciplines = [
    {
      id: 'Running',
      icon: PersonStanding,
      description: 'Road & Trail Running',
      color: '#2196f3'
    },
    {
      id: 'Cycling',
      icon: Bike,
      description: 'Road & Indoor Cycling',
      color: '#4caf50'
    },
    {
      id: 'Triathlon',
      icon: Timer,
      description: 'Swim, Bike & Run',
      color: '#ff9800'
    },
    {
      id: 'Duathlon',
      icon: Repeat,
      description: 'Run, Bike & Run',
      color: '#9c27b0'
    }
  ];

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        Select Your Discipline
      </Typography>
      <Grid container spacing={1.5}>
        {disciplines.map((discipline) => {
          const Icon = discipline.icon;
          const isSelected = selectedDiscipline === discipline.id;

          return (
            <Grid item xs={12} sm={6} md={3} key={discipline.id}>
              <DisciplineButton
                onClick={() => !disabled && onChange(discipline.id)}
                isSelected={isSelected}
                disabled={disabled}
              >
                <Icon
                  size={20}
                  style={{
                    color: isSelected ? discipline.color : 'inherit'
                  }}
                />
                <Box sx={{ flex: 1, minWidth: 0 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      color: isSelected ? discipline.color : 'text.primary',
                      lineHeight: 1.2
                    }}
                  >
                    {discipline.id}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      display: 'block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {discipline.description}
                  </Typography>
                </Box>
              </DisciplineButton>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default DisciplineSelector;
