import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import moment from "moment";
import axios from "axios";
import {
  CustomCard,
  CustomCardContent,
  Heading,
  InfoText,
  iconStyle,
} from "../style/CustomCard";

const TrendCard = ({ accessToken }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api2.enduroco.in/fitness", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  if (loading) return <Typography>Loading...</Typography>;

  const getLastUpdatedDate = () => {
    if (data && Array.isArray(data) && data.length > 0) {
      return moment(data[0].date).local().format("LLLL");
    }
    return "No Date Available";
  };

  const getTrendIcon = (trend) => {
    if (trend.toLowerCase().includes("increase")) {
      return <TrendingUpIcon color="primary" />; // Regular size
    } else if (trend.toLowerCase().includes("decrease")) {
      return <TrendingDownIcon color="error" />; // Regular size
    } else if (trend.toLowerCase().includes("same")) {
      return <TrendingFlatIcon color="disabled" />; // Regular size
    } else {
      return <FitnessCenterIcon color="secondary" />; // Regular size
    }
  };

  return (
    <CustomCard>
      <CustomCardContent>
        <Heading>
          <FitnessCenterIcon color="primary" style={iconStyle} />
          Fitness Trend
        </Heading>
        {data && Array.isArray(data) ? (
          data.map((item, index) => (
            <Box key={index}>
              {Array.isArray(item.trend) &&
                item.trend.map((trend, idx) => (
                  <InfoText key={idx}>
                    {getTrendIcon(trend)}
                    <span style={{ marginLeft: "4px" }}>{trend}</span>
                  </InfoText>
                ))}
            </Box>
          ))
        ) : (
          <InfoText>No Data Available</InfoText>
        )}
        <Typography variant="caption" align="right">
          As On: {getLastUpdatedDate()}
        </Typography>
      </CustomCardContent>
    </CustomCard>
  );
};

export default TrendCard;
