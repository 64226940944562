import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import axios from "axios";
import { Box, Card, Snackbar, Alert, Button, Modal } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddEventForm from "./AddEventForm";
import { grey } from "@mui/material/colors";
import {
  CustomCard,
  CustomCardContent,
  Heading,
  InfoText,
  iconStyle,
  Subheading,
} from "../style/CustomCard";

const Events = forwardRef((props, ref) => {
  const [events, setEvents] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openAddEventModal, setOpenAddEventModal] = useState(false);
  const deleteAllEvents = async () => {
    try {
      // Assuming you have an API endpoint to delete all events
      const response = await axios.delete("https://api2.enduroco.in/events", {
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      });
      // Refresh the events after deletion
      setEvents([]);
    } catch (err) {
      setErrorMessage(
        err?.response?.data?.error || "Failed to Delete All Events!"
      );
      setShowError(true);
    }
  };

  useImperativeHandle(ref, () => ({
    deleteAllEvents,
  }));
  const fetchEvents = async () => {
    try {
      const response = await axios.get("https://api2.enduroco.in/events", {
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      });
      setEvents(response.data);
    } catch (err) {
      setErrorMessage(err.message || "Failed to fetch events!");
      setShowError(true);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const addEventModalOpenHandler = () => setOpenAddEventModal(true);
  const addEventModalCloseHandler = () => setOpenAddEventModal(false);

  const addEventHandler = async (eventData) => {
    try {
      await axios.post("https://api2.enduroco.in/events", eventData, {
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      });
      setOpenAddEventModal(false);
      await fetchEvents();
      props.onEventAdded(true);
    } catch (err) {
      setErrorMessage(err?.response?.data?.error || "Failed to Add Event!");
      setShowError(true);
    }
  };

  const deleteEventHandler = async (id) => {
    try {
      await axios.delete(`https://api2.enduroco.in/events`, {
        headers: {
          Authorization: `Bearer ${props.accessToken}`,
        },
      });
      await fetchEvents();
    } catch (err) {
      setErrorMessage(err?.response?.data?.error || "Failed to Delete Event!");
      setShowError(true);
    }
  };

  const getDateText = (date) => {
    const eventDate = new Date(date);
    const now = new Date();
    const diffTime = eventDate.getTime() - now.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (Math.abs(diffDays) <= 7) {
      if (diffDays < 0) {
        return `${Math.abs(diffDays)} days ago`;
      } else if (diffDays === 0) {
        return "Today";
      } else {
        return `${diffDays} days to go`;
      }
    } else {
      return `On ${eventDate.toLocaleDateString()}`;
    }
  };

  return (
    <Box sx={{ marginY: 2 }}>
      {events && events.length === 0 && (
        <Card>
          <CustomCardContent>
            <Heading>
              <EmojiEventsIcon color="primary" style={iconStyle} />
              Next Event
            </Heading>
            <InfoText>
              Update your event for personalized workout adjustments.
            </InfoText>

            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={addEventModalOpenHandler}
            >
              Add Event
            </Button>
          </CustomCardContent>
        </Card>
      )}

      {events.map((event) => (
        <Card key={event._id} sx={{ marginTop: 2 }}>
          <CustomCardContent>
            <Heading>
              <EmojiEventsIcon color="primary" style={iconStyle} /> Next Event
            </Heading>
            <div>
              <Subheading>Name: {event.name}</Subheading>
            </div>
            <InfoText>Type: {event.type}</InfoText>
            <InfoText>{getDateText(event.startDateLocal)}</InfoText>
            <InfoText>Distance: {event.subtype}</InfoText>
            <InfoText>{event.description}</InfoText>
          </CustomCardContent>
        </Card>
      ))}

      <Snackbar
        open={showError}
        autoHideDuration={2000}
        onClose={() => setShowError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <Modal
        open={openAddEventModal}
        onClose={addEventModalCloseHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: grey[200],
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <AddEventForm
            onSubmit={addEventHandler}
            onCancel={addEventModalCloseHandler}
          />
        </Box>
      </Modal>
    </Box>
  );
});

export default Events;
