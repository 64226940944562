import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import logo_top from "../../../../src/assets/logo_orange.png";
import useTheme from "@mui/material/styles/useTheme";
// Import font if necessary
import "@fontsource/patrick-hand";

const font = "'Patrick Hand', cursive"; // Your chosen font

const Logo = ({ isAuthenticated }) => {
  // Add isAuthenticated prop
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      className="logo_box"
    >
      <RouterNavLink
        to={isAuthenticated ? "/dashboard" : "/"}
        style={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <img
          src={logo_top}
          alt="Logo"
          style={{
            height: "50px",
            padding: "5px",
          }}
        />
        <Typography
          variant="h6"
          component="span"
          sx={{
            color: "white",
            fontWeight: "bold",
            ml: 1,
            fontFamily: font,
            fontSize: "35px",
            display: { xs: isAuthenticated ? "none" : "block", sm: "block" }, // Conditional display based on authentication
          }}
        >
          Enduro
        </Typography>
        <Typography
          variant="h6"
          component="span"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontFamily: font,
            fontSize: "35px",
            display: { xs: isAuthenticated ? "none" : "block", sm: "block" }, // Conditional display based on authentication
          }}
        >
          Co
        </Typography>
      </RouterNavLink>
    </Box>
  );
};

export default Logo;
