export const formatValidationErrors = (errors) => {
  const errorMessages = [];
  for (const [field, message] of Object.entries(errors)) {
    switch (field) {
      case 'numberofdaysweekly':
        errorMessages.push(`Training Days: ${message}`);
        break;
      case 'weekstart':
        errorMessages.push(`Week Start Day: ${message}`);
        break;
      case 'targetformpct':
        errorMessages.push(`Progression Rate: ${message}`);
        break;
      case 'general':
        errorMessages.push(message);
        break;
      default:
        if (field.match(/^(Mon|Tue|Wed|Thu|Fri|Sat|Sun)$/)) {
          errorMessages.push(`${field}: ${message}`);
        } else {
          errorMessages.push(message);
        }
    }
  }
  return errorMessages;
};

export const getValidationSummary = (errors) => {
  const errorMessages = formatValidationErrors(errors);
  if (errorMessages.length === 0) return "";
  
  if (errorMessages.length === 1) {
    return errorMessages[0];
  }
  
  return `Multiple validation errors:\n- ${errorMessages.join('\n- ')}`;
};
