import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const AddEventForm = (props) => {
  const [eventData, setEventData] = useState({
    name: "",
    startDateLocal: "",
    type: "Running",
    subtype: "",
    description: "",
  });

  useEffect(() => {
    if (eventData.type === "Other") {
      setEventData({ ...eventData, subtype: "" });
    }
  }, [eventData.type]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEventData({ ...eventData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmit(eventData);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const runningDistances = [
    "5k",
    "10k",
    "Half Marathon",
    "Marathon",
    "Ultra Run (60k+)",
    "Ultra Run (flat 60k+)"
  ];
  const cyclingOptions = ["Race", "Ultra"];
  const triathlonDistances = ["Sprint", "Half", "Full"];

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h5" mb={2}>
        Add Event
      </Typography>
      <TextField
        required
        label="Event Name"
        name="name"
        value={eventData.name}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        required
        type="date"
        label="Event Date"
        name="startDateLocal"
        value={eventData.startDateLocal}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl fullWidth sx={{ mb: 2 }} required>
        <InputLabel id="event-type-label">Event Type</InputLabel>
        <Select
          labelId="event-type-label"
          id="event-type"
          label="Event Type"
          name="type"
          value={eventData.type}
          onChange={handleChange}
        >
          <MenuItem value="Running">Running</MenuItem>
          <MenuItem value="Cycling">Cycling</MenuItem>
          <MenuItem value="Triathlon">Triathlon</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </FormControl>
      {eventData.type !== "Other" && (
        <FormControl fullWidth sx={{ mb: 2 }} required>
          <InputLabel id="event-subtype-label">Distance</InputLabel>
          <Select
            labelId="event-subtype-label"
            id="event-subtype"
            name="subtype"
            label="Distance"
            value={eventData.subtype}
            onChange={handleChange}
          >
            {eventData.type === "Running" &&
              runningDistances.map((distance) => (
                <MenuItem key={distance} value={distance}>
                  {distance}
                </MenuItem>
              ))}
            {eventData.type === "Cycling" &&
              cyclingOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            {eventData.type === "Triathlon" &&
              triathlonDistances.map((distance) => (
                <MenuItem key={distance} value={distance}>
                  {distance}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      <Grid container spacing={2} justifyContent="flex-end">
        <Grid >
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid >
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddEventForm;
