import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { Paper } from "@mui/material";
import PoolIcon from "@mui/icons-material/Pool";

const { fromJS } = require("immutable");
const Label = styled("span")(({ theme }) => ({
  color: "#757575",
  fontSize: "18px",
  fontWeight: "bold",
  textTransform: "uppercase",
  display: "block",
  textAlign: "center",
  width: "100%",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const HighlightedToggleButton = styled(ToggleButton)(
  ({ theme, value, selected }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: selected
        ? theme.palette.primary.main
        : theme.palette.action.selected,
      color: theme.palette.common.white,
      "& .MuiSvgIcon-root": {
        fill: theme.palette.common.white,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&": {
      backgroundColor: value === selected ? theme.palette.primary.main : "",
      color:
        value === selected
          ? theme.palette.common.white
          : theme.palette.text.primary,
    },
    justifyContent: "flex-start",
    width: "100%",
    display: "flex",
  })
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
}));

const IconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "50px",
}));

const TextWrapper = styled("div")(({ theme }) => ({
  marginLeft: "55px",
}));

// Create a custom component to display dual icons for Duathlete
const DuathleteIcon = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <DirectionsBikeIcon style={{ marginRight: 4 }} />
    <DirectionsRunIcon />
  </div>
);
const TriathleteIcon = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <DirectionsBikeIcon style={{ marginRight: 4 }} />
    <DirectionsRunIcon />
    <PoolIcon />
  </div>
);

export default function SelectDiscipline({
  userProfile,
  handleChangeUserProfile,
}) {
  const defaultCheckedOptions = fromJS({
    ridePower: false,
    ridePowerHr: false,
    rideHR: false,
    runPower: false,
    runPace: false,
    runHr: false,
  });

  useEffect(() => {
    if (userProfile) {
      setCheckedOptions(
        fromJS({
          ridePower: userProfile.bikepowerwo && !userProfile.bikehrwo,
          ridePowerHr: userProfile.bikehrwo && userProfile.bikepowerwo,
          rideHR: userProfile.bikehrwo && !userProfile.bikepowerwo,
          runPower: userProfile.runpowerwo,
          runPace: userProfile.runpacewo && !userProfile.runpowerwo,
          runHr:
            userProfile.runhrwo &&
            !userProfile.runpowerwo &&
            !userProfile.runpacewo,
        })
      );
    }
  }, [userProfile]);

  const [checkedOptions, setCheckedOptions] = useState(fromJS({}));

  // Rest of your component logic...
  const athleteTypeMapping = {
    Bike: "Cycling",
    Run: "Running",
    Duathlete: "Duathlon",
    Triathlete: "Triathlon",
  };
  const inverseMapping = Object.entries(athleteTypeMapping).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key }),
    {}
  );

  if (!userProfile) {
    return <div>Loading...</div>; // Show loading message or any other placeholder
  }

  const athleteTypeIcons = {
    Cycling: <DirectionsBikeIcon />,
    Running: <DirectionsRunIcon />,
    Duathlon: <DuathleteIcon />, // Use the custom dual icon for Duathlete
    Triathlon: <TriathleteIcon />,
  };

  //
  return (
    <div>
      <StyledToggleButtonGroup
        orientation="vertical"
        value={athleteTypeMapping[userProfile.athletetype]}
        exclusive
        onChange={(event, newAthleteType) => {
          if (newAthleteType !== null) {
            // Convert display name back to internal type for backend
            const backendType = Object.keys(athleteTypeMapping).find(
              (key) => athleteTypeMapping[key] === newAthleteType
            );
            handleChangeUserProfile({
              email: userProfile.email,
              athletetype: backendType,
            });
          }
        }}
        aria-label="athlete type"
      >
        {Object.entries(athleteTypeMapping).map(([key, label]) => (
          <HighlightedToggleButton
            key={key}
            value={label}
            aria-label={key.toLowerCase()}
            selected={athleteTypeMapping[userProfile.athletetype] === label}
          >
            <IconWrapper>{athleteTypeIcons[label]}</IconWrapper>
            <TextWrapper>{label}</TextWrapper>
          </HighlightedToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </div>
  );
}
