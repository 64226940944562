import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';  // Importing useAuth0 to get the token

const GarminConnect = ({ userProfile, onRefresh }) => {
    const [loading, setLoading] = useState(false);
    const { getIdTokenClaims } = useAuth0();  // Use the useAuth0 hook to get the identity token claims

    const handleGarminConnect = async () => {
        setLoading(true);
        try {
            const tokenClaims = await getIdTokenClaims();  // Get the ID token claims
            const accessToken = tokenClaims.__raw;  // Extract the raw access token

            const response = await fetch('https://api2.enduroco.in/thirdpartyconnection/get-garmin-auth-url', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,  // Use the accessToken for authorization
                },
            });
            const data = await response.json();
            if (data && data.authUrl) {
                window.location.href = data.authUrl;  // Redirect user to Garmin auth URL
            } else {
                throw new Error('Failed to retrieve Garmin auth URL');
            }
        } catch (error) {
            console.error('Error connecting to Garmin:', error);
        } finally {
            setLoading(false);
        }
    };

    const disconnectService = async (name) => {
        console.log(`Attempting to disconnect service: ${name}`);
        setLoading(true);
        try {
            const tokenClaims = await getIdTokenClaims();  // Get the ID token claims
            const accessToken = tokenClaims.__raw;  // Extract the raw access token

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ type: name }),
                redirect: "follow",
            };

            const response = await fetch('https://api2.enduroco.in/thirdpartyconnection/remove', requestOptions);
            const result = await response.json();
            console.log(`${name} disconnected successfully`, result);
            onRefresh();
        } catch (error) {
            console.error(`Error disconnecting ${name}`, error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <FormControlLabel
                control={
                    <Switch
                        checked={userProfile?.garminauth || false}  // Optional chaining for safety
                        onChange={() => userProfile?.garminauth
                            ? disconnectService("garmin")
                            : handleGarminConnect()
                        }
                        name="garminSwitch"
                        color="primary"
                        disabled={loading}
                    />
                }
                label=""
                style={{ marginRight: "10px" }}
            />
        </div>
    );
};

export default GarminConnect;
