import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem, TextField
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import PhoneInput from "react-phone-input-2";
import EditableTextField from "../components/EditableTextField";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone"; // Import moment-timezone
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const UserProfileSection = ({ userProfile, onChange, user, onClose }) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [editedName, setEditedName] = useState(userProfile.name);
  const [editedNickname, setEditedNickname] = useState(userProfile.nickname);
  const [editedTimezone, setEditedTimezone] = useState(userProfile.timezone);

  const navigate = useNavigate();

  const handlePhoneNumberChange = (phone) => {
    onChange({ number: phone });
  };

  const handleSaveName = () => {
    onChange({ ...userProfile, name: editedName });
    setIsEditingName(false);
  };

  const handleSaveNickname = () => {
    onChange({ ...userProfile, nickname: editedNickname });
    setIsEditingNickname(false);
  };
  const navigateToProfile = () => {
    navigate("/profile");
  };
  const getTimezoneOffset = (tz) => {
    return moment.tz(tz).utcOffset();
  };
  const logoutButtonStyle = {
    // Rest of the styles
    color: "black",
    borderColor: "white", // Same for the border color if it's an outlined button
    marginTop: "20px",
  };
  const logoutSectionStyle = {
    position: "relative",
    bottom: "20px", // Position it at the bottom of the modal
    left: "50%", // Center it horizontally
    transform: "translateX(-50%)", // Adjust for exact centering
    width: "100%", // Full width to contain the button
    textAlign: "center", // Center the button within the section
    padding: "10px 20px", // Add some padding around the button
  };
  const { logout } = useAuth0();
  const handleLogout = () => {
    const logoutUrl = "https://www.enduroco.in";
    // const logoutUrl = window.location.origin;
    logout({ returnTo: logoutUrl });
  };

  // Group timezones by their offset
  const groupedTimezones = moment.tz.names().reduce((acc, tz) => {
    const offset = getTimezoneOffset(tz);
    acc[offset] = acc[offset] || [];
    acc[offset].push(tz);
    return acc;
  }, {});
  // Function to format timezone names with their current offset
  const getTimezoneNameWithOffset = (tz) => {
    return `GMT${moment.tz(tz).format("Z")}`;
  };

  // Sort groups by offset and pick one timezone per offset
  const sortedUniqueTimezones = Object.keys(groupedTimezones)
    .sort((a, b) => parseInt(a) - parseInt(b)) // Sort keys (offsets) numerically
    .map((offset) => {
      // For each group, pick the first timezone (or any other logic)
      const tz = groupedTimezones[offset][0];
      return getTimezoneNameWithOffset(tz);
    });

  const handleTimezoneChange = (event) => {
    const newTimezone = event.target.value;
    setEditedTimezone(newTimezone);
    onChange({ ...userProfile, timezone: newTimezone });
  };
  return (
    <Grid container spacing={2} sx={{ alignItems: "center" }}>
      <Grid xs={12} sx={{ textAlign: "center" }}>
        <div onClick={() => navigate("/profile")} style={{ cursor: "pointer" }}>
          {user.picture ? (
            <img src={user.picture} alt="Profile" style={profileImageStyle} />
          ) : (
            <AccountCircleIcon sx={profileIconStyle} />
          )}
        </div>
      </Grid>
      <Grid xs={3}>
        <Label>Name</Label>
      </Grid>
      <Grid xs={9}>
        {isEditingName ? (
          <TextField
            fullWidth
            variant="outlined"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            autoFocus
          />
        ) : (
          <Typography
            variant="h6"
            sx={{ cursor: "pointer" }}
            onClick={() => setIsEditingName(true)}
          >
            {userProfile.name}
          </Typography>
        )}
        {isEditingName && (
          <Button
            onClick={handleSaveName}
            color="primary"
            variant="outlined"
            startIcon={<SaveIcon />}
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        )}
      </Grid>
      <Grid xs={3}>
        <Label>Nickname</Label>
      </Grid>
      <Grid xs={9}>
        {isEditingNickname ? (
          <TextField
            fullWidth
            variant="outlined"
            value={editedNickname}
            onChange={(e) => setEditedNickname(e.target.value)}
          />
        ) : (
          <Typography
            variant="subtitle1"
            sx={{ cursor: "pointer" }}
            onClick={() => setIsEditingNickname(true)}
          >
            {userProfile.nickname}
          </Typography>
        )}
        {isEditingNickname && (
          <Button
            onClick={handleSaveNickname}
            color="primary"
            variant="outlined"
            startIcon={<SaveIcon />}
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        )}
      </Grid>
      <Grid xs={3}>
        <Label>Email</Label>
      </Grid>
      <Grid xs={9}>
        {userProfile.email}
      </Grid>
      <Grid xs={3}>
        <Label>Mobile</Label>
      </Grid>
      <Grid xs={9}>
        <PhoneInput
          country={"in"}
          disableDropdown={true}
          value={userProfile.number}
          onChange={handlePhoneNumberChange}
        />
      </Grid>
      {/* Conditionally display timezone if available */}
      {userProfile.timezone && (
        <React.Fragment>
          <Grid xs={3}>
            <Label>Timezone</Label>
          </Grid>
          <Grid xs={9}>
            <FormControl fullWidth>
              <Select
                labelId="timezone-select-label"
                id="timezone-select"
                value={editedTimezone || ""}
                onChange={handleTimezoneChange}
                displayEmpty
              >
                {sortedUniqueTimezones.map((tzWithOffset, index) => (
                  <MenuItem key={index} value={tzWithOffset}>
                    {tzWithOffset}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </React.Fragment>
      )}
      <div style={logoutSectionStyle}>
        <Button
          variant="outlined"
          style={logoutButtonStyle}
          onClick={handleLogout}
          startIcon={<PowerSettingsNewIcon />}
        >
          Logout
        </Button>
      </div>
    </Grid>
  );
};
export default UserProfileSection;

// Styles for profile image and icon
const Label = styled(Typography)({
  color: "#757575",
  fontSize: "16px",
  textTransform: "uppercase",
  fontWeight: "bold",
});

const profileImageStyle = {
  height: "80px", // Larger size
  width: "80px",
  borderRadius: "50%",
  marginBottom: "10px",
};

const profileIconStyle = {
  fontSize: "80px", // Larger size for icon
  marginBottom: "10px",
};
