import { useState, useEffect } from 'react';
import { isFieldComplete } from '../../utils/validation';

const useChatState = (steps, currentProfile, promptTemplates, successMessages) => {
    const [messages, setMessages] = useState([]);
    const [updates, setUpdates] = useState({});
    const [currentStep, setCurrentStep] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized && currentProfile) {
            const initialSummary = generateSummary(currentProfile);
            const initialUpdates = {};
            let skipStrava = false;

            if (currentProfile?.stravaConnected && steps.includes('strava_connect')) {
                skipStrava = true;
            }
            
            const firstIncompleteIndex = steps.findIndex(step => {
                if (step === 'strava_connect') {
                    return !skipStrava && !currentProfile?.stravaConnected;
                }
                return !isFieldComplete(currentProfile?.[step]);
            });
            
            setMessages([{ 
                role: 'assistant', 
                content: initialSummary
            }]);
            
            steps.forEach(step => {
                if (step !== 'strava_connect' && isFieldComplete(currentProfile?.[step])) {
                    initialUpdates[step] = currentProfile[step];
                }
            });

            setUpdates(initialUpdates);
            
            if (firstIncompleteIndex === -1 || 
                (skipStrava && firstIncompleteIndex === steps.indexOf('strava_connect'))) {
                setCurrentStep(steps.length);
                setIsComplete(true);
            } else {
                setCurrentStep(firstIncompleteIndex);
            }
            
            setInitialized(true);
        }
    }, [currentProfile, initialized, promptTemplates, steps]);

    const generateSummary = (profile) => {
        const completedFields = [];
        const remainingFields = [];

        // Helper function to format training approach details
        const formatTrainingDetails = () => {
            if (!profile.workoutVolume?.settingEnabled) return null;

            const method = profile.workoutVolume.volumeSelectionMethod;
            if (method === 'manual') {
                const settings = profile.workoutVolume.manual;
                return `Training Approach: Custom (Fixed Days - ${settings.numberofdaysweekly} days/week)`;
            } else if (method === 'timebased') {
                return 'Training Approach: Custom (Duration Based)';
            }
            return null;
        };

        // Helper function to format event details
        const formatEventDetails = () => {
            if (!profile.event?.name) return null;
            return `Training Approach: Event Based (${profile.event.name} - ${profile.event.type} ${profile.event.subtype})`;
        };

        steps.forEach(step => {
            if (step === 'strava_connect') {
                if (profile?.stravaConnected) {
                    completedFields.push('Strava connection');
                } else {
                    remainingFields.push('Strava connection');
                }
            } else if (step === 'training_approach') {
                const trainingDetails = formatEventDetails() || formatTrainingDetails();
                if (trainingDetails) {
                    completedFields.push(trainingDetails);
                } else {
                    remainingFields.push('Training approach');
                }
            } else if (isFieldComplete(profile?.[step])) {
                const value = profile[step];
                switch(step) {
                    case 'name':
                        completedFields.push(`Name: ${value}`);
                        break;
                    case 'age':
                        completedFields.push(`Age: ${value} years`);
                        break;
                    case 'weight':
                        completedFields.push(`Weight: ${value}kg`);
                        break;
                    case 'gender':
                        completedFields.push(`Gender: ${value}`);
                        break;
                    // Skip the dependent fields if they're not relevant
                    case 'event_details':
                    case 'training_method':
                    case 'fixed_days_settings':
                    case 'time_based_settings':
                        // These will be handled by training_approach
                        break;
                    default:
                        const fieldName = step.charAt(0).toUpperCase() + step.slice(1).replace(/_/g, ' ');
                        completedFields.push(`${fieldName}: ${value}`);
                }
            } else if (!['event_details', 'training_method', 'fixed_days_settings', 'time_based_settings'].includes(step)) {
                const fieldName = step.charAt(0).toUpperCase() + step.slice(1).replace(/_/g, ' ');
                remainingFields.push(fieldName);
            }
        });

        let summary = "👋 Welcome back! Here's what I have for your profile:\n\n";
        
        if (completedFields.length > 0) {
            summary += "✅ Completed:\n• " + completedFields.join("\n• ") + "\n\n";
        }
        
        if (remainingFields.length > 0) {
            summary += "📝 Still needed:\n• " + remainingFields.join("\n• ");
            summary += "\n\nLet's complete your profile! ";
            
            const firstMissingField = steps.find(step => 
                step === 'strava_connect' ? !profile?.stravaConnected : !isFieldComplete(profile?.[step])
            );
            if (firstMissingField) {
                summary += promptTemplates[firstMissingField];
            }
        } else {
            summary += "🎉 Your profile is complete! Let me know if you need to update anything.";
            setIsComplete(true);
        }

        return summary;
    };

    return {
        messages,
        setMessages,
        updates,
        setUpdates,
        currentStep,
        setCurrentStep,
        isComplete,
        setIsComplete
    };
};

export default useChatState;
