import React, { useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import testimonialsData from "./data/bikefit/get_social_cards.json"; // Adjust the path as necessary

const testimonials = testimonialsData.result.pagination_data;

const TestimonialCard = ({ testimonial }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleReadMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid >
            <Avatar sx={{ bgcolor: deepOrange[500] }}>
              {testimonial.author_name.charAt(0)}
            </Avatar>
          </Grid>
          <Grid xs>
            <Typography variant="h6" component="h2">
              {testimonial.author_name}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body2" color="text.secondary" component="div">
          {isExpanded ? (
            testimonial.message_content.split(/<br\/?>/g).map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))
          ) : (
            <span>
              {testimonial.message_content
                .split(/<br\/?>/g)
                .slice(0, 1)
                .map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              ...
            </span>
          )}
        </Typography>
        <Button onClick={handleReadMoreClick}>
          {isExpanded ? "Read Less" : "Read More"}
        </Button>
      </CardContent>
    </Card>
  );
};

const TestimonialsPage = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Sanjeev Sharma's Bike Fitting Excellence
      </Typography>
      <Typography paragraph>
        Welcome to the home of precision and performance in cycling. Sanjeev
        Sharma brings years of expertise and a meticulous approach to bike
        fitting, ensuring every rider achieves their optimal alignment for
        comfort, efficiency, and speed. Experience the difference that a
        professional bike fit can make in your cycling journey.
      </Typography>
      <Grid container spacing={2}>
        {testimonials.map((testimonial) => (
          <Grid xs={12} sm={6} md={4} key={testimonial.uuid}>
            <TestimonialCard testimonial={testimonial} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TestimonialsPage;
