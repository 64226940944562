import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { Save } from 'lucide-react';

const FloatingSaveButton = ({ onSave, isSaving, hasChanges }) => {
  if (!hasChanges) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 1000
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={onSave}
        disabled={isSaving}
        startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : <Save size={20} />}
        sx={{
          boxShadow: 3,
          '&:hover': {
            boxShadow: 5,
          }
        }}
      >
        {isSaving ? 'Saving...' : 'Save Changes'}
      </Button>
    </Box>
  );
};

export default FloatingSaveButton;
