import React from 'react';
import { Alert, Box, Button, CircularProgress } from '@mui/material';
import axios from 'axios';

const MigrationBanner = ({ accessToken, userEmail, onMigrationComplete }) => {
  const [isMigrating, setIsMigrating] = React.useState(false);
  const [error, setError] = React.useState(null);

  const verifyMigrationStatus = async () => {
    try {
      const response = await axios.get(
        'https://api2.enduroco.in/settings',
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          params: { email: userEmail }
        }
      );

      if (response.data?.profile?.isMigrated) {
        if (onMigrationComplete) {
          onMigrationComplete();
        }
      }
    } catch (error) {
      console.error('Failed to verify migration status:', error);
    }
  };

  const handleMigrate = async () => {
    setIsMigrating(true);
    setError(null);

    try {
      await axios.patch(
        'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
        {
          profile: {
            isMigrated: true
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          params: { email: userEmail }
        }
      );

      // Poll for migration status every 2 seconds for up to 30 seconds
      let attempts = 0;
      const maxAttempts = 15;
      const pollInterval = setInterval(async () => {
        attempts++;
        await verifyMigrationStatus();

        if (attempts >= maxAttempts) {
          clearInterval(pollInterval);
          setError('Migration taking longer than expected. Please refresh the page.');
          setIsMigrating(false);
        }
      }, 2000);

      // Clear interval if component unmounts
      return () => clearInterval(pollInterval);

    } catch (error) {
      console.error('Migration failed:', error);
      setError(error.response?.data?.error || 'Migration failed. Please try again.');
      setIsMigrating(false);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Alert
        severity={error ? "error" : "warning"}
        action={
          <Button
            color="inherit"
            size="small"
            onClick={handleMigrate}
            disabled={isMigrating}
            startIcon={isMigrating ? <CircularProgress size={16} /> : null}
          >
            {isMigrating ? 'Migrating...' : 'Migrate Now'}
          </Button>
        }
      >
        {error || 'To access all new features and improvements, we need to update your account to our new system.'}
      </Alert>
    </Box>
  );
};

export default MigrationBanner;