import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import {
  Alert,
  Button,
  Snackbar,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Dialog,
  DialogActions,
  DialogTitle, useMediaQuery
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Loading from "../../components/Loading";
import JoinClubButton from "./components/JoinClubButton";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Link } from "@mui/material";
import ClipboardCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import PoolIcon from '@mui/icons-material/Pool';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ClubDetails = ({ accessToken }) => {
  const { clubId } = useParams();
  const [searchParams] = useSearchParams();
  const secret = searchParams.get('secret') === 'true'; // Correctly fetch 'secret' parameter from URL
  const [clubInfo, setClubInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'fitness', direction: 'descending' });

  const navigate = useNavigate();
  const theme = useTheme();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchClubDetails();
  }, [accessToken, clubId, secret]);

  const fetchClubDetails = async () => {
    if (!accessToken) {
      setError("Access token is missing.");
      return;
    }

    try {
      const queryParam = secret ? 'secret=true' : 'details=true';
      const response = await axios.get(
        `https://api2.enduroco.in/clubs/${clubId}?${queryParam}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setClubInfo(response.data);
      setError(null);
    } catch (error) {
      setError("Failed to fetch data. Please try again.");
      navigate("/")
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  const compare = (a, b, isAsc, stringnum = false,) => {
    // console.log({ a, b, isAsc, stringnum },)
    if (stringnum) {
      a = a || 0; // Fallback to 0 if null or undefined
      b = b || 0; // Fallback to 0 if null or undefined
      a = parseFloat(a); // Convert string to float
      b = parseFloat(b); // Convert string to float
    } else {
      // Convert strings to lowercase for case-insensitive comparison
      if (typeof a === 'string' && typeof b === 'string') {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }
    }

    if (a < b) {
      return isAsc ? -1 : 1;
    }
    if (a > b) {
      return isAsc ? 1 : -1;
    }
    return 0;
  };
  function compareDates(a, b, isAsc) {
    // Default date set to one month ago
    const defaultDate = new Date();
    defaultDate.setMonth(defaultDate.getMonth() - 1);

    // Get the last workout date from each object, or use the default date
    const dateA = new Date(a.fitnessFatigue?.lastWorkoutDate || defaultDate);
    const dateB = new Date(b.fitnessFatigue?.lastWorkoutDate || defaultDate);

    // Compare dates
    return compare(dateA, dateB, isAsc, false);
  }

  const sortedUsers = React.useMemo(() => {
    let sortableItems = [...(clubInfo.users || [])];
    sortableItems.sort((a, b) => {
      const isAsc = sortConfig.direction === 'ascending';
      const isDes = sortConfig.direction === 'descending';
      switch (sortConfig.key) {
        case 'nickname':
          return compare(a.nickname, b.nickname, isAsc, false);
        case 'fitness':
          return compare(a.fitnessFatigue?.fitness, b.fitnessFatigue?.fitness, isAsc, true);
        case 'lastActive':
          return compareDates(a, b, isAsc, false);
        default:
          return 0;
      }
    });
    return sortableItems;
  }, [clubInfo.users, sortConfig]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setSnackOpen(true);
  };
  const handleCloseSnack = () => {
    setSnackOpen(false);
  };
  const handleDeleteClub = async () => {
    try {
      await axios.delete(`https://api2.enduroco.in/clubs/${clubId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      navigate("/clubs"); // Redirect after deletion
    } catch (error) {
      setError("Failed to delete the club. Please try again.");
    }
  };

  const openDeleteDialog = () => {
    setOpenDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDialog(false);
  };
  const formatDate = (fitnessFatigue) => {
    if (!fitnessFatigue) return "N/A";

    const { date, lastWorkoutDate } = fitnessFatigue;

    // Check for specific invalid text in lastWorkoutDate
    if (lastWorkoutDate === "No non-zero dates found.") {
      return "Never"; // Return "Never" if the last workout date is not found
    }

    // Use lastWorkoutDate if available, otherwise fallback to using date
    const referenceDate = lastWorkoutDate || date;
    const asterisk = lastWorkoutDate ? "" : "*"; // Add an asterisk if using the date instead of lastWorkoutDate

    if (referenceDate === null || referenceDate === "null" || referenceDate === "") {
      return "N/A";
    }

    const refDate = new Date(referenceDate);
    if (isNaN(refDate.getTime())) { // Check for invalid date
      return "Invalid date";
    }

    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const refDayStart = new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate());

    // Calculate the difference in days
    const dayDifference = (todayStart - refDayStart) / (1000 * 3600 * 24);

    if (dayDifference < 1) {
      return `today${asterisk}`;
    } else if (dayDifference < 2) {
      return `yesterday${asterisk}`;
    } else {
      return `${Math.floor(dayDifference)} days ago${asterisk}`;
    }
  };

  const getFitnessColor = (fitness, fatigue) => {
    if (!fitness || !fatigue) return 'inherit'; // default color if data is missing
    const fatiguePercentage = ((fitness - fatigue) / fitness) * 100;

    if (fatiguePercentage < -30) {
      return '#f44336'; // bright red
    } else if (fatiguePercentage < -10) {
      return '#FFC107'; // amber
    } else {
      return '#4caf50'; // bright green
    }

  };
  // Existing logic to calculate based on the 'date' property
  const formatDateUsingDate = (fitnessFatigue) => {
    const { date } = fitnessFatigue;
    if (date === null || date === "null" || date === "") {
      return "N/A";
    }

    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return "Invalid date";
    }

    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
    const dateStart = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate()).getTime();

    const dayDifference = (todayStart - dateStart) / (1000 * 3600 * 24);

    if (dayDifference < 0) {
      return "in the future";
    } else if (dayDifference < 1) {
      return "today";
    } else if (dayDifference < 2) {
      return "yesterday";
    } else {
      return `${Math.floor(dayDifference)} days ago`;
    }
  };
  const DeleteButton = styled(Button)`
  min-width: 80px;
  font-size: 1rem;
  padding: 10px 10px;
  margin: 8px;
  border: 2px solid red; /* Set red border */
  color: red; 

  &:hover {
    background-color: rgba(255, 0, 0, 0.1); /* Light red hover background */
    border-color: darkred; /* Slightly darker red on hover */
  }
`;
  const tableRowStyle = {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.getContrastText(theme.palette.warning.dark),
  };
  const currentUserRowStyle = {
    backgroundColor: theme.palette.action.selected, // or any color that suits your design
  };

  const buttonStyle = {
    minWidth: '80px',
    fontSize: '1rem',
    padding: '10px 10px',
    margin: '8px'
  };

  const headerCellStyle = {
    padding: "8px 16px",
    verticalAlign: "middle",
    fontWeight: "bold", // Bold for headers
    textTransform: "uppercase",
  };

  const bodyCellStyle = {
    padding: "8px 16px",
    verticalAlign: "middle",
    fontWeight: "normal", // Regular weight for body cells
  };
  const athleteTypeIcons = {
    Run: <DirectionsRunIcon style={{ fontSize: '16px' }} />,
    Bike: <DirectionsBikeIcon style={{ fontSize: '16px' }} />,
    Duathlete: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DirectionsBikeIcon style={{ marginRight: 2, fontSize: '16px' }} />
        <DirectionsRunIcon style={{ fontSize: '16px' }} />
      </div>
    ),
    Triathlete: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DirectionsBikeIcon style={{ marginRight: 2, fontSize: '16px' }} />
        <DirectionsRunIcon style={{ fontSize: '16px' }} />
        <PoolIcon style={{ fontSize: '16px' }} />
      </div>
    )
  };
  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!accessToken) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" color="error" align="center" gutterBottom>
          Access token is missing.
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" color="error" align="center" gutterBottom>
          {error}
        </Typography>
      </Container>
    );
  }

  // Render club details
  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 6 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link component={RouterLink} to="/">Home</Link>
        <Link component={RouterLink} to="/clubs">Clubs</Link>
        <Typography color="text.primary">{clubInfo.name || "Club Details"}</Typography>
      </Breadcrumbs>
      <Paper elevation={0} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h4" align="center">
          {clubInfo.name || "Club Details"}
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary">
          {clubInfo.description || "No description available."}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', flexWrap: 'wrap', marginTop: '1rem' }}>
          <Button
            variant="outlined"
            startIcon={<ClipboardCopyIcon />}
            onClick={handleCopyLink}
            sx={buttonStyle}
          >
            Share
          </Button>
          <JoinClubButton
            accessToken={accessToken}
            clubId={clubId}
            isMember={clubInfo.isCurrentUserAMember}
            onMembershipChange={fetchClubDetails}
          // Here we pass the buttonStyle directly to JoinClubButton
          // assuming it accepts and applies it in its own `sx` prop.
          />
          {clubInfo.isOwner && (
            <DeleteButton
              startIcon={<DeleteIcon />}
              variant="outlined" // Important: Specify 'outlined' variant 
              onClick={openDeleteDialog}
            >
              Delete Club
            </DeleteButton>
          )}
        </div>
      </Paper>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent="Slide"
      >
        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>
      <Typography variant="h6" align="center" sx={{ mt: 4 }}>
        Club Members
      </Typography>
      <TableContainer component={Paper} elevation={3}>
        <Table
          sx={{ minWidth: 300, maxWidth: 600 }}
          aria-label="fitness data table"
        >
          <TableHead>
            <TableRow sx={tableRowStyle}>
              <TableCell sx={headerCellStyle}>#</TableCell>
              <TableCell onClick={() => requestSort('nickname')}>
                Nickname {sortConfig.key === 'nickname' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
              </TableCell>              <TableCell sx={headerCellStyle}></TableCell>
              <TableCell onClick={() => requestSort('fitness')}>
                Fitness {sortConfig.key === 'fitness' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
              </TableCell>
              <TableCell onClick={() => requestSort('lastActive')} align="right">
                Last Active {sortConfig.key === 'lastActive' ? (sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers.length > 0 ? sortedUsers.map((user, index) => (
              <TableRow key={user.userId} sx={user.isCurrentUser ? currentUserRowStyle : {}}>
                <TableCell sx={bodyCellStyle}>{index + 1}</TableCell>
                <TableCell component="th" scope="row" sx={bodyCellStyle}>
                  {user.nickname}
                </TableCell>
                <TableCell component="th" scope="row" sx={bodyCellStyle}>
                  {athleteTypeIcons[user.athletetype]}
                </TableCell>
                <TableCell align="right" sx={{
                  ...bodyCellStyle,
                  color: getFitnessColor(user.fitnessFatigue?.fitness, user.fitnessFatigue?.fatigue),
                  fontSize: '16px'
                }}>
                  {user.fitnessFatigue?.fitness || 0}
                </TableCell>
                <TableCell align="right" sx={bodyCellStyle}>
                  {formatDate(user.fitnessFatigue)}
                </TableCell>
                {secret && (
                  <TableCell align="right" sx={bodyCellStyle}>
                    {user.number && user.number.length > 2 ? user.number.slice(2) : "N/A"}
                  </TableCell>
                )}
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={matchesSmallScreen ? 3 : 4} align="center">No users found for this club.</TableCell>
              </TableRow>
            )}
          </TableBody>

        </Table>
      </TableContainer>


      <Dialog open={openDialog} onClose={closeDeleteDialog}>
        <DialogTitle>
          {"Are you sure you want to delete this club?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteClub} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default withAuthenticationRequired(ClubDetails, {
  onRedirecting: () => <Loading />,
  returnTo: "/login",
});
