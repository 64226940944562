import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";

import Hero from "../components/Hero";
import Content from "../components/Content";

import { useAuth0 } from "@auth0/auth0-react";

function Home(props) {
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated) return <Navigate to={"/dashboard"} />;
  else
    return (
      <Fragment>
        <Hero />
        <Content />
      </Fragment>
    );
}
export default Home;
