import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2"; // Make sure your project supports Unstable_Grid2
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import StravaIcon from "@mui/icons-material/Whatshot"; // Placeholder for Strava icon
import TPIcon from "@mui/icons-material/FitnessCenter"; // Placeholder for TrainingPeaks icon

// Define brand colors
const brandColors = {
  strava: "#FC4C02",
  trainingPeaks: "#12b461", // Placeholder color for TrainingPeaks
};

const steps = [
  {
    title: "Connect with Strava",
    details:
      'To sync your activities, select "Connect" for Strava in the settings and authorize EnduroCo to access your account. You’ll be guided to Strava for authorization.',
    url: "https://www.strava.com/oauth/authorize?client_id=41820&response_type=code&redirect_uri=http://www.enduroco.in%2Fthirdpartyauth2&approval_prompt=force&scope=activity:read,activity:read_all,activity:write",
    icon: <StravaIcon />,
    color: "strava",
    optional: false,
  }
];

// Placeholder function for demonstration
const handleConnect = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export default function SetupStepperMobile({ userProfile }) {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  useEffect(() => {
    if (userProfile) {
      if (!userProfile.stravaauth) setActiveStep(0);
      else if (!userProfile.trainingpeaksauth) setActiveStep(1);
      else setActiveStep(2);
    }
  }, [userProfile]);

  if (activeStep >= maxSteps) {
    return null;
  }

  const currentStep = steps[activeStep];
  const buttonColor = brandColors[currentStep.color];

  // Determine the title text based on whether the step is optional
  const stepTitle = currentStep.optional
    ? `Optional Step ${activeStep + 1}`
    : `Just a Few More Steps (${activeStep + 1}/${maxSteps})`;

  return (
    <Grid
      xs={12}
      md={12}
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Paper
        elevation={3}
        variant="elevation"
        sx={{
          bgcolor: "grey.100",
          fontFamily: "Roboto, sans-serif",
          borderRadius: 2,
          width: "100%",
          p: 2,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Typography variant="h5" gutterBottom>
            {stepTitle}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            {currentStep.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {currentStep.details}
          </Typography>
          <Button
            startIcon={currentStep.icon}
            variant="contained"
            sx={{
              bgcolor: buttonColor,
              color: "white",
              "&:hover": {
                bgcolor: buttonColor,
                opacity: 0.85,
              },
              mb: 2,
              borderRadius: 1,
            }}
            onClick={() => handleConnect(currentStep.url)}
          >
            Connect
          </Button>
        </Box>
      </Paper>
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{
          width: "100%",
          justifyContent: "center",
          background: "transparent",
          pt: 2,
        }}
      />
    </Grid>
  );
}
