import React from 'react';
import { Box } from '@mui/material';
import WorkoutPlanningOptions from './WorkoutPlanningOptions';
import WorkoutUpdateStatus from './WorkoutUpdateStatus';
import EventSection from './EventSection';
import WorkoutSettingsSection from './WorkoutSettingsSection';
import ConfirmEventDeleteDialog from './ConfirmEventDeleteDialog';

const WorkoutPlanningWrapper = ({
  workoutPlanningOption,
  handleWorkoutOptionSelect,
  profileData,
  accessToken,
  userEmail,
  fetchProfile,
  showDeleteEventDialog,
  handleConfirmEventDelete,
  handleCloseDeleteDialog
}) => {
  if (!workoutPlanningOption) return null;

  return (
    <Box sx={{ mb: 3 }}>
      <WorkoutPlanningOptions
        activeOption={workoutPlanningOption}
        onOptionSelect={handleWorkoutOptionSelect}
        hasEvent={!!(profileData?.event?.name)}
        accessToken={accessToken}
        userEmail={userEmail}
        onRefresh={fetchProfile}
      />
      {workoutPlanningOption === 'event' ? (
        <EventSection
          event={profileData.event}
          accessToken={accessToken}
          userEmail={userEmail}
          onRefresh={fetchProfile}
        />
      ) : (
        <WorkoutSettingsSection
          workoutVolume={profileData.workoutVolume}
          discipline={profileData.discipline}
          accessToken={accessToken}
          userEmail={userEmail}
          onRefresh={fetchProfile}
          hasEvent={!!(profileData.event && profileData.event.name)}
        />
      )}
      <ConfirmEventDeleteDialog
        open={showDeleteEventDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmEventDelete}
      />
    </Box>
  );
};

export default WorkoutPlanningWrapper;