import React from "react";
import axios from "axios";
import { Button } from "@mui/material";
export const DeleteActivityButton = ({ activityId, token }) => {
  const deleteActivity = () => {
    if (window.confirm("Are you sure you want to delete this activity?")) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `https://api2.enduroco.in/activities/${activityId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log("Activity deleted:", response.data);
          // Additional logic after successful deletion
        })
        .catch((error) => {
          console.error("Error deleting activity:", error);
        });
    }
  };

  return (
    <Button
      variant="outlined"
      color="error" // Using 'error' color for the delete button
      onClick={deleteActivity}
      style={{ marginTop: "10px" }} // Add some margin at the top
    >
      Delete Activity
    </Button>
  );
};
