import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { fromJS } from "immutable";

export default function SelectWorkoutType({
  userProfile,
  handleChangeUserProfile,
}) {
  const defaultCheckedOptions = fromJS({
    ridePower: false,
    ridePowerHr: false,
    rideHR: false,
    runPower: false,
    runPace: false,
    runHr: false,
  });
  const [checkedOptions, setCheckedOptions] = useState(defaultCheckedOptions);

  useEffect(() => {
    if (userProfile) {
      setCheckedOptions(
        fromJS({
          ridePower: userProfile.bikepowerwo && !userProfile.bikehrwo,
          ridePowerHr: userProfile.bikehrwo && userProfile.bikepowerwo,
          rideHR: userProfile.bikehrwo && !userProfile.bikepowerwo,
          runPower: userProfile.runpowerwo,
          runPace: userProfile.runpacewo && !userProfile.runpowerwo,
          runHr:
            userProfile.runhrwo &&
            !userProfile.runpowerwo &&
            !userProfile.runpacewo,
        })
      );
    }
  }, [userProfile]);

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  const checkBoxChangeTrigger = async (type, value) => {
    const checkedOptionsLocal = checkedOptions.toJS();
    if (
      type === "ridePower" &&
      !value &&
      !checkedOptionsLocal.ridePowerHr &&
      !checkedOptionsLocal.rideHR
    ) {
      return;
    } else if (
      type === "ridePowerHr" &&
      !value &&
      !checkedOptionsLocal.ridePower &&
      !checkedOptionsLocal.rideHR
    ) {
      return;
    } else if (
      type === "rideHR" &&
      !value &&
      !checkedOptionsLocal.ridePower &&
      !checkedOptionsLocal.ridePowerHr
    ) {
      return;
    }
    if (
      type === "runPower" &&
      !value &&
      !checkedOptionsLocal.runPace &&
      !checkedOptionsLocal.runHr
    ) {
      return;
    }
    if (
      type === "runPace" &&
      !value &&
      !checkedOptionsLocal.runPower &&
      !checkedOptionsLocal.runHr
    ) {
      return;
    }
    if (
      type === "runHr" &&
      !value &&
      !checkedOptionsLocal.runPower &&
      !checkedOptionsLocal.runPace
    ) {
      return;
    }
    checkedOptionsLocal[type] = value;
    if (type === "ridePower" && value) {
      checkedOptionsLocal.ridePowerHr = false;
      checkedOptionsLocal.rideHR = false;
    } else if (type === "ridePowerHr" && value) {
      checkedOptionsLocal.ridePower = false;
      checkedOptionsLocal.rideHR = false;
    } else if (type === "rideHR" && value) {
      checkedOptionsLocal.ridePower = false;
      checkedOptionsLocal.ridePowerHr = false;
    } else if (type === "runPower" && value) {
      checkedOptionsLocal.runPace = false;
      checkedOptionsLocal.runHr = false;
    } else if (type === "runPace" && value) {
      checkedOptionsLocal.runPower = false;
      checkedOptionsLocal.runHr = false;
    } else if (type === "runHr" && value) {
      checkedOptionsLocal.runPower = false;
      checkedOptionsLocal.runPace = false;
    }

    setCheckedOptions(fromJS(checkedOptionsLocal));

    var body = {
      email: userProfile.email,
      bikepowerwo:
        checkedOptionsLocal.ridePower || checkedOptionsLocal.ridePowerHr,
      bikehrwo: checkedOptionsLocal.rideHR || checkedOptionsLocal.ridePowerHr,
      runpowerwo: checkedOptionsLocal.runPower,
      runpacewo: checkedOptionsLocal.runPace && !checkedOptionsLocal.runPower,
      runhrwo:
        checkedOptionsLocal.runHr &&
        !checkedOptionsLocal.runPower &&
        !checkedOptionsLocal.runPace,
    };
    handleChangeUserProfile(body);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {(userProfile.athletetype === "Bike" ||
        userProfile.athletetype === "Duathlete" ||
        userProfile.athletetype === "Triathlete") && (
          <Grid xs={12}>
            <Label>Cycling Intensity Metric</Label>
            <div
              className="col-md-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                checked={checkedOptions.get("ridePower")}
                onChange={(event) => {
                  checkBoxChangeTrigger("ridePower", event.target.checked);
                }}
              />
              <div>
                <span style={{ display: "block" }}>Power based:</span>
                <small style={{ display: "block" }}>
                  Indoor only or indoor + outdoor with power meter.
                </small>
              </div>
            </div>
            <div
              className="col-md-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                checked={checkedOptions.get("ridePowerHr")}
                onChange={(event) => {
                  checkBoxChangeTrigger("ridePowerHr", event.target.checked);
                }}
              />
              <div>
                <span style={{ display: "block" }}>Power and HR based:</span>
                <small style={{ display: "block" }}>
                  Indoor with power but outdoor with HR monitor.
                </small>
              </div>
            </div>
            <div
              className="col-md-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                checked={checkedOptions.get("rideHR")}
                onChange={(event) => {
                  checkBoxChangeTrigger("rideHR", event.target.checked);
                }}
              />
              <div>
                <span style={{ display: "block" }}>HR based:</span>
                <small style={{ display: "block" }}>
                  Outdoor with HR monitor.
                </small>
              </div>
            </div>
          </Grid>
        )}
      {(userProfile.athletetype === "Run" ||
        userProfile.athletetype === "Duathlete" ||
        userProfile.athletetype === "Triathlete") && (
          <Grid xs={12}>
            <Label>Running Intensity Metric</Label>
            <div
              className="col-md-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                checked={checkedOptions.get("runPower")}
                onChange={(event) => {
                  checkBoxChangeTrigger("runPower", event.target.checked);
                }}
              />
              <div>
                <span style={{ display: "block" }}>Power based:</span>
                <small style={{ display: "block" }}>
                  With running power meter (preferred).
                </small>
              </div>
            </div>
            <div
              className="col-md-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                checked={checkedOptions.get("runPace")}
                onChange={(event) => {
                  checkBoxChangeTrigger("runPace", event.target.checked);
                }}
              />
              <div>
                <span style={{ display: "block" }}>Pace based:</span>
                <small style={{ display: "block" }}>
                  Outdoor in flat or mild undulating terrain.
                </small>
              </div>
            </div>
            <div
              className="col-md-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Switch
                checked={checkedOptions.get("runHr")}
                onChange={(event) => {
                  checkBoxChangeTrigger("runHr", event.target.checked);
                }}
              />
              <div>
                <span style={{ display: "block" }}>HR based:</span>
                <small style={{ display: "block" }}>
                  Outdoor in hilly terrain with HR monitor.
                </small>
              </div>
            </div>
          </Grid>
        )}
    </Grid>
  );
}

const Label = styled(Typography)({
  color: "#757575",
  fontSize: "18px",
  fontWeight: "bold",
  textTransform: "uppercase",
  textAlign: "center",
  marginBottom: "10px",
});
