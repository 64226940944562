import React from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import useChat from '../hooks/useChat';
import { questions } from '../constants/chatConstants';

const ProfileChatInterface = ({ onSave, onSwitchView, profile }) => {
    const {
        messages,
        input: renderedInput,
        messagesContainerRef,
        isProcessing,
    } = useChat({
        promptTemplates: questions.reduce((acc, q) => ({ ...acc, [q.id]: q.text }), {}),
        fieldValidators: questions.reduce((acc, q) => ({ ...acc, [q.id]: q.validation }), {}),
        successMessages: questions.reduce((acc, q) => ({ 
            ...acc, 
            [q.id]: (value) => `Thanks! ${value} has been saved.`
        }), {}),
        steps: questions.map(q => q.id),
        onSave,
        currentProfile: profile,
    });

    return (
        <Paper
            elevation={2}
            sx={{
                height: '600px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 3,
                bgcolor: '#FAFBFC'
            }}
        >
            <Box sx={{
                p: 2,
                borderBottom: 1,
                borderColor: 'divider',
                bgcolor: 'background.paper',
                display: 'flex',
                alignItems: 'center',
                gap: 2
            }}>
                <Button
                    startIcon={<ArrowLeft />}
                    onClick={onSwitchView}
                    variant="outlined"
                    size="small"
                    sx={{ borderRadius: 2 }}
                >
                    Back
                </Button>
                <Typography variant="h6">Profile Setup</Typography>
            </Box>

            <Box
                ref={messagesContainerRef}
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    p: 3,
                    scrollBehavior: 'smooth',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(0,0,0,0.1)',
                        borderRadius: '4px'
                    }
                }}
            >
                {messages}
            </Box>

            {renderedInput}
        </Paper>
    );
};

export default ProfileChatInterface;
