import axios from 'axios';

const formatWorkoutSettings = (updates) => {
    if (updates.training_approach === 'event') {
        return {
            event: {
                name: updates.event_details.name,
                description: '',
                startDateLocal: updates.event_details.date,
                type: updates.event_details.type,
                subtype: updates.event_details.subtype
            }
        };
    }

    if (updates.training_approach === 'custom') {
        const workoutVolume = {
            settingEnabled: true,
            volumeSelectionMethod: updates.training_method === 'fixed' ? 'manual' : 'timebased'
        };

        if (updates.training_method === 'fixed') {
            workoutVolume.manual = {
                weekstart: updates.fixed_days_settings.weekstart,
                numberofdaysweekly: updates.fixed_days_settings.days,
                targetformpct: updates.fixed_days_settings.rampRate
            };
        } else {
            workoutVolume.manual = {
                weekstart: updates.time_based_settings.weekstart
            };
            workoutVolume.timeBased = {
                volumeSelectionTimeBasedSettings: updates.time_based_settings.durations
            };
        }

        return { workoutVolume };
    }

    return {};
};

export const saveChatUpdates = async (updates, accessToken, email) => {
    try {
        const workoutSettings = formatWorkoutSettings(updates);
        const payload = {
            profile: {
                name: updates.name,
                age: updates.age,
                weight: updates.weight,
                gender: updates.gender,
                email
            },
            ...workoutSettings
        };

        await axios.patch(
            'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                params: { email }
            }
        );
        return true;
    } catch (error) {
        console.error('Failed to save chat updates:', error);
        return false;
    }
};
