import React from "react";
import { Typography, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { getConfig } from "../../../config";
import useSWR from "swr";
import Battery from "../../../components/battery";
import Speedometer from "../../../components/speedometer";
import {
  CustomCard,
  CustomCardContent,
  Heading,
  InfoText,
} from "../style/CustomCard"; // Import styled components

const fetcher = (url) => fetch(url).then((res) => res.json());

function FitnessForm({ email, data: propData, profile }) {
  const config = getConfig();
  const { data: fetchData, error } = useSWR(
    !propData
      ? config.apiEndpoint + "/api/v1/user/fitness?email=" + email
      : null,
    fetcher
  );

  const data = propData ? propData : fetchData;

  if (error) return "An error has occurred.";
  if (!data) return <Typography>Loading...</Typography>;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid xs={12} >
        <CustomCard sx={{ marginBottom: "5px" }}>
          <CustomCardContent>
            {data ? (
              <Grid container spacing={2}>
                <Grid xs={12} sx={{ textAlign: "center" }}>
                  <Speedometer
                    id="dial5"
                    value={data.fitness}
                    title="Fitness"
                    athletetype={profile ? profile?.athletetype : data.athletetype}
                  />
                  <Heading>Fitness {data.fitness}</Heading>
                </Grid>
                <Grid xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                  <InfoText sx={{ alignSelf: "flex-end" }}>
                    <Battery percentage={data.formpercent ? data.formpercent : 0} />
                  </InfoText>
                </Grid>
                <Grid xs={12} sx={{ textAlign: "center" }}>
                  <Link
                    href="https://forum.enduroco.in/t/suggested-fitness-targets/69"
                    variant="body2"
                    target="_blank"
                  >
                    Guide for Target Fitness
                  </Link>
                </Grid>
              </Grid>
            ) : (
              <Typography>Loading...</Typography>
            )}
          </CustomCardContent>
        </CustomCard>
      </Grid>
    </Grid>
  );
}

export default FitnessForm;
