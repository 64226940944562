import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const ConfirmEventDeleteDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Event Deletion</DialogTitle>
      <DialogContent>
        <Typography>
          Switching to Custom Training Schedule will delete your current event. Are you sure you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Delete Event & Switch
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmEventDeleteDialog;
