import React, { useState } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Grid,
    Typography
} from '@mui/material';
import { weekStartOptions, timeSlots } from '../../../constants/workoutConstants';

const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const TimeBasedForm = ({ onSubmit }) => {
    const [settings, setSettings] = useState({
        weekstart: '',
        durations: DAYS.reduce((acc, day) => ({ ...acc, [day]: '0' }), {})
    });

    const handleDurationChange = (day, duration) => {
        setSettings(prev => ({
            ...prev,
            durations: {
                ...prev.durations,
                [day]: duration
            }
        }));
    };

    const handleSubmit = () => {
        onSubmit(settings);
    };

    const isValid = () => {
        return settings.weekstart &&
            Object.values(settings.durations).every(v => v !== undefined) &&
            Object.values(settings.durations).some(v => v !== '0');
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
            <FormControl fullWidth>
                <InputLabel>Week Start Day</InputLabel>
                <Select
                    value={settings.weekstart}
                    onChange={(e) => setSettings({ ...settings, weekstart: e.target.value })}
                    label="Week Start Day"
                >
                    {weekStartOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Daily Training Durations
            </Typography>

            <Grid container spacing={2}>
                {DAYS.map(day => (
                    <Grid item xs={12} sm={6} key={day}>
                        <FormControl fullWidth>
                            <InputLabel>{day}</InputLabel>
                            <Select
                                value={settings.durations[day]}
                                onChange={(e) => handleDurationChange(day, e.target.value)}
                                label={day}
                            >
                                {timeSlots.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                ))}
            </Grid>

            <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!isValid()}
                sx={{ mt: 2 }}
            >
                Save Schedule
            </Button>
        </Box>
    );
};

export default TimeBasedForm;
