import { useState, useRef } from 'react';

const useMessageHandling = (addMessage) => {
    const [isTyping, setIsTyping] = useState(false);
    const typingTimeoutRef = useRef(null);

    const simulateTyping = async (message) => {
        setIsTyping(true);
        const words = message.split(' ');
        let currentText = '';

        for (let i = 0; i < words.length; i++) {
            await new Promise(resolve => {
                typingTimeoutRef.current = setTimeout(() => {
                    currentText += (i === 0 ? '' : ' ') + words[i];
                    addMessage(prev => {
                        const newMessages = [...prev];
                        newMessages[newMessages.length - 1] = {
                            ...newMessages[newMessages.length - 1],
                            content: currentText
                        };
                        return newMessages;
                    });
                    resolve();
                }, Math.random() * 50 + 20);
            });
        }
        setIsTyping(false);
    };

    return {
        isTyping,
        simulateTyping,
        typingTimeoutRef
    };
};

export default useMessageHandling;
