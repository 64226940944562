import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  TextField,
  Button,
  Paper,
  Container,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  IconButton,
} from "@mui/material";
import { CalendarClock } from "lucide-react";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const eventTypes = ["Running", "Cycling", "Triathlon", "Other"];
const runningSubtypes = [
  "5k",
  "10k",
  "Half Marathon",
  "Marathon",
  "Ultra Run (60k+)",
  "Ultra Run (flat 60+)",
];
const cyclingSubtypes = ["Race", "Ultra"];
const triathlonSubtypes = ["Sprint", "Half", "Full"];

const EventSection = ({
  event: initialEvent,
  accessToken,
  userEmail,
  onRefresh,
}) => {
  const [event, setEvent] = useState(initialEvent || {});
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    startDateLocal: null,
    type: "",
    subtype: "",
  });

  useEffect(() => {
    if (event) {
      setFormValues({
        name: event.name || "",
        description: event.description || "",
        startDateLocal: event.startDateLocal
          ? dayjs(event.startDateLocal)
          : null,
        type: event.type || "",
        subtype: event.subtype || "",
      });
    }
  }, [event]);

  const handleChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      const eventData = {
        ...formValues,
        startDateLocal: formValues.startDateLocal
          ? formValues.startDateLocal.format("YYYY-MM-DD")
          : null,
      };
      await axios.patch(
        "https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings",
        { event: eventData },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params: {
            email: userEmail,
          },
        }
      );
      setEvent(eventData);
      setIsDialogOpen(false);
      if (onRefresh) {
        onRefresh();
      }
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const handleRemove = async () => {
    try {
      await axios.patch(
        "https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings",
        { event: null },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          params: {
            email: userEmail,
          },
        }
      );
      setEvent({});
      if (onRefresh) {
        onRefresh();
      }
    } catch (error) {
      console.error("Error removing event:", error);
    }
  };

  const subtypes =
    formValues.type === "Running"
      ? runningSubtypes
      : formValues.type === "Cycling"
        ? cyclingSubtypes
        : formValues.type === "Triathlon"
          ? triathlonSubtypes
          : [];

  return (
    <Container maxWidth="md" sx={{ p: { xs: 0, sm: 1 } }}>
      <Paper
        elevation={2}
        sx={{
          p: { xs: 3, sm: 4 },
          margin: "auto",
          textAlign: { xs: "left", sm: "inherit" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: { xs: 2, sm: 3 },
          }}
        >
          <CalendarClock
            size={32}
            style={{ marginRight: "12px", color: "#1976d2" }}
          />
          <Typography
            variant="h5"
            component="h1"
            sx={{
              fontSize: { xs: "1.4rem", sm: "1.8rem" },
              textAlign: { xs: "left", sm: "center" },
            }}
          >
            Event Details
          </Typography>
        </Box>
        <Divider sx={{ mb: { xs: 2, sm: 3 } }} />
        {event && event.name ? (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" component="div">
                {event.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {event.description}
              </Typography>
              <Typography variant="body2">
                <strong>Date:</strong> {event.startDateLocal}
              </Typography>
              <Typography variant="body2">
                <strong>Type:</strong> {event.type}
              </Typography>
              <Typography variant="body2">
                <strong>Subtype:</strong> {event.subtype}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <IconButton color="primary" onClick={() => setIsDialogOpen(true)}>
                <EditIcon />
              </IconButton>
              <IconButton color="secondary" onClick={handleRemove}>
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        ) : (
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsDialogOpen(true)}
            >
              Add Event
            </Button>
          </Box>
        )}
      </Paper>

      {/* Dialog for editing/adding event */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{event && event.name ? "Edit Event" : "Add Event"}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <TextField
              label="Event Name"
              value={formValues.name}
              onChange={(e) => handleChange("name", e.target.value)}
              fullWidth
            />
            <TextField
              label="Description"
              value={formValues.description}
              onChange={(e) => handleChange("description", e.target.value)}
              multiline
              rows={3}
              fullWidth
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Event Date"
                value={formValues.startDateLocal}
                onChange={(date) => handleChange("startDateLocal", date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            <FormControl fullWidth>
              <InputLabel>Event Type</InputLabel>
              <Select
                label="Event Type"
                value={formValues.type}
                onChange={(e) => handleChange("type", e.target.value)}
              >
                {eventTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {subtypes.length > 0 && (
              <FormControl fullWidth>
                <InputLabel>Event Subtype</InputLabel>
                <Select
                  label="Event Subtype"
                  value={formValues.subtype}
                  onChange={(e) => handleChange("subtype", e.target.value)}
                >
                  {subtypes.map((subtype) => (
                    <MenuItem key={subtype} value={subtype}>
                      {subtype}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EventSection;
