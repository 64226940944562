import { createRoot } from "react-dom/client";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#fc4c02",
    },
    secondary: {
      main: "#ff8a65", // Complementary lighter orange for secondary color
    },
    eventActivityBackground: {
      main: "rgba(252, 76, 2, 0.2)", // Lighter shade of primary color for activity background
      hover: "rgba(252, 76, 2, 0.3)", // Slightly darker shade for hover
    },
    eventOtherBackground: {
      main: "rgba(252, 76, 2, 0.1)", // Even lighter shade for other events
      hover: "rgba(252, 76, 2, 0.15)", // Slightly darker shade for hover
    },
    text: {
      primary: "#333333", // Standard text color
      secondary: "#555555", // Secondary text color
    },
  },
  navBar: {
    main: "#000000", // Black color for the navigation bar
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Rounded corners for buttons
          textTransform: "none", // Buttons with regular case text
        },
      },
    },
    // ... other component overrides
  },
  // ... other global overrides, transitions, zIndex, etc.
});

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  useRefreshTokens: true,
  cacheLocation: "localstorage",
  onRedirectCallback,
};

const root = createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </ThemeProvider>
);

serviceWorker.unregister();
