import React from "react";
import { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

function Signup(props) {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  loginWithRedirect({ screen_hint: "signup" });
  return <div>Loading...</div>;
}

export default Signup;
