import React, { useState, useEffect } from "react";
import {
  styled,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Note: Ensure this is correct for your version of MUI
import FormSlider from "../components/RampRateSlider.js";

const Label = styled(Typography)({
  color: "#757575",
  fontSize: "16px",
  textTransform: "uppercase",
  fontWeight: "bold",
});

export default function PrecisionTuningSection({
  userProfile,
  handleChangeRadio,
  handleChangeTargetFormPct,
}) {
  const [weekStartOptions, setWeekStartOptions] = useState([]);

  useEffect(() => {
    // Update the Week Start options based on the number of days selected
    if (userProfile?.numberofdaysweekly == 6) {
      setWeekStartOptions([
        { label: "Sunday", value: 0 },
        { label: "Monday", value: 1 },
      ]);
    } else if (userProfile?.numberofdaysweekly == 5) {
      setWeekStartOptions([
        { label: "Sunday/Thursday", value: 0 },
        { label: "Monday/Friday", value: 1 },
      ]);
    }
  }, [userProfile?.numberofdaysweekly]); // This effect runs whenever the number of days changes

  return (
    <div>
      <Grid container spacing={0}>
        <Grid xs={3} alignSelf={"center"}>
          <Label> Days</Label>
        </Grid>
        <Grid xs={8}>
          <FormControl fullWidth>
            <Select
              value={userProfile?.numberofdaysweekly || ""}
              onChange={(e) => handleChangeRadio(e, "numberofdaysweekly")}
              displayEmpty
            >
              <MenuItem value={6}>6 Days / Week</MenuItem>
              <MenuItem value={5}>5 Days / Week</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid xs={3} alignSelf={"center"}>
          <Label>Rest </Label>
        </Grid>
        <Grid xs={8}>
          <FormControl fullWidth>
            <Select
              value={userProfile?.weekstart ?? ""}
              onChange={(e) => handleChangeRadio(e, "weekstart")}
              displayEmpty
            >
              {weekStartOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        {/* Ramp Rate */}
        <Grid xs={12} alignSelf={"top"} marginTop={"5px"}>
          <Label>Weekly Training Volume </Label>
        </Grid>
        <Grid xs={12}>
          <FormSlider
            valueG={userProfile?.targetformpct}
            handleChangeTargetFormPct={handleChangeTargetFormPct}
          ></FormSlider>
        </Grid>
      </Grid>
    </div>
  );
}
