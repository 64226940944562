import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { disciplines } from '../../../constants/workoutConstants';

const DisciplineSelector = ({ onSelect, selectedDiscipline }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Select your training discipline:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
                {disciplines.map((discipline) => (
                    <Button
                        key={discipline.value}
                        onClick={() => onSelect(discipline.value)}
                        variant={selectedDiscipline === discipline.value ? "contained" : "outlined"}
                        sx={{
                            minWidth: 160,
                            height: 80,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1
                        }}
                    >
                        <Typography variant="h6">{discipline.label.split(' ')[0]}</Typography>
                        <Typography variant="caption">
                            {discipline.label.split(' ').slice(1).join(' ')}
                        </Typography>
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default DisciplineSelector;
