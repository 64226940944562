export const DEFAULT_WORKOUT_VOLUME = {
    volumeSelectionMethod: 'manual',
    manual: {
        numberofdaysweekly: '4',
        weekstart: '1',
        targetformpct: '0'
    },
    timeBased: {
        volumeSelectionTimeBasedSettings: {}
    },
    settingEnabled: true
};

export const validateWorkoutSettings = (workoutVolume) => {
    const errors = {};
    
    if (!['0', '1', '2', '6'].includes(String(workoutVolume.manual.weekstart))) {
        errors.weekstart = 'Week start must be Sunday (0), Monday (1), Tuesday (2), or Saturday (6)';
    }

    if (workoutVolume.volumeSelectionMethod === 'manual') {
        const { numberofdaysweekly, targetformpct } = workoutVolume.manual;

        if (!['3', '4', '5', '6'].includes(String(numberofdaysweekly))) {
            errors.numberofdaysweekly = 'Number of days must be 3, 4, 5, or 6';
        }

        const targetFormpctNum = Number(targetformpct);
        const allowedPctValues = [-40, -30, -20, -10, 0, 10, 20, 30, 40];
        if (!allowedPctValues.includes(targetFormpctNum)) {
            errors.targetformpct = 'Form percentage must be -40, -30, -20, -10, 0, 10, 20, 30, 40';
        }
    } else if (workoutVolume.volumeSelectionMethod === 'timebased') {
        const settings = workoutVolume.timeBased?.volumeSelectionTimeBasedSettings || {};
        let hasNonRestDay = false;

        for (const [day, value] of Object.entries(settings)) {
            // Skip validation for rest days
            if (value === 0) continue;
            
            // Check if day has either a valid duration or is set to flexible (-1)
            if (value === -1) {
                hasNonRestDay = true;
                continue;
            }
            
            // Check duration is within valid range if not rest or flexible
            if (value < 15 || value > 120) {
                errors[day] = 'Duration must be between 15 and 120 minutes';
            } else {
                hasNonRestDay = true;
            }
        }

        // Only flag an error if all days are set to rest (0)
        // Don't flag an error if any days are set to no limit (-1) or have valid durations
        if (!hasNonRestDay) {
            errors.general = 'At least one training day must be set (all days cannot be rest days)';
        }
    }

    return errors;
};

export const normalizeWorkoutVolume = (workoutVolume) => {
    if (workoutVolume.volumeSelectionMethod === 'manual' && workoutVolume.manual) {
        return {
            ...workoutVolume,
            manual: {
                ...workoutVolume.manual,
                numberofdaysweekly: String(workoutVolume.manual.numberofdaysweekly)
            }
        };
    }
    return workoutVolume;
};
