import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Events from "../../dashboard/components/events";
import PrecisionTuningSection from "./precisionTuningSection";
import TimeBasedSection from "../components/TimeBasedSection";
import { getConfig } from "../../../config";
import qs from "qs";

export default function VolumeSelector({
  userProfile,
  handleChangeUserProfile,
  handleChangeTargetFormPct,
  handleChangeRadio,
  accessToken,
}) {
  const [volumeSelectionMethod, setVolumeSelectionMethod] = useState(
    userProfile.volumeSelectionMethod || ""
  );
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [temporaryVolumeSelection, setTemporaryVolumeSelection] = useState("");
  const [forceRerender, setForceRerender] = useState(false);
  const eventsRef = useRef();

  const theme = useTheme();


  useEffect(() => {
    setVolumeSelectionMethod(userProfile.volumeSelectionMethod || "");
  }, [userProfile.volumeSelectionMethod]);

  const handleVolumeSelectionChange = (event, newVolumeSelectionMethod) => {
    if (
      volumeSelectionMethod === "event" &&
      newVolumeSelectionMethod !== "event"
    ) {
      setOpenConfirmationDialog(true);
      setTemporaryVolumeSelection(newVolumeSelectionMethod);
    } else {
      updateVolumeSelectionMethod(newVolumeSelectionMethod);
    }
  };

  const handleConfirmSwitch = () => {
    if (temporaryVolumeSelection === "manual") {
      eventsRef.current.deleteAllEvents();
    }
    updateVolumeSelectionMethod(temporaryVolumeSelection);
    setOpenConfirmationDialog(false);
  };

  const handleCancelSwitch = () => {
    setOpenConfirmationDialog(false);
    setTemporaryVolumeSelection("");
    setForceRerender((prev) => !prev);
  };

  const updateVolumeSelectionMethod = (method) => {
    setVolumeSelectionMethod(method);
    if (method === "manual") {
      handleChangeUserProfile({
        ...userProfile,
        volumeSelectionMethod: "manual",
      });
    } else if (method === "availableTime") {
      handleChangeUserProfile({
        ...userProfile,
        volumeSelectionMethod: "availableTime",
      });
    }
  };

  const handleEventAdded = (added) => {
    if (added) {
      handleChangeUserProfile({
        ...userProfile,
        volumeSelectionMethod: "event",
      });
    }
  };

  const handleTimeChange = async (day, value) => {
    const config = getConfig();
    const updatedSettings = {
      ...userProfile,
      volumeSelectionTimeBasedSettings: {
        ...userProfile.volumeSelectionTimeBasedSettings,
        [day]: value,
      },
    };

    handleChangeUserProfile(updatedSettings);

    const urlEncodedData = qs.stringify({
      volumeSelectionTimeBasedSettings: updatedSettings.volumeSelectionTimeBasedSettings
    });

    try {
      const response = await fetch(`${config.api2Endpoint}/user`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${accessToken}`,
        },
        body: urlEncodedData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error("Error updating volume selection time-based settings:", error);
    }
  };

  const handleTargetFormPctChange = async (rampRate) => {
    const config = getConfig();
    const updatedProfile = {
      ...userProfile,
      targetformpct: rampRate,
    };

    handleChangeUserProfile(updatedProfile);

    try {
      // Prepare URL-encoded data
      const urlEncodedData = `targetformpct=${encodeURIComponent(rampRate)}`;

      const response = await fetch(`${config.api2Endpoint}/user`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${accessToken}`,
        },
        body: urlEncodedData,  // Send URL-encoded data
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error("Error updating target form percentage:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mb: 4 }}>
        <Dialog open={openConfirmationDialog} onClose={handleCancelSwitch}>
          <DialogTitle>{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This will delete your event. This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelSwitch}>Cancel</Button>
            <Button onClick={handleConfirmSwitch} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <ToggleButtonGroup
          key={forceRerender}
          exclusive
          value={volumeSelectionMethod}
          onChange={handleVolumeSelectionChange}
          sx={{ borderRadius: "10px" }}
          color="primary"
        >
          <ToggleButton
            value="event"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            Event
            {userProfile.volumeSelectionMethod === "event" && (
              <CheckCircleIcon fontSize="small" />
            )}
          </ToggleButton>
          <ToggleButton
            value="manual"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            Manual
            {userProfile.volumeSelectionMethod === "manual" && (
              <CheckCircleIcon fontSize="small" />
            )}
          </ToggleButton>
          <ToggleButton
            value="availableTime"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            Time Based
            {userProfile.volumeSelectionMethod === "availableTime" && (
              <CheckCircleIcon fontSize="small" />
            )}
          </ToggleButton>
        </ToggleButtonGroup>

        {volumeSelectionMethod === "manual" ? (
          <PrecisionTuningSection
            userProfile={userProfile}
            handleChangeRadio={handleChangeRadio}
            handleChangeTargetFormPct={handleChangeTargetFormPct}
          />
        ) : volumeSelectionMethod === "event" ? (
          <Events
            ref={eventsRef}
            email={userProfile.email}
            userProfile={userProfile}
            handleChangeUserProfile={handleChangeUserProfile}
            accessToken={accessToken}
            onEventAdded={handleEventAdded}
          />
        ) : (
          volumeSelectionMethod === "availableTime" && (
            <TimeBasedSection
              userProfile={userProfile}
              handleChangeTime={handleTimeChange}
              handleTargetFormPctChange={handleTargetFormPctChange}
              handleChangeUserProfile={handleChangeUserProfile}
            />
          )
        )}
      </Box>
    </ThemeProvider>
  );
}
