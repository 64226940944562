import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import {
  CustomCard,
  CustomCardContent,
  Heading,
  InfoText,
} from "../style/CustomCard";
import { styled } from "@mui/system";
import { useSettingsReport } from "../../../hooks/useSettingsReport";  // Correct import path

const NotificationText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginBottom: "4px",
}));

const SettingsNotificationCard = ({ accessToken, toggleSettings }) => {
  const settingsReport = useSettingsReport(accessToken);  // Receive the entire settings report

  const handleClick = () => {
    if (toggleSettings) {
      toggleSettings();
    }
  };

  const notifications = settingsReport?.notifications || [];
  return (
    <CustomCard onClick={handleClick}>
      <CustomCardContent>
        <Heading>Settings Issues</Heading>
        {notifications.length === 0 ? (
          <NotificationText>
            <WarningIcon color="error" />
            No issues found.
          </NotificationText>
        ) : (
          notifications.map((notification, index) => (
            <NotificationText key={index}>
              <WarningIcon color="error" />
              {notification}
            </NotificationText>
          ))
        )}
      </CustomCardContent>
    </CustomCard>
  );
};

export default SettingsNotificationCard;
