import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import AppStoreImg from "../assets/AppStore.png";
import PlayStoreImg from "../assets/PlayStore.png";
import WebAppImg from "../assets/webapp_button.png";

export default function BannerText() {
  return (
    <Paper
      className="banner_text_section"
      sx={{ backgroundColor: "#000", color: "#FFF" }}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid xs={12} md={8} textAlign="center">
          <Box sx={{ "& > img": { mx: 2 } }}>
            <Link
              href="https://apps.apple.com/tt/app/enduroco/id1598581676"
              target="_blank"
            >
              <img src={AppStoreImg} alt="App Store" />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=com.enduroco.app&hl=en"
              target="_blank"
            >
              <img src={PlayStoreImg} alt="Play Store" />
            </Link>
            <Link href="https://www.enduroco.in/dashboard" target="_blank">
              <img src={WebAppImg} alt="Web App" />
            </Link>
          </Box>
          <Typography variant="h3" gutterBottom>
            Individual, High-End Performance Coaching In One App.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
