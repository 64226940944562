import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import {
  CustomCard,
  CustomCardContent,
  Heading,
} from "./dashboard/style/CustomCard";

function FitnessFormChart({ fitnessData }) {
  const ref = useRef();

  const drawChart = () => {
    if (fitnessData) {
      const data = makeData(fitnessData);
      const textOffsetX = 30;
      const rectWidth = 19;
      const rectHeight = 19;
      const legendSpacing = 20;
      const svg = d3.select(ref.current);
      svg.selectAll("*").remove();
      const width = svg.node().getBoundingClientRect().width;
      const height = svg.node().getBoundingClientRect().height;
      const margin = { top: 20, right: 50, bottom: 50, left: 50 };
      const adjWidth = width - margin.left - margin.right;
      const adjHeight = height - margin.top - margin.bottom;
      const xScale = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => d.date))
        .range([0, adjWidth]);
      const yScaleFitness = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.fitness)])
        .range([adjHeight, 0]);
      const yScaleForm = d3
        .scaleLinear()
        .domain([d3.min(data, (d) => d.form), d3.max(data, (d) => d.form)])
        .range([adjHeight, 0]);
      const mainGroup = svg
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
      const lineFitness = d3
        .line()
        .x((d) => xScale(d.date))
        .y((d) => yScaleFitness(d.fitness))
        .curve(d3.curveMonotoneX);
      const lineForm = d3
        .line()
        .x((d) => xScale(d.date))
        .y((d) => yScaleForm(d.form))
        .curve(d3.curveMonotoneX);

      mainGroup
        .append("path")
        .datum(data)
        .attr("d", lineFitness)
        .attr("fill", "none")
        .attr("stroke", "blue")
        .attr("stroke-width", "2px");
      mainGroup
        .append("path")
        .datum(data)
        .attr("d", lineForm)
        .attr("fill", "none")
        .attr("stroke", "red")
        .attr("stroke-width", "2px");

      const xAxis = d3.axisBottom(xScale);
      if (width < 500) {
        // Check if the viewport is small
        xAxis.ticks(d3.timeMonth.every(1)); // Reduce the number of ticks if viewport is small
      }
      mainGroup
        .append("g")
        .attr("transform", `translate(0, ${adjHeight})`)
        .call(xAxis);

      mainGroup.append("g").call(d3.axisLeft(yScaleFitness));
      mainGroup
        .append("g")
        .attr("transform", `translate(${adjWidth}, 0)`)
        .call(d3.axisRight(yScaleForm));

      // Append the Y axis for form on the right with zero line above if negative values exist
      mainGroup
        .append("g")
        .attr("transform", `translate(0, ${adjHeight})`)
        .call(d3.axisBottom(xScale));
      mainGroup.append("g").call(d3.axisLeft(yScaleFitness));
      mainGroup
        .append("g")
        .attr("class", "axis axis--form")
        .attr("transform", `translate(${adjWidth}, 0)`)
        .call(d3.axisRight(yScaleForm));
      svg
        .append("text")
        .attr("transform", `translate(${width / 2}, ${height - 5})`)
        .attr("text-anchor", "middle")
        .text("Date");
      svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", margin.left / 2)
        .attr("x", 0 - height / 2)
        .attr("text-anchor", "middle")
        .text("Fitness")
        .attr("stroke", "blue");
      svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", width - margin.right / 2 - 30)
        .attr("x", 0 - height / 2 + 2)
        .attr("text-anchor", "middle")
        .text("% Form")
        .attr("stroke", "red");

      const legend = mainGroup
        .append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .attr("text-anchor", "start")
        .selectAll("g")
        .data([
          { color: "blue", text: "Fitness" },
          { color: "red", text: "% Form" },
        ])
        .enter()
        .append("g")
        .attr("transform", (d, i) => `translate(0,${i * legendSpacing})`);
      const currentDate = new Date();
      const futureDateExtent = xScale.domain()[1]; // Assuming this is the max date in your domain

      if (currentDate < futureDateExtent) {
        mainGroup
          .append("rect")
          .attr("class", "future-overlay")
          .attr("x", xScale(currentDate)) // Start the rectangle at the current date
          .attr("y", 0)
          .attr("width", xScale(futureDateExtent) - xScale(currentDate)) // Width from current date to end of scale
          .attr("height", adjHeight)
          .attr("fill", "rgba(0, 0, 0, 0.1)"); // Semi-transparent shading
        const middleOfShading =
          xScale(currentDate) +
          (xScale(futureDateExtent) - xScale(currentDate)) / 2;

        // Append "Projected" label
        mainGroup
          .append("text")
          .attr("x", middleOfShading)
          .attr("y", 20) // Adjust this to move the label up or down
          .attr("text-anchor", "middle")
          .attr("fill", "#666") // Grey color to match the shading
          .attr("font-size", "16px") // Font size for the label
          .attr("font-family", "sans-serif") // Font family for the label
          .text("Projected");
      }
      legend
        .append("rect")
        .attr("x", 0)
        .attr("width", rectWidth)
        .attr("height", rectHeight)
        .attr("fill", (d) => d.color);
      legend
        .append("text")
        .attr("x", rectWidth + 5)
        .attr("y", rectHeight / 2)
        .attr("dy", "0.32em")
        .text((d) => d.text);
    }
  };

  useEffect(() => {
    drawChart();
    const handleResize = () => {
      drawChart(); // Redraw chart on window resize
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [fitnessData]); // Redraw chart when fitnessData changes

  return (
    <CustomCard>
      <CustomCardContent>
        <Heading>Fitness Form Chart</Heading>
        <div>
          <svg ref={ref} style={{ height: "500px", width: "100%" }}></svg>
        </div>
      </CustomCardContent>
    </CustomCard>
  );
}

function makeData(fitnessData) {
  if (!fitnessData) return [];
  if (!fitnessData.workouts) return [];
  if (!fitnessData.workouts.length) return [];

  return fitnessData.workouts
    .filter((e) => e.fitness)
    .map((e) => ({
      fitness: e.fitness,
      form: Math.round(e.fitness === 0 ? 0 : (e.form / e.fitness) * 100),
      date: new Date(e.workoutdate.split("T")[0]),
    }));
}

export default FitnessFormChart;
