import axios from 'axios';

export const verifyStravaConnection = async (accessToken, email) => {
  try {
    const response = await axios.get(
      'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: { email }
      }
    );
    return response.data;  // Return the full settings object
  } catch (error) {
    console.error('Failed to verify Strava connection:', error);
    throw error;
  }
};
