import { eventTypes, weekStartOptions, trainingDaysOptions, rampRateOptions, timeSlots, disciplines } from './workoutConstants';

export const questions = [
    {
        id: 'name',
        text: "What's your full name?",
        subtext: "We use this to personalize your experience and workout plans",
        type: 'text',
        validation: (value) => {
            if (!value) return "Name cannot be empty";
            if (!value.includes(' ')) return "Please enter your full name";
            return null;
        }
    },
    {
        id: 'age',
        text: "What's your age?",
        subtext: "Helps us tailor workout intensity and recovery times",
        type: 'number',
        validation: (value) => {
            const age = Number(value);
            if (!Number.isInteger(age) || age < 13 || age > 120)
                return "Age must be between 13 and 120";
            return null;
        }
    },
    {
        id: 'weight',
        text: "What's your weight in kg?",
        subtext: "Used to calculate optimal training zones and energy expenditure",
        type: 'number',
        validation: (value) => {
            const weight = Number(value);
            if (!Number.isInteger(weight) || weight < 20 || weight > 300)
                return "Weight must be between 20 and 300 kg";
            return null;
        }
    },
    {
        id: 'gender',
        text: "What's your gender?",
        subtext: "Helps calculate physiological metrics like heart rate zones",
        type: 'gender',
        validation: (value) => {
            if (!['Male', 'Female'].includes(value)) return "Please select Male or Female";
            return null;
        }
    },
    {
        id: 'strava_connect',
        text: "Would you like to import your workouts from Strava?",
        subtext: "This will help us understand your fitness level and training history",
        type: 'strava_connection',
        skipValidation: true
    },
    {
        id: 'training_approach',
        text: "Would you prefer to train for a specific event, or would you like a custom training schedule?",
        subtext: "Event-based training is ideal for races, while custom scheduling is better for general fitness",
        type: 'training_approach',
        validation: (value) => {
            if (!['event', 'custom'].includes(value)) return "Please select Event or Custom training";
            return null;
        }
    },
    {
        id: 'discipline',
        text: "What type of training will you be doing?",
        subtext: "This helps us tailor your training plan to your specific sport",
        type: 'discipline',
        validation: (value) => {
            if (!disciplines.map(d => d.value).includes(value))
                return "Please select a valid discipline";
            return null;
        }
    },
    {
        id: 'event_details',
        text: "Great! Let's set up your event.",
        subtext: "Tell us about the event you're training for",
        type: 'event_details',
        conditional: (updates) => updates.training_approach === 'event',
        validation: (value) => {
            if (!value.name || !value.date || !value.type || !value.subtype || !value.discipline)
                return "Please fill in all event details";
            return null;
        }
    },
    {
        id: 'training_method',
        text: "Would you prefer to set fixed training days or prioritize daily durations?",
        subtext: "Fixed days provide structure, while duration priority offers more flexibility",
        type: 'training_method',
        conditional: (updates) => updates.training_approach === 'custom',
        validation: (value) => {
            if (!['fixed', 'duration'].includes(value))
                return "Please select Fixed Days or Duration Priority";
            return null;
        }
    },
    {
        id: 'fixed_days_settings',
        text: "Let's set up your training schedule.",
        subtext: "Choose your preferred training structure",
        type: 'fixed_days_settings',
        conditional: (updates) => updates.training_method === 'fixed',
        validation: (value) => {
            if (!value.weekstart || !value.days || !value.rampRate)
                return "Please complete all training settings";
            return null;
        }
    },
    {
        id: 'time_based_settings',
        text: "Let's plan your weekly training schedule.",
        subtext: "Set your preferred duration for each day",
        type: 'time_based_settings',
        conditional: (updates) => updates.training_method === 'duration',
        validation: (value) => {
            if (!Object.values(value).every(v => v !== undefined))
                return "Please set durations for all days";
            if (!Object.values(value).some(v => v > 0))
                return "At least one day must have training scheduled";
            return null;
        }
    }
];

export const timezones = [
    'GMT-12:00', 'GMT-11:00', 'GMT-10:00', 'GMT-09:00', 'GMT-08:00',
    'GMT-07:00', 'GMT-06:00', 'GMT-05:00', 'GMT-04:00', 'GMT-03:00',
    'GMT-02:00', 'GMT-01:00', 'GMT+00:00', 'GMT+01:00', 'GMT+02:00',
    'GMT+03:00', 'GMT+04:00', 'GMT+05:00', 'GMT+05:30', 'GMT+06:00',
    'GMT+07:00', 'GMT+08:00', 'GMT+09:00', 'GMT+10:00', 'GMT+11:00',
    'GMT+12:00'
];
