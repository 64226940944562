import React, { useState, useEffect, useRef, Suspense } from "react";
import { Paper, Typography, Button, Box, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import WorkoutTextView from "./components/workoutTextView";
import CalendarBody from "./components/CalendarBody";
import mapWorkoutsToEvents from "../helpers/mapWorkoutsToEvents";
import { useTheme } from "@mui/material/styles";
import { useCalendarData } from "./calendarDataProvider";
import Loading from "../../Loading";
import CircularProgress from "@mui/material/CircularProgress";

const CalendarDesktop = ({ accessToken }) => {
  const theme = useTheme();
  const { data, error } = useCalendarData();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openWorkoutView, setOpenWorkoutView] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialWeeks = [
    moment().subtract(1, 'week').startOf("isoWeek"),
    moment().startOf("isoWeek"),
    moment().add(1, "week").startOf("isoWeek"),
    moment().add(2, "week").startOf("isoWeek"),
  ];
  const [weeks, setWeeks] = useState(initialWeeks);
  const [currentWeekInView, setCurrentWeekInView] = useState(
    moment().subtract(1, 'week').startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [currentMonth, setCurrentMonth] = useState(
    moment().subtract(1, 'week').startOf("isoWeek").format("MMMM YYYY")
  );
  const scrollRef = useRef(null);
  const weekdays = moment.weekdaysShort().slice(1).concat(moment.weekdaysShort().slice(0, 1));

  useEffect(() => {
    setEvents(mapWorkoutsToEvents(data));
    ensureScrollableContent();
  }, [data]);

  useEffect(() => {
    const element = scrollRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll, { passive: true });
      updateCurrentWeekInView();
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, weeks]);

  useEffect(() => {
    window.addEventListener("resize", ensureScrollableContent);
    return () => window.removeEventListener("resize", ensureScrollableContent);
  }, [weeks]);

  useEffect(() => {
    if (currentWeekInView) {
      const date = moment(currentWeekInView, "YYYY-MM-DD");
      setCurrentMonth(date.format("MMMM YYYY"));
    }
  }, [currentWeekInView]);

  const ensureScrollableContent = () => {
    setTimeout(() => {
      if (scrollRef.current) {
        const scrollHeight = scrollRef.current.scrollHeight;
        const clientHeight = scrollRef.current.clientHeight;
        if (scrollHeight <= clientHeight) {
          addWeek("next");
        }
      }
    }, 100);
  };

  const addWeek = (direction) => {
    setLoading(true);
    setTimeout(() => {
      if (direction === "next") {
        const newWeek = moment(weeks[weeks.length - 1]).add(1, "week");
        setWeeks((prevWeeks) => [...prevWeeks, newWeek]);
      } else if (direction === "prev") {
        const newWeek = moment(weeks[0]).subtract(1, "week");
        setWeeks((prevWeeks) => [newWeek, ...prevWeeks]);
        const scrollElement = scrollRef.current;
        if (scrollElement) {
          const firstWeekElement = document.getElementById(`week-0`);
          if (firstWeekElement) {
            const heightDifference = firstWeekElement.clientHeight;
            scrollElement.scrollTop += heightDifference;
          }
        }
      }
      setLoading(false);
    }, 300);
  };

  const scrollToThisWeek = () => {
    const thisWeekIndex = weeks.findIndex((week) =>
      moment().isSame(week, "week")
    );
    if (thisWeekIndex !== -1) {
      const thisWeekElement = document.getElementById(`week-${thisWeekIndex}`);
      if (thisWeekElement) {
        const topPosition =
          thisWeekElement.offsetTop - scrollRef.current.offsetTop;
        scrollRef.current.scrollTo(0, topPosition);
        setCurrentWeekInView(moment().startOf("isoWeek").format("YYYY-MM-DD"));
        setCurrentMonth(moment().format("MMMM YYYY"));
      }
    } else {
      setWeeks(initialWeeks);
      setTimeout(() => {
        const thisWeekElement = document.getElementById("week-0");
        if (thisWeekElement) {
          const topPosition =
            thisWeekElement.offsetTop - scrollRef.current.offsetTop;
          scrollRef.current.scrollTo(0, topPosition);
          setCurrentWeekInView(
            moment().startOf("isoWeek").format("YYYY-MM-DD")
          );
          setCurrentMonth(moment().format("MMMM YYYY"));
        }
      }, 100);
    }
  };
  const scrollOneWeek = (direction) => {
    const weekHeight = document.getElementById("week-0").clientHeight;
    const currentScrollTop = scrollRef.current.scrollTop;
    if (direction === "up") {
      scrollRef.current.scrollTo({
        top: currentScrollTop - weekHeight,
        behavior: "smooth",
      });
    } else {
      scrollRef.current.scrollTo({
        top: currentScrollTop + weekHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollOneWeekUp = () => scrollOneWeek("up");
  const scrollOneWeekDown = () => scrollOneWeek("down");

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (!loading) {
      if (scrollTop + clientHeight >= scrollHeight) {
        addWeek("next");
      } else if (scrollTop === 0) {
        addWeek("prev");
      }
    }
    updateCurrentWeekInView();
  };

  const updateCurrentWeekInView = () => {
    const visibleWeeks = document.querySelectorAll('[id^="week-"]');
    let currentInView = "";
    for (let i = 0; i < visibleWeeks.length; i++) {
      const rect = visibleWeeks[i].getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        currentInView = visibleWeeks[i].getAttribute("data-week");
        break;
      }
    }
    if (currentInView) {
      setCurrentWeekInView(currentInView);
    }
  };

  return (
    <Paper elevation={0} square>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        zIndex: 2,
        padding: "8px",
      }}>
        <Typography variant="h6" sx={{ marginRight: "auto" }}>
          {currentMonth}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, p: 2 }}>
          <IconButton onClick={() => scrollOneWeek("up")} aria-label="Scroll Up One Week">
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton onClick={() => scrollOneWeek("down")} aria-label="Scroll Down One Week">
            <ArrowDownwardIcon />
          </IconButton>
        </Box>
        <Button variant="contained" size="small" onClick={scrollToThisWeek} sx={{ marginLeft: "auto" }}>
          Today
        </Button>
      </Box>

      <Box ref={scrollRef} sx={{
        overflowY: "auto",
        maxHeight: "80vh"
      }}>
        {weeks.map((week, index) => (
          <CalendarBody
            key={index}
            id={`week-${index}`}
            currentWeek={week}
            events={events}
            setOpenWorkoutView={setOpenWorkoutView}
            setSelectedEvent={setSelectedEvent}
            isLastWeek={index === weeks.length - 1}
          />
        ))}
        {loading && (
          <Grid xs={12} sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Grid>
        )}
      </Box>

      {selectedEvent && (
        <WorkoutTextView
          open={openWorkoutView}
          setOpen={setOpenWorkoutView}
          woObj={selectedEvent}
          accessToken={accessToken}
        />
      )}
    </Paper>
  );
};

const CalendarDesktopWrapper = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <CalendarDesktop {...props} />
    </Suspense>
  );
};

export default CalendarDesktopWrapper;
