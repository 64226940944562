import React from 'react';
import { Typography } from '@mui/material';
import CalendarHeatmap from 'react-github-contribution-calendar';
import moment from 'moment';
import { CustomCard, CustomCardContent, Heading, InfoText } from "../style/CustomCard";

const HeatMapCard = ({ data }) => {
  // Early validation of data structure
  if (!data || !data.dailyload || !Array.isArray(data.dailyload)) {
    return (
      <CustomCard>
        <CustomCardContent>
          <Heading>Load Heatmap</Heading>
          <InfoText>No data available.</InfoText>
        </CustomCardContent>
      </CustomCard>
    );
  }

  const validData = data.dailyload.slice(0, -14);

  if (validData.length === 0) {
    return (
      <CustomCard>
        <CustomCardContent>
          <Heading>Load Heatmap</Heading>
          <InfoText>No load data available.</InfoText>
        </CustomCardContent>
      </CustomCard>
    );
  }

  const startDate = moment().subtract(validData.length - 1, 'days');

  const panelColors = [
    '#EEEEEE', // No load - lightest color
    '#ff8a65', // Medium load - secondary color
    '#ac5808', // Very high load - darker shade of primary color
  ];

  // Create values object for the heatmap
  const values = validData.reduce((acc, load, index) => {
    const date = moment(startDate).add(index, 'days').format('YYYY-MM-DD');
    acc[date] = load;
    return acc;
  }, {});

  const until = moment(startDate)
    .add(validData.length - 1, 'days')
    .format('YYYY-MM-DD');

  return (
    <CustomCard>
      <CustomCardContent>
        <Heading>Load Heatmap</Heading>
        <CalendarHeatmap
          values={values}
          until={until}
          panelColors={panelColors}
        />
      </CustomCardContent>
    </CustomCard>
  );
};

export default HeatMapCard;