import React from 'react';
import { Box, Typography, Paper, Grid, Button } from '@mui/material';
import { CalendarClock, Clock, Check, AlertCircle, Trash2 } from 'lucide-react';
import axios from 'axios';

const WorkoutPlanningOptions = ({ activeOption, onOptionSelect, hasEvent, accessToken, userEmail, onRefresh }) => {
  const options = [
    {
      id: 'event',
      title: 'Event-Based Training',
      description: 'Plan workouts based on an upcoming event or race',
      icon: CalendarClock,
    },
    {
      id: 'custom',
      title: 'Custom Training Schedule',
      description: 'Create a personalized training plan without a specific event',
      icon: Clock,
    }
  ];

  const handleRemoveEvent = async () => {
    try {
      await axios.patch(
        'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
        { event: null },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          params: {
            email: userEmail
          }
        }
      );
      onRefresh();
    } catch (error) {
      console.error('Error removing event:', error);
    }
  };

  const handleSwitchToEvent = () => {
    onOptionSelect('event');
    // This will trigger the event section to show the edit mode directly
    if (window.eventSectionRef && typeof window.eventSectionRef.startEditing === 'function') {
      window.eventSectionRef.startEditing();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        {options.map((option) => {
          const isActive = (option.id === 'event' && hasEvent) ||
            (option.id === 'custom' && !hasEvent);
          const isDisabled = option.id === 'custom' && hasEvent;
          const Icon = option.icon;

          return (
            <Grid item xs={12} sm={6} key={option.id}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: '100%',
                  border: '2px solid',
                  borderColor: isActive ? 'success.main' : 'divider',
                  bgcolor: isActive ? 'success.lighter' : 'background.paper',
                  opacity: isDisabled ? 0.7 : 1,
                  transition: 'all 0.2s',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                {isActive && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      bgcolor: 'success.main',
                      color: 'white',
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      fontSize: '0.75rem',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5
                    }}
                  >
                    <Check size={14} />
                    Active
                  </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Icon
                      size={24}
                      style={{
                        marginRight: '12px',
                        color: isActive ? '#2e7d32' : '#1976d2'
                      }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        color: isActive ? 'success.dark' : 'text.primary',
                        fontWeight: 500
                      }}
                    >
                      {option.title}
                    </Typography>
                  </Box>

                  <Typography
                    variant="body2"
                    color={isActive ? 'success.dark' : 'text.secondary'}
                    sx={{ mb: 2, flex: 1 }}
                  >
                    {option.description}
                  </Typography>

                  <Box sx={{ mt: 'auto', display: 'flex', gap: 1 }}>
                    {isDisabled ? (
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          color: 'text.secondary'
                        }}
                      >
                        <AlertCircle size={16} />
                        Remove event first to switch to custom schedule
                      </Typography>
                    ) : (
                      <>
                        {option.id === 'event' && isActive ? (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={handleRemoveEvent}
                            startIcon={<Trash2 size={16} />}
                            fullWidth
                          >
                            Remove Event
                          </Button>
                        ) : (
                          <Button
                            variant={isActive ? "outlined" : "contained"}
                            color={isActive ? "success" : "primary"}
                            onClick={() => option.id === 'event' ? handleSwitchToEvent() : onOptionSelect(option.id)}
                            fullWidth
                          >
                            {isActive ? 'Modify Settings' : `Switch to ${option.title}`}
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default WorkoutPlanningOptions;
