import React, { useState } from 'react';
import { Typography, Box, Divider, FormControlLabel, Switch, Snackbar, Alert, Paper, Container} from '@mui/material';
import { Settings } from 'lucide-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import SettingsEditButton from './components/SettingsEditButton';

const PreferencesSection = ({
  preferences,
  accessToken,
  userEmail,
  onPreferencesChange,
  onRefresh,
}) => {
  const [localPreferences, setLocalPreferences] = useState(preferences);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleChange = (event) => {
    setLocalPreferences({
      ...localPreferences,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await axios.patch(
        'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
        { preferences: localPreferences },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          params: {
            email: userEmail,
          },
        }
      );

      if (response.data.success) {
        onPreferencesChange(localPreferences);
        setIsEditing(false);
        setSnackbar({
          open: true,
          message: 'Preferences updated successfully',
          severity: 'success',
        });
        if (onRefresh) {
          onRefresh();
        }
      } else {
        throw new Error('API response was not successful');
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update preferences',
        severity: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setLocalPreferences(preferences);
  };

  return (
    <Container maxWidth="md" sx={{ p: { xs: 0, sm: 1 } }}>
      <Paper
        elevation={0}
        sx={{
          p: { xs: 2, sm: 3 },
          textAlign: { xs: 'left', sm: 'inherit' },
          width: '100%',
          margin: 'auto',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: { xs: 2, sm: 3 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Settings size={32} style={{ marginRight: '12px', color: '#1976d2' }} />
            <Typography
              variant="h5"
              component="h1"
              sx={{
                fontSize: { xs: '1.2rem', sm: '1.5rem' },
                textAlign: { xs: 'left', sm: 'center' },
              }}
            >
              Preferences
            </Typography>
          </Box>
          <SettingsEditButton
            isEditing={isEditing}
            onEdit={() => setIsEditing(true)}
            onSave={handleSave}
            onCancel={handleCancel}
            isSaving={isSaving}
          />
        </Box>
        <Divider sx={{ mb: { xs: 2, sm: 3 } }} />
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={localPreferences.emailnotification}
                onChange={handleChange}
                name="emailnotification"
                disabled={!isEditing || isSaving}
              />
            }
            label="Email Notifications"
          />
          <FormControlLabel
            control={
              <Switch
                checked={localPreferences.updateStravaDescription}
                onChange={handleChange}
                name="updateStravaDescription"
                disabled={!isEditing || isSaving}
              />
            }
            label="Update Strava Description"
          />
        </Box>
      </Paper>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

PreferencesSection.propTypes = {
  preferences: PropTypes.object.isRequired,
  accessToken: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  onPreferencesChange: PropTypes.func.isRequired,
  onRefresh: PropTypes.func,
};

PreferencesSection.defaultProps = {
  onPreferencesChange: () => {},
};

export default PreferencesSection;
