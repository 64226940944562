import React, { useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../../config";
var qs = require("qs");

export const ThirdpartyauthWahoo = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const location = useLocation();
  const [source, setsource] = useState();
  const [status, setstatus] = useState(false);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const fetchTokenAndProceed = async () => {
      if (params.code) {
        const idToken = await getIdTokenClaims();
        const accessToken = idToken.__raw;
        setsource("Wahoo");
        updateUserCodeToBackend(params.code, "wahooauth", accessToken);
      }
    };

    fetchTokenAndProceed();
  }, [user, location.search, getIdTokenClaims]);

  function updateUserCodeToBackend(code, type, accessToken) {
    const requestBody = {
      type: type.replace("auth", ""),
      email: user.email,
      [type]: code,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    const config = getConfig();
    fetch(config.api2Endpoint + "/thirdpartyconnection/add", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log('Response from backend:', result);
        setstatus(true);
      })
      .catch((error) => {
        console.error("Error connecting to Wahoo:", error);
        setstatus(false); // You might want to handle this differently
      });
  }

  return status ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <div className="connectionStatus fadeIn">Connecting to WAHOO...</div>
  );
};

export default withAuthenticationRequired(ThirdpartyauthWahoo, {
  onRedirecting: () => <Loading />,
});
