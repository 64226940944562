import React, { useState } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@mui/material';
import { weekStartOptions, trainingDaysOptions, rampRateOptions } from '../../../constants/workoutConstants';

const FixedDaysForm = ({ onSubmit }) => {
    const [settings, setSettings] = useState({
        weekstart: '',
        days: '',
        rampRate: ''
    });

    const handleSubmit = () => {
        onSubmit(settings);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
            <FormControl fullWidth>
                <InputLabel>Week Start Day</InputLabel>
                <Select
                    value={settings.weekstart}
                    onChange={(e) => setSettings({ ...settings, weekstart: e.target.value })}
                    label="Week Start Day"
                >
                    {weekStartOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel>Training Days per Week</InputLabel>
                <Select
                    value={settings.days}
                    onChange={(e) => setSettings({ ...settings, days: e.target.value })}
                    label="Training Days per Week"
                >
                    {trainingDaysOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel>Training Intensity</InputLabel>
                <Select
                    value={settings.rampRate}
                    onChange={(e) => setSettings({ ...settings, rampRate: e.target.value })}
                    label="Training Intensity"
                >
                    {rampRateOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!settings.weekstart || !settings.days || !settings.rampRate}
            >
                Save Settings
            </Button>
        </Box>
    );
};

export default FixedDaysForm;
