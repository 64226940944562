import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Calendar, Clock } from 'lucide-react';

const TrainingMethodSelection = ({ onSelect }) => {
    return (
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', p: 2 }}>
            <Button
                onClick={() => onSelect('fixed')}
                variant="outlined"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    p: 2,
                    minWidth: 160,
                    height: 'auto'
                }}
            >
                <Calendar size={24} />
                <Typography>Fixed Days</Typography>
                <Typography variant="caption" color="text.secondary">
                    Set consistent weekly schedule
                </Typography>
            </Button>
            <Button
                onClick={() => onSelect('duration')}
                variant="outlined"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    p: 2,
                    minWidth: 160,
                    height: 'auto'
                }}
            >
                <Clock size={24} />
                <Typography>Duration Priority</Typography>
                <Typography variant="caption" color="text.secondary">
                    Flexible daily durations
                </Typography>
            </Button>
        </Box>
    );
};

export default TrainingMethodSelection;
