import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Divider, FormControlLabel, Switch, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { grey, red } from "@mui/material/colors";
import HelperText from "../../components/helperText";
import { getConfig } from "../../../../config";
import { useAuth0 } from "@auth0/auth0-react";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GarminConnect from "./components/garminConnect";

function Connections({ userProfile, onRefresh }) {
  const { getIdTokenClaims } = useAuth0();
  const theme = useTheme();
  const [loading, setLoading] = useState("");

  const Label = styled(Typography)(({ theme }) => ({
    color: "#757575",
    fontSize: "18px",
    textTransform: "uppercase",
    fontWeight: "bold",
  }));

  useEffect(() => { }, [loading, userProfile]);

  const OptionalLabel = ({ children }) => (
    <Tooltip title="This connection is optional and is used for exporting workouts that can then sync to your watch/bike computer.">
      <Typography
        variant="body2"
        component="span"
        style={{
          color: grey[600],
          marginLeft: theme.spacing(1),
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {children}
        <InfoIcon fontSize="small" style={{ marginLeft: theme.spacing(0.5) }} />
      </Typography>
    </Tooltip>
  );

  const MandatoryLabel = ({ children }) => (
    <Tooltip title="This connection is required and used for importing completed activities.">
      <Typography
        variant="body2"
        component="span"
        style={{
          color: red[700],
          marginLeft: theme.spacing(1),
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {children}
        <InfoIcon fontSize="small" style={{ marginLeft: theme.spacing(0.5) }} />
      </Typography>
    </Tooltip>
  );
  const SectionHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: "20px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),  // Adjusted from 1 to 2 for more space
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1)
  }));


  const SectionContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0),
    backgroundColor: grey[100],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0)
  }));
  async function disconnectService(name) {
    console.log(`Attempting to disconnect service: ${name}`);
    setLoading(name);
    const idToken = await getIdTokenClaims();
    const accessToken = idToken.__raw;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ type: name }),
      redirect: "follow",
    };
    const config = getConfig();

    fetch(`${config.api2Endpoint}/thirdpartyconnection/remove`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(`${name} disconnected successfully`, result);
        onRefresh();
      })
      .catch((error) => {
        console.log(`Error disconnecting ${name}`, error);
      })
      .finally(() => {
        setLoading("");
      });
  }

  if (!userProfile) {
    console.log("userProfile is null");
    return null;
  }

  return (
    <div>
      <SectionContainer>
        <SectionHeader>
          <CloudDownloadIcon />
          Import Activities
        </SectionHeader>
        <Grid container spacing={0} alignItems="center">
          <Grid xs={6}>
            <Label>STRAVA</Label>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={userProfile.stravaauth || false}
                  onChange={() =>
                    userProfile.stravaauth
                      ? disconnectService("strava")
                      : (window.location.href =
                        "https://www.strava.com/oauth/authorize?client_id=41820&response_type=code&redirect_uri=http://www.enduroco.in%2Fthirdpartyauth2&approval_prompt=force&scope=activity:read,activity:read_all,activity:write")
                  }
                  name="stravaSwitch"
                  color="primary"
                  disabled={loading === "strava"}
                />
              }
              style={{ marginRight: "10px" }}
            />
            <HelperText name="stravaconnect" />
          </Grid>
        </Grid>
      </SectionContainer >
      {/* <Divider variant="middle" /> */}
      < SectionContainer >

        <SectionHeader>
          <CloudUploadIcon />
          Export Workouts
        </SectionHeader>
        {/* TRAINING PEAKS Connection */}
        <Grid container spacing={0} alignItems="center">
          <Grid xs={6}>
            <Label>TRAINING PEAKS</Label>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={userProfile.trainingpeaksauth || false}
                  onChange={() =>
                    userProfile.trainingpeaksauth
                      ? disconnectService("trainingpeaks")
                      : (window.location.href =
                        "https://oauth.trainingpeaks.com/OAuth/Authorize?response_type=code&client_id=enduro-co&scope=workouts%3Aplan%20workouts%3Aread%20athlete%3Aprofile&redirect_uri=https%3A%2F%2Fenduroco.in%2Fthirdpartyauth2")
                  }
                  color="primary"
                  disabled={loading === "trainingpeaks"}
                />
              }
              label=""
              style={{ marginRight: "10px" }}
            />
            <HelperText name="trainingpeaksconnect" />
          </Grid>
        </Grid>
        <Grid container spacing={0} alignItems="center">
          <Grid xs={6}>
            <Label>INTERVALS.ICU</Label>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={userProfile.intervalsauth || false}
                  onChange={() =>
                    userProfile.intervalsauth
                      ? disconnectService("intervals")
                      : (window.location.href =
                        "https://intervals.icu/oauth/authorize?client_id=9&redirect_uri=https://www.enduroco.in/thirdpartyauth&scope=ACTIVITY:READ,WELLNESS:WRITE,CALENDAR:WRITE,LIBRARY:WRITE,SETTINGS:WRITE")
                  }
                  name="intervalsSwitch"
                  color="primary"
                  disabled={loading === "intervals"}
                />
              }
              style={{ marginRight: "10px" }}
            />
            <HelperText name="intervalsconnect" />
          </Grid>
        </Grid>
        <Grid container spacing={0} alignItems="center">
          <Grid xs={6}>
            <Label>WAHOO</Label>
          </Grid>
          <Grid xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={userProfile.wahooauth || false}
                  onChange={() => {
                    const url = new URL('https://api.wahooligan.com/oauth/authorize');
                    url.searchParams.append('response_type', 'code');
                    url.searchParams.append('client_id', "SwPrHw3Nd_dHWHMyYnH5-LzrPL2EqySedG4PGpZfC6A");
                    url.searchParams.append('redirect_uri', "https://www.enduroco.in/thirdpartyauthwahoo");
                    url.searchParams.append('scope', 'user_read user_write email workouts_read workouts_write plans_read plans_write power_zones_read power_zones_write offline_data');
                    userProfile.wahooauth ? disconnectService("wahoo") : window.location.href = url.toString();
                  }}
                  name="wahooSwitch"
                  color="primary"
                  disabled={loading === "wahoo"}
                />
              }
              label=""
              sx={{ mr: 2 }}
              style={{ marginRight: "10px" }}
            />
            <HelperText name="wahooconnect" />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid xs={6}>
            <Label>GARMIN</Label>
          </Grid>
          <Grid xs={6} style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
            <GarminConnect userProfile={userProfile} onRefresh={onRefresh} />
            <HelperText name="wahooconnect" />
          </Grid>
        </Grid>
      </SectionContainer >

    </div >
  );
}

export default Connections;
