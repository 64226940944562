import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import AuthButtons from "../views/navbar/components/AuthButtons";
import HeroLinks from "./HeroLinks";

const Hero = () => (
  <Box
    className="banner_section"
    sx={{
      position: "relative",
      zIndex: 1,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      color: "white",
      textAlign: "center",
      p: 4,
      minHeight: "500px",
    }}
  >
    <Box className="container" sx={{ position: "relative", zIndex: 2 }}>
      <Box className="row align-items-center" sx={{ height: "100%" }}>
        <Box className="col-md-12">
          <Stack
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <AuthButtons />
            <Typography
              variant="h3"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Peak Performance for Run, Ride, Tri
              <br /> Your Daily AI-Driven Training Ally
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "white", fontWeight: "bold" }}
            >
              Your Best Race Awaits: AI-Crafted Plans from Sprints to Ironmans
            </Typography>
            <HeroLinks />
          </Stack>
        </Box>
      </Box>
    </Box>
    <Box
      className="overlay-gradient"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 0,
      }}
    ></Box>
  </Box>
);

export default Hero;
