import React from 'react';
import { Button, Snackbar } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { styled } from '@mui/material/styles'; // Import for styling

const JoinClubButton = ({ accessToken, clubId, isMember, onMembershipChange }) => {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const joinOrLeaveClub = async () => {
        try {
            const endpoint = isMember ? '/leave' : '/join';
            const response = await fetch(`https://api2.enduroco.in/clubs/${clubId}${endpoint}`, {
                method: 'POST',
                headers: new Headers({
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                })
            });
            if (!response.ok) throw new Error('HTTP status ' + response.status);
            setMessage(`Successfully ${isMember ? 'left' : 'joined'} the club!`);
            onMembershipChange(); // Trigger a refresh in the parent component
        } catch (error) {
            setMessage(`Failed to ${isMember ? 'leave' : 'join'} the club: ${error.message}`);
        }
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const buttonStyles = {
        color: 'white',
        backgroundColor: isMember ? 'crimson' : 'green',
        '&:hover': {
            backgroundColor: isMember ? 'firebrick' : 'darkgreen',
        },
        minWidth: '80px',
        fontSize: '1rem',
        padding: '10px 10px',
        margin: '8px'
    };
    const OutlinedButton = styled(Button)`
    color: ${isMember ? 'crimson' : 'green'};
    border: 2px solid ${isMember ? 'crimson' : 'green'};
    min-width: 80px;
    font-size: 1rem;
    padding: 10px 10px;
    margin: 8px;       

    &:hover {
        background-color: ${isMember ? 'rgba(205, 92, 92, 0.1)' : 'rgba(0, 128, 0, 0.1)'}; /* Light background on hover */
        border-color: ${isMember ? 'firebrick' : 'darkgreen'}; /* Slightly darker color on hover */
    }
`;

    return (
        <>
            <OutlinedButton
                startIcon={isMember ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                variant="outlined"
                onClick={joinOrLeaveClub}
            >
                {isMember ? 'Leave Club' : 'Join Club'}
            </OutlinedButton>
            <Snackbar
            // ... Snackbar remains the same ...
            />
        </>
    );
};

export default JoinClubButton;
