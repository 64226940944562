import React from 'react';
import { Card, CardContent, Typography, Box, Container } from '@mui/material';

const Contact = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Contact Us
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Rohan Sharma
            </Typography>
            <Typography variant="body1" gutterBottom>
              Email: rohan@enduroco.in
            </Typography>
            <Typography variant="body1" paragraph>
              8/9, Sector 3, Rajendra Nagar<br />
              Ghaziabad, Uttar Pradesh 201005<br />
              India
            </Typography>
            <Typography variant="body1" sx={{ mt: 4 }}>
              For any questions or concerns, please feel free to reach out to us using the contact information provided above.
              We aim to respond to all inquiries within 24-48 business hours.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Contact;
