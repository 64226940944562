import React from 'react';
import { Box, Typography, Alert, Radio, FormControlLabel, RadioGroup, Paper } from '@mui/material';
import { Info, Zap, Heart } from 'lucide-react';
import ThresholdInput from '../ThresholdInput';

const IntegratedCyclingMetrics = ({ selectedMetrics, thresholds, isEditing, onMetricsChange, onThresholdChange }) => {
  const radioValue = Array.isArray(selectedMetrics) ? 
    (selectedMetrics.includes('Power') && selectedMetrics.includes('HR') ? 'Both' : selectedMetrics[0]) 
    : '';

  const handleRadioChange = (event) => {
    const value = event.target.value;
    let newMetrics;
    switch(value) {
      case 'Power':
        newMetrics = ['Power'];
        break;
      case 'HR':
        newMetrics = ['HR'];
        break;
      case 'Both':
        newMetrics = ['Power', 'HR'];
        break;
      default:
        newMetrics = [];
    }
    onMetricsChange(null, newMetrics);
  };

  const renderThresholdInputs = (value) => {
    return (
      <Box sx={{ 
        backgroundColor: 'background.paper',
        borderRadius: 2,
        p: 2,
        mt: -2,
        border: '2px solid',
        borderColor: 'primary.main',
        borderTop: 'none',
        mb: 2,
        position: 'relative',
        zIndex: 0
      }}>
        {(value === 'Power' || value === 'Both') && (
          <ThresholdInput
            sport="cycling"
            metric="ftp"
            label="Functional Threshold Power (FTP)"
            min={50}
            max={500}
            value={thresholds.ftp.threshold}
            onChange={(e) => onThresholdChange('ftp', 'threshold', e.target.value)}
            isEditing={isEditing}
            enabled={true}
            unit="watts"
          />
        )}
        {(value === 'HR' || value === 'Both') && (
          <ThresholdInput
            sport="cycling"
            metric="lthr"
            label="Lactate Threshold Heart Rate (LTHR)"
            min={60}
            max={220}
            value={thresholds.lthr.threshold}
            onChange={(e) => onThresholdChange('lthr', 'threshold', e.target.value)}
            isEditing={isEditing}
            enabled={true}
            unit="bpm"
          />
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Typography 
        variant="subtitle1" 
        sx={{ 
          mb: 2,
          fontWeight: 500,
          color: 'text.primary'
        }}
      >
        Training Method
      </Typography>

      <RadioGroup
        value={radioValue}
        onChange={handleRadioChange}
        sx={{ width: '100%' }}
      >
        {['Power', 'HR', 'Both'].map((value) => (
          <Box key={value}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: 2,
                border: '2px solid',
                borderColor: value === radioValue ? 'primary.main' : 'divider',
                backgroundColor: 'background.paper',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  borderColor: 'primary.main',
                },
                position: 'relative',
                zIndex: 1,
                mb: value === radioValue ? 0 : 2
              }}
            >
              <FormControlLabel
                value={value}
                disabled={!isEditing}
                control={
                  <Radio 
                    sx={{
                      color: 'text.primary',
                      '&.Mui-checked': {
                        color: 'primary.main',
                      }
                    }}
                  />
                }
                label={
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1.5,
                    color: 'text.primary',
                  }}>
                    {value === 'Power' && <Zap size={20} />}
                    {value === 'HR' && <Heart size={20} />}
                    {value === 'Both' && (
                      <Box sx={{ display: 'flex', gap: 0.5 }}>
                        <Zap size={20} />
                        <Heart size={20} />
                      </Box>
                    )}
                    <Typography sx={{ 
                      fontWeight: value === radioValue ? 600 : 400,
                    }}>
                      {value === 'Power' && 'Power Based Training Only'}
                      {value === 'HR' && 'Heart Rate Based Training Only'}
                      {value === 'Both' && 'Use Both Power & Heart Rate'}
                    </Typography>
                  </Box>
                }
                sx={{
                  mx: 0,
                  px: 2,
                  py: 1.5,
                  width: '100%',
                }}
              />
            </Paper>
            {value === radioValue && renderThresholdInputs(value)}
          </Box>
        ))}
      </RadioGroup>

      {!isEditing && radioValue === 'Both' && (
        <Alert 
          severity="info"
          icon={<Info size={20} />}
          sx={{ 
            mt: 2,
            borderRadius: 2,
            '& .MuiAlert-message': {
              fontSize: '0.875rem'
            }
          }}
        >
          You'll receive separate workouts for both Power-based and Heart Rate-based training.
        </Alert>
      )}
    </Box>
  );
};

export default IntegratedCyclingMetrics;
