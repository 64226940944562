import SpeedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import PoolIcon from "@mui/icons-material/Pool";
import { Chip, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import RenderThresholds from "./RenderThresholds"; // Import the new component

// Define custom icons for Duathlete and Triathlete
const DuathleteIcon = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <DirectionsBikeIcon style={{ marginRight: 4 }} />
    <DirectionsRunIcon />
  </div>
);

const TriathleteIcon = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <DirectionsBikeIcon style={{ marginRight: 4 }} />
    <DirectionsRunIcon />
    <PoolIcon />
  </div>
);

const useChipStyles = () => ({
  backgroundColor: "#f5f5f5",
  color: "#757575",
  fontWeight: "normal",
});
const SettingsAccordionSummary = ({
  id,
  title,
  Icon,
  userProfileState,
  subscriptionStatus,
  thresholdSettings,
}) => {
  const theme = useTheme();
  const DefaultIcon = Icon ? Icon : ExpandMoreIcon;

  const athleteTypeMapping = {
    Bike: { label: "Cycling", Icon: PedalBikeIcon },
    Run: { label: "Running", Icon: DirectionsRunIcon },
    Duathlete: { label: "Duathlon", Icon: DuathleteIcon },
    Triathlete: { label: "Triathlon", Icon: TriathleteIcon },
  };
  const intensityMetricsMapping = {
    bikepowerwo: {
      Icon: BoltIcon,
      ActivityIcon: PedalBikeIcon,
      types: ["Bike", "Duathlete", "Triathlete"],
    },
    bikehrwo: {
      Icon: FavoriteIcon,
      ActivityIcon: PedalBikeIcon,
      types: ["Bike", "Duathlete", "Triathlete"],
    },
    runhrwo: {
      Icon: FavoriteIcon,
      ActivityIcon: DirectionsRunIcon,
      types: ["Run", "Duathlete", "Triathlete"],
    },
    runpowerwo: {
      Icon: BoltIcon,
      ActivityIcon: DirectionsRunIcon,
      types: ["Run", "Duathlete", "Triathlete"],
    },
    runpacewo: {
      Icon: SpeedIcon,
      ActivityIcon: DirectionsRunIcon,
      types: ["Run", "Duathlete", "Triathlete"],
    },
  };
  // Filter metrics based on athlete type
  const athleteType = userProfileState.athletetype;
  const selectedMetrics = Object.keys(intensityMetricsMapping).filter(
    (metric) =>
      userProfileState[metric] &&
      intensityMetricsMapping[metric].types.includes(athleteType)
  );

  const renderCustomIcon = (ActivityIcon, MetricIcon) => (
    <div style={{ display: "flex", alignItems: "center", padding: "0" }}>
      <ActivityIcon style={{ marginRight: 2, fontSize: "1.2rem" }} />
      <MetricIcon
        style={{ fontSize: "1.2rem", color: theme.palette.primary.main }}
      />
    </div>
  );

  const trainingPhases = [
    {
      ramp: 10,
      value: 0,
      label: "Tapering",
    },
    {
      ramp: -10,
      value: 1,
      label: "Maintenance",
    },
    {
      ramp: -20,
      value: 2,
      label: "Build-Up",
    },
    {
      ramp: -30,
      value: 3,
      label: "Quick Build",
    },
    {
      ramp: -40,
      value: 4,
      label: "Intense Boost",
    },
  ];

  const getRampRateLabel = (targetFormPct) => {
    // Assuming ramp rates are sorted in descending order in your trainingPhases
    const sortedPhases = [...trainingPhases].sort((a, b) => b.ramp - a.ramp);
    let closestPhase = sortedPhases[0]; // Default to the first phase as the closest

    for (let phase of sortedPhases) {
      if (
        Math.abs(phase.ramp - targetFormPct) <
        Math.abs(closestPhase.ramp - targetFormPct)
      ) {
        closestPhase = phase;
      }
    }

    return closestPhase ? closestPhase.label : "Unknown Phase";
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
    >
      <DefaultIcon />
      <Typography variant="h6">{title}</Typography>
      {id === "profile" && userProfileState?.email && (
        <Chip
          label={userProfileState.email}
          size="small"
          style={{ useChipStyles }}
        />
      )}
      {id === "workoutDiscipline" && (
        <Chip
          icon={React.createElement(
            athleteTypeMapping[userProfileState.athletetype]?.Icon
          )}
          label={athleteTypeMapping[userProfileState.athletetype]?.label}
          size="small"
          style={{ useChipStyles }}
        />
      )}
      {id === "connections" && (
        <Chip
          icon={userProfileState.stravaauth ? <LinkIcon /> : <LinkOffIcon />}
          label="Strava"
          size="small"
          style={{ useChipStyles }}
        />
      )}
      {id === "workoutType" && selectedMetrics.length > 0 && (
        <Tooltip title="Selected Intensity Metrics">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            {selectedMetrics.map((metric) => (
              <Chip
                key={metric}
                icon={renderCustomIcon(
                  intensityMetricsMapping[metric].ActivityIcon,
                  intensityMetricsMapping[metric].Icon
                )}
                label="" // Keeping label empty to focus on icons
                size="small"
                style={{ useChipStyles }}
              />
            ))}
          </div>
        </Tooltip>
      )}
      {id === "volume" && userProfileState.volumeSelectionMethod && (
        <Chip
          label={`${
            userProfileState.volumeSelectionMethod.charAt(0).toUpperCase() +
            userProfileState.volumeSelectionMethod.slice(1)
          }`}
          size="small"
          style={{ useChipStyles }}
        />
      )}
      {id === "volume" &&
        userProfileState.volumeSelectionMethod === "manual" && (
          <Chip
            label={getRampRateLabel(userProfileState.targetformpct)}
            size="small"
            style={{
              backgroundColor: "#f5f5f5",
              color: "#757575",
              fontWeight: "normal",
            }}
          />
        )}
      {id === "advancedsettings" && (
        <Chip
          label={getRampRateLabel(userProfileState.targetformpct)}
          size="small"
          style={{ useChipStyles }}
        />
      )}
      <RenderThresholds
        id={id}
        thresholdSettings={thresholdSettings}
        userProfileState={userProfileState}
        intensityMetricsMapping={intensityMetricsMapping}
      />

      {id === "billing" && subscriptionStatus && (
        <Chip
          label={subscriptionStatus}
          size="small"
          style={{ useChipStyles }}
        />
      )}
    </div>
  );
};

export default SettingsAccordionSummary;
