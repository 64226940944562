import React from 'react';
import { Typography, Box } from '@mui/material';
import DaysPerWeekSelector from './DaysPerWeekSelector';

const ManualVolumeSettings = ({ localWorkoutVolume, handleChange, isEditing, errors }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        Training Days
      </Typography>
      <DaysPerWeekSelector
        value={localWorkoutVolume.manual.numberofdaysweekly || ''}
        onChange={(value) => handleChange({
          target: {
            name: 'numberofdaysweekly',
            value
          }
        })}
        disabled={!isEditing}
      />
      {errors?.numberofdaysweekly && (
        <Typography color="error" variant="caption" sx={{ mt: 0.5, display: 'block' }}>
          {errors.numberofdaysweekly}
        </Typography>
      )}
    </Box>
  );
};

export default ManualVolumeSettings;
