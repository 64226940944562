import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
  useTheme,
  useMediaQuery,
  IconButton,
  alpha,
  Tooltip,
  Snackbar,
  Alert
} from '@mui/material';
import { Link2, Link2Off } from 'lucide-react';

const ServiceCard = ({ service, onRefresh, disabled }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getIdTokenClaims } = useAuth0();

  const handleGarminConnect = async () => {
    setLoading(true);
    try {
      const tokenClaims = await getIdTokenClaims();
      const accessToken = tokenClaims.__raw;

      const response = await fetch('https://api2.enduroco.in/thirdpartyconnection/get-garmin-auth-url', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'accept': '*/*',
          'accept-language': 'en-US,en;q=0.9'
        }
      });
      
      const data = await response.json();
      if (data?.authUrl) {
        window.location.href = data.authUrl;
      } else {
        throw new Error('No auth URL received from server');
      }
    } catch (error) {
      console.error('Garmin connection error:', error);
      setError(error.message || 'Failed to connect to Garmin');
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnect = async () => {
    setLoading(true);
    try {
      const tokenClaims = await getIdTokenClaims();
      const accessToken = tokenClaims.__raw;

      const response = await fetch('https://api2.enduroco.in/thirdpartyconnection/remove', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ type: service.id }),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      onRefresh();
    } catch (error) {
      console.error(`Disconnect error:`, error);
      setError(error.message || 'Failed to disconnect service');
    } finally {
      setLoading(false);
    }
  };

  const handleAction = () => {
    if (service.isConnected) {
      handleDisconnect();
    } else if (service.useCustomAuth) {
      handleGarminConnect();
    } else {
      window.location.href = service.authUrl;
    }
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          position: 'relative',
          p: { xs: 1.5, sm: 2.5 },
          border: '1px solid',
          borderColor: service.isConnected ? 'success.main' : 'divider',
          borderLeftWidth: service.isConnected ? 4 : 1,
          bgcolor: service.isConnected ? alpha(theme.palette.success.main, 0.04) : 'background.paper',
          width: '100%',
          transition: theme.transitions.create(['background-color', 'border-color', 'box-shadow'], {
            duration: theme.transitions.duration.short,
          }),
          '&:hover': !disabled && {
            boxShadow: 1
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 1.5, sm: 3 }
          }}
        >
          <Box
            component="img"
            src={service.icon}
            alt={service.name}
            sx={{
              width: { xs: 40, sm: 56 },
              height: { xs: 40, sm: 56 },
              objectFit: 'contain',
              flexShrink: 0,
              filter: disabled ? 'grayscale(100%)' : 'none',
              opacity: disabled ? 0.5 : 1
            }}
          />

          <Box sx={{
            flex: 1,
            minWidth: 0
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 0.5,
              flexWrap: 'wrap'
            }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  color: disabled ? 'text.disabled' : 'text.primary',
                  fontSize: { xs: '0.9rem', sm: '1rem' }
                }}
              >
                {service.name}
              </Typography>
              {service.isConnected && (
                <Typography
                  variant="caption"
                  sx={{
                    color: 'success.main',
                    bgcolor: alpha(theme.palette.success.main, 0.1),
                    px: 1,
                    py: 0.25,
                    borderRadius: 1,
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    display: { xs: 'none', sm: 'block' }
                  }}
                >
                  Active
                </Typography>
              )}
            </Box>
            <Typography
              variant="body2"
              sx={{
                color: disabled ? 'text.disabled' : 'text.secondary',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                fontSize: { xs: '0.8rem', sm: '0.875rem' },
                lineHeight: '1.3'
              }}
            >
              {service.description}
            </Typography>
          </Box>

          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <Tooltip title={service.isConnected ? "Disconnect" : "Connect to service"}>
              <IconButton
                onClick={handleAction}
                disabled={disabled}
                size="small"
                color={service.isConnected ? "error" : "primary"}
                sx={{
                  border: '1px solid',
                  borderColor: 'currentColor',
                  p: { xs: 1, sm: 1.2 },
                  flexShrink: 0,
                  '&:hover': {
                    bgcolor: service.isConnected
                      ? alpha(theme.palette.error.main, 0.04)
                      : alpha(theme.palette.primary.main, 0.04),
                  }
                }}
              >
                {service.isConnected ? (
                  <Link2Off size={isMobile ? 16 : 18} />
                ) : (
                  <Link2 size={isMobile ? 16 : 18} />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Paper>
      
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setError(null)} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ServiceCard;
