import React from "react";
import Typography from "@mui/material/Typography";
import GaugeBg from "./gauge-bg.png";

const gaugeWidth = 170;
const gaugeHeight = 50;
const gaugeContentWidth = gaugeWidth - 12;
const gaugeBarsNb = 20;
const gaugeBarWidth = gaugeContentWidth / gaugeBarsNb;
const gaugeBarMargin = 1;
const gaugeBarRadius = 10;

const lowBattery = -20;

const styles = {
  container: {
    position: "relative",
    width: `${gaugeWidth}px`,
    height: `${gaugeHeight}px`,
  },
  barsContainer: {
    width: `${gaugeWidth}px`,
    height: `${gaugeHeight}px`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "3px",
  },
  barContainer: {
    width: `${gaugeBarWidth}px`,
    height: `${gaugeHeight - 10}px`,
    paddingLeft: `${gaugeBarMargin}px`,
    paddingRight: `${gaugeBarMargin}px`,
  },
  bar: {
    width: `${gaugeBarWidth - gaugeBarMargin * 2}px`,
    height: "100%",
    backgroundColor: "#3f5c8c",
    zIndex: 1,
  },
  barFirst: {
    borderTopLeftRadius: `${gaugeBarRadius}px`,
    borderBottomLeftRadius: `${gaugeBarRadius}px`,
  },
  barLast: {
    borderTopRightRadius: `${gaugeBarRadius}px`,
    borderBottomRightRadius: `${gaugeBarRadius}px`,
  },
  barRed: {
    backgroundColor: "#8b0000",
  },
  bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: 0,
  },
  barText: {
    marginTop: `5px`,
  },
  red: {
    color: "red",
  },
  green: {
    color: "green",
  },
};

const Battery = ({ percentage, form }) => {
  //limit percent between + and -50
  var percentageLimited = percentage;
  if (percentageLimited > 30) percentageLimited = 30;
  if (percentageLimited < -30) percentageLimited = -30;
  const percent10 = Math.round((parseInt(percentageLimited, 10) + 30) / 3);
  // if (percent10 <= 10) percent10 = 10
  const percentageArray = [...Array(percent10).keys()];
  return (
    <>
      <div style={styles.container}>
        <img src={GaugeBg} style={styles.bg} alt="BatteryBG" />
        <div style={styles.barsContainer}>
          {percentageArray.map((ele, index) => (
            <div key={index} style={styles.barContainer}>
              {" "}
              {/* Added key prop */}
              {index === 0 ? (
                <div
                  key={index} // Added key prop
                  style={{ ...styles.bar, ...styles.barFirst }}
                />
              ) : index === gaugeBarsNb - 1 ? (
                <div key={index} style={{ ...styles.bar, ...styles.barLast }} />
              ) : (
                <div key={index} style={{ ...styles.bar }} />
              )}
            </div>
          ))}
        </div>
      </div>
      <Typography variant="h5" gutterBottom style={styles.barText}>
        Form
        {percentage < lowBattery ? (
          <span style={styles.red}> {percentage}% </span>
        ) : (
          <span style={styles.green}> {percentage}%</span>
        )}
      </Typography>
    </>
  );
};

export default Battery;
