import React from 'react';
import { Box, Button } from '@mui/material';
import { UserPlus } from 'lucide-react';

const InitialMenu = ({ onSelect }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Button
        variant="outlined"
        onClick={() => onSelect('profile_setup')}
        startIcon={<UserPlus size={20} />}
        sx={{
          justifyContent: 'flex-start',
          textAlign: 'left',
          py: 1.5,
          px: 2,
          borderRadius: 2,
          borderColor: 'divider',
          '&:hover': {
            borderColor: 'primary.main',
            bgcolor: 'primary.50'
          }
        }}
      >
        Help me set up my profile
      </Button>
    </Box>
  );
};

export default InitialMenu;