import React from 'react';
import { Container, Typography, Card, CardContent } from '@mui/material';

const Refund = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Cancellation and Refund Policy
          </Typography>
          
          <Typography variant="body1" paragraph>
            At EnduroCo, we strive to ensure complete satisfaction with our services. This policy outlines our procedures for cancellations and refunds.
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Subscription Cancellation
          </Typography>
          <Typography variant="body1" paragraph>
            You may cancel your subscription at any time through your account settings. Once cancelled, you will continue to have access to the service until the end of your current billing period.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Refund Eligibility
          </Typography>
          <Typography variant="body1" paragraph>
            - Refund requests must be made within 14 days of purchase
            - First-time subscribers are eligible for a full refund if requested within the first 14 days
            - Refunds are processed to the original payment method
          </Typography>

          <Typography variant="h6" gutterBottom>
            Non-Refundable Items
          </Typography>
          <Typography variant="body1" paragraph>
            - Partial month usage
            - Special promotional offers
            - Add-on services
          </Typography>

          <Typography variant="h6" gutterBottom>
            Refund Process
          </Typography>
          <Typography variant="body1" paragraph>
            To request a refund, please contact our support team at rohan@enduroco.in. Include your account email and reason for the refund request.
          </Typography>

          <Typography variant="body1" sx={{ mt: 4 }}>
            We reserve the right to modify this policy at any time. Any changes will be effective immediately upon posting to our website.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Refund;
