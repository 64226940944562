import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Divider,
    Snackbar,
    Alert,
    Paper,
    Container,
    Card,
    CardContent,
    Grid,
    Chip,
    Button,
} from '@mui/material';
import { Dumbbell, Edit2, Calendar, Clock, TrendingUp } from 'lucide-react';
import VolumeMethodSelector from './components/VolumeMethodSelector';
import ManualVolumeSettings from './components/ManualVolumeSettings';
import TimeBasedSettings from './components/TimeBasedSettings';
import FloatingSaveButton from './components/FloatingSaveButton';
import CommonWorkoutSettings from './CommonWorkoutSettings';
import { DEFAULT_WORKOUT_VOLUME, validateWorkoutSettings } from './WorkoutSettingsTypes';
import { updateWorkoutSettings } from './WorkoutSettingsService';
import { getRampRateInfo } from './utils/workoutConstants';
import { getValidationSummary } from './utils/validationUtils';
import DailyDurationCalendar from './components/DailyDurationCalendar';

const WorkoutSettingsSection = ({
    workoutVolume,
    discipline: initialDiscipline,
    accessToken,
    userEmail,
    onRefresh,
    hasEvent
}) => {
    console.log('Initial workoutVolume received:', workoutVolume);

    const getRestDay = (weekstart) => {
        switch (weekstart) {
            case '0': return 'Sun';
            case '6': return 'Sat';
            case '1': return 'Mon';
            case '2': return 'Tue';
            default:
                console.error('Invalid weekstart:', weekstart);
                throw new Error(`Invalid weekstart: ${weekstart}`);
        }
    };

    const [discipline, setDiscipline] = useState(initialDiscipline);
    const [localWorkoutVolume, setLocalWorkoutVolume] = useState(() => {
        if (!workoutVolume) return DEFAULT_WORKOUT_VOLUME;
        return workoutVolume;
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [errors, setErrors] = useState({});
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        if (workoutVolume) {
            setLocalWorkoutVolume(workoutVolume);
        }
    }, [workoutVolume]);

    useEffect(() => {
        const hasWorkoutVolumeChanged = JSON.stringify(workoutVolume) !== JSON.stringify(localWorkoutVolume);
        const hasDisciplineChanged = initialDiscipline !== discipline;
        setHasChanges(isEditing && (hasWorkoutVolumeChanged || hasDisciplineChanged));
    }, [workoutVolume, localWorkoutVolume, initialDiscipline, discipline, isEditing]);

    const handleDisciplineChange = (newDiscipline) => {
        setDiscipline(newDiscipline);
        setHasChanges(true);
    };

    const handleWeekStartChange = (value) => {
        setLocalWorkoutVolume(prev => ({
            ...prev,
            manual: {
                ...prev.manual,
                weekstart: value
            }
        }));
        setHasChanges(true);
    };

    const handleRampRateChange = (event) => {
        setLocalWorkoutVolume(prev => ({
            ...prev,
            manual: {
                ...prev.manual,
                targetformpct: event.target.value
            }
        }));
        setHasChanges(true);
    };

    const handleVolumeMethodChange = (method) => {
        console.log('Switching volume method to:', method);
        setLocalWorkoutVolume(prev => {
            const currentWeekstart = prev.manual?.weekstart;
            console.log('Current weekstart when switching methods:', currentWeekstart);

            if (method === 'timebased') {
                const restDay = getRestDay(currentWeekstart);
                return {
                    ...prev,
                    volumeSelectionMethod: method,
                    manual: {
                        weekstart: currentWeekstart
                    },
                    timeBased: {
                        volumeSelectionTimeBasedSettings: {
                            Mon: -1, Tue: -1, Wed: -1, Thu: -1, Fri: -1, Sat: -1, Sun: -1,
                            [restDay]: 0
                        }
                    }
                };
            }

            return {
                ...prev,
                volumeSelectionMethod: method,
                manual: {
                    weekstart: currentWeekstart,
                    numberofdaysweekly: '4',
                    targetformpct: '0'
                },
                timeBased: {
                    volumeSelectionTimeBasedSettings: {}
                }
            };
        });
        setHasChanges(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log('handleChange called with:', { name, value });

        if (name === 'weekstart') {
            setLocalWorkoutVolume(prev => {
                const oldRestDay = getRestDay(prev.manual.weekstart);
                const newRestDay = getRestDay(value);

                console.log('Rest day change:', { oldRestDay, newRestDay });

                if (prev.volumeSelectionMethod === 'timebased') {
                    const currentSettings = prev.timeBased?.volumeSelectionTimeBasedSettings || {};
                    const newTimeSettings = {
                        ...currentSettings,
                        [oldRestDay]: currentSettings[oldRestDay] === 0 ? -1 : currentSettings[oldRestDay],
                        [newRestDay]: 0
                    };

                    return {
                        ...prev,
                        manual: {
                            ...prev.manual,
                            weekstart: value
                        },
                        timeBased: {
                            ...prev.timeBased,
                            volumeSelectionTimeBasedSettings: newTimeSettings
                        }
                    };
                }

                return {
                    ...prev,
                    manual: {
                        ...prev.manual,
                        weekstart: value
                    }
                };
            });
        }
        else if (localWorkoutVolume.volumeSelectionMethod === 'manual') {
            setLocalWorkoutVolume(prev => ({
                ...prev,
                manual: {
                    ...prev.manual,
                    [name]: value
                }
            }));
        }
        else if (localWorkoutVolume.volumeSelectionMethod === 'timebased') {
            const restDay = getRestDay(localWorkoutVolume.manual.weekstart);
            console.log('Current rest day:', restDay);

            setLocalWorkoutVolume(prev => {
                const currentSettings = prev.timeBased?.volumeSelectionTimeBasedSettings || {};
                const newSettings = {
                    ...currentSettings,
                    [name]: name === restDay ? 0 : value,
                    [restDay]: 0
                };

                return {
                    ...prev,
                    timeBased: {
                        ...prev.timeBased,
                        volumeSelectionTimeBasedSettings: newSettings
                    }
                };
            });
        }

        setHasChanges(true);
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const validationErrors = validateWorkoutSettings(localWorkoutVolume);

            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                const errorSummary = getValidationSummary(validationErrors);
                setSnackbar({
                    open: true,
                    message: `Validation failed: ${errorSummary}`,
                    severity: 'error'
                });
                throw new Error(errorSummary);
            }

            await updateWorkoutSettings(discipline, localWorkoutVolume, accessToken, userEmail);

            setSnackbar({
                open: true,
                message: 'Settings saved successfully',
                severity: 'success'
            });

            if (onRefresh) {
                await onRefresh();
            }

            setIsEditing(false);
            setErrors({});
            setHasChanges(false);
        } catch (error) {
            console.error('Validation or save error:', error);
            if (!snackbar.open) {  // Only show if not already showing validation error
                setSnackbar({
                    open: true,
                    message: 'Error saving settings: ' + (error.message || 'Unknown error'),
                    severity: 'error'
                });
            }
        } finally {
            setIsSaving(false);
        }
    };

    const renderTimeBasedSummary = () => {
        const settings = localWorkoutVolume.timeBased?.volumeSelectionTimeBasedSettings || {};
        return <DailyDurationCalendar
            settings={settings}
            weekstart={localWorkoutVolume.manual?.weekstart}
        />;
    };

    const renderSettingsView = () => {
        const rampRate = localWorkoutVolume.manual?.targetformpct;
        const rampRateInfo = getRampRateInfo(rampRate);

        return (
            <Box sx={{ mt: 2 }}>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <CommonWorkoutSettings
                                    discipline={discipline}
                                    onDisciplineChange={handleDisciplineChange}
                                    weekstart={localWorkoutVolume.manual?.weekstart}
                                    onWeekStartChange={handleWeekStartChange}
                                    rampRate={localWorkoutVolume.manual?.targetformpct || '0'}
                                    onRampRateChange={handleRampRateChange}
                                    isEditing={false}
                                    errors={errors}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{
                                    bgcolor: 'background.paper',
                                    p: 2,
                                    borderRadius: 1,
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    mb: 2
                                }}>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        Training Method
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {localWorkoutVolume.volumeSelectionMethod === 'manual' ? (
                                            <Calendar size={20} />
                                        ) : (
                                            <Clock size={20} />
                                        )}
                                        <Typography variant="body1">
                                            {localWorkoutVolume.volumeSelectionMethod === 'manual'
                                                ? 'Weekly Schedule'
                                                : 'Daily Duration'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            {localWorkoutVolume.volumeSelectionMethod === 'manual' ? (
                                <Grid item xs={12}>
                                    <Box sx={{
                                        bgcolor: 'background.paper',
                                        p: 2,
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: 'divider'
                                    }}>
                                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                            Training Days
                                        </Typography>
                                        <Chip
                                            label={`${localWorkoutVolume.manual.numberofdaysweekly || '0'} days per week`}
                                            color="primary"
                                            size="small"
                                        />
                                    </Box>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    {renderTimeBasedSummary()}
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        );
    };

    return (
        <Container maxWidth="md" sx={{ p: { xs: 0, sm: 1 } }}>
            <Paper elevation={0} sx={{ p: { xs: 2, sm: 3 }, margin: 'auto', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Dumbbell size={32} style={{ marginRight: '12px', color: '#1976d2' }} />
                        <Typography variant="h5" component="h1">
                            Training Settings
                        </Typography>
                    </Box>
                    {!hasEvent && (
                        <Button
                            variant={isEditing ? "outlined" : "contained"}
                            color={isEditing ? "secondary" : "primary"}
                            onClick={() => setIsEditing(!isEditing)}
                            startIcon={<Edit2 size={16} />}
                        >
                            {isEditing ? "Cancel" : "Edit Settings"}
                        </Button>
                    )}
                </Box>
                <Divider sx={{ mb: 3 }} />

                {isEditing ? (
                    <>
                        <CommonWorkoutSettings
                            discipline={discipline}
                            onDisciplineChange={handleDisciplineChange}
                            weekstart={localWorkoutVolume.manual?.weekstart}
                            onWeekStartChange={handleWeekStartChange}
                            rampRate={localWorkoutVolume.manual?.targetformpct || '0'}
                            onRampRateChange={handleRampRateChange}
                            isEditing={true}
                            errors={errors}
                        />

                        <Box sx={{ mt: 4 }}>
                            <Typography variant="h6" gutterBottom>
                                Training Volume Method
                            </Typography>
                            <VolumeMethodSelector
                                value={localWorkoutVolume.volumeSelectionMethod}
                                onChange={handleVolumeMethodChange}
                                disabled={hasEvent}
                            />

                            {localWorkoutVolume.volumeSelectionMethod === 'manual' && (
                                <ManualVolumeSettings
                                    localWorkoutVolume={localWorkoutVolume}
                                    handleChange={handleChange}
                                    isEditing={true}
                                    errors={errors}
                                />
                            )}
                            {localWorkoutVolume.volumeSelectionMethod === 'timebased' && (
                                <TimeBasedSettings
                                    localWorkoutVolume={localWorkoutVolume}
                                    handleChange={handleChange}
                                    isEditing={true}
                                    errors={errors}
                                />
                            )}
                        </Box>
                    </>
                ) : (
                    renderSettingsView()
                )}
            </Paper>

            <FloatingSaveButton
                onSave={handleSave}
                isSaving={isSaving}
                hasChanges={hasChanges}
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default WorkoutSettingsSection;
