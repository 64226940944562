import { useState } from 'react';

const useProfileUpdates = () => {
    const [selectedGender, setSelectedGender] = useState(null);

    const handleGenderSelection = async (gender, processInput) => {
        setSelectedGender(gender);
        await processInput(gender);
    };

    return {
        selectedGender,
        setSelectedGender,
        handleGenderSelection
    };
};

export default useProfileUpdates;
