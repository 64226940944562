export const DEFAULT_THRESHOLDS = {
  cycling: {
    enabled: true,
    metrics: ['Power', 'HR'],
    ftp: { enabled: false, threshold: '60' },
    lthr: { enabled: false, threshold: '0' }
  },
  running: {
    enabled: true,
    metrics: ['HR'], // Default as array
    ftp: { enabled: false, threshold: '0' },
    lthr: { enabled: false, threshold: '0' },
    thresholdPace: { enabled: false, threshold: '0' }
  }
};

export const METRIC_LABELS = {
  'Power': 'Power Based Only',
  'HR': 'Heart Rate Only',
  'Both': 'Power & Heart Rate',
};

export const RUNNING_METRICS = ['HR', 'Power', 'Pace'];
