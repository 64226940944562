export const getRampRateInfo = (value) => {
  const numValue = Number(value);
  switch (numValue) {
    case -40:
      return {
        label: 'Very Aggressive',
        description: 'Maximum progression with high intensity',
        color: '#d32f2f'
      };
    case -20:
      return {
        label: 'Aggressive',
        description: 'Faster progression with challenging workouts',
        color: '#ed6c02'
      };
    case 0:
      return {
        label: 'Conservative',
        description: 'Balanced progression with moderate intensity',
        color: '#2e7d32'
      };
    case 20:
      return {
        label: 'Recovery',
        description: 'Reduced intensity for recovery periods',
        color: '#0288d1'
      };
    case 40:
      return {
        label: 'Very Easy',
        description: 'Minimal intensity for extended recovery',
        color: '#0277bd'
      };
    default:
      return {
        label: 'Not Set',
        description: '',
        color: '#666666'
      };
  }
};

export const getWeekStartLabel = (value) => {
  switch (value) {
    case '0': return 'Sunday';
    case '1': return 'Monday';
    case '2': return 'Tuesday';
    case '6': return 'Saturday';
    default: return 'Not Set';
  }
};
