import React from "react";
import { useQuery } from 'react-query';
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    Box,
    useTheme,
    Breadcrumbs,
    Link as MuiLink,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const fetchClubs = async ({ queryKey }) => {
    const [, accessToken] = queryKey;
    if (!accessToken) throw new Error("Access token is required");
    const { data } = await axios.get('https://api2.enduroco.in/clubs', {
        headers: { 'Authorization': `Bearer ${accessToken}` }
    });
    return data;
};

const AllClubs = ({ accessToken }) => {
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const theme = useTheme();

    const {
        data: clubs,
        error,
        isLoading
    } = useQuery(['clubs', accessToken], fetchClubs, {
        enabled: !!accessToken,
        onError: (error) => {
            if (error.response?.status === 401) {
                loginWithRedirect();
            }
        }
    });

    const truncateText = (text, limit) => {
        const words = text.split(" ", limit);
        return words.length >= limit ? `${words.join(" ")}...` : text;
    };

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography color="error">{error.message}</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 6 }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Link component={RouterLink} color="inherit" to="/">Home</Link>
                <Typography color="text.primary">Clubs</Typography>
            </Breadcrumbs>
            <Paper
                sx={{
                    p: 1,
                    mt: 3,
                    mb: 3,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Typography variant="h4" sx={{ mb: 2, width: "100%", textAlign: "center", flexGrow: 1 }}>
                    Featured Clubs
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        p: 3,
                        flexGrow: 1,
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: "10px",
                        margin: "0 10px",
                        cursor: "pointer",
                    }}
                    onClick={() => navigate("/clubs/3e0aab0f-a8be-4e60-8b20-d0017990dba5")}
                >
                    <Typography variant="h5" sx={{ mb: 1 }}>
                        CCC
                    </Typography>
                </Box>
            </Paper>
            <Typography variant="h4" gutterBottom>My Clubs</Typography>
            {clubs.length > 0 ? (
                <TableContainer component={Paper} elevation={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold", fontSize: "1.25rem", color: theme.palette.primary.main }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clubs.map((club) => (
                                <TableRow key={club.clubId} hover onClick={() => navigate(`/clubs/${club.clubId}`)} style={{ cursor: 'pointer' }}>
                                    <TableCell sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: '1.1rem' }}>{club.name}</TableCell>
                                    <TableCell sx={{ color: theme.palette.text.secondary }}>{truncateText(club.description, 20)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Paper sx={{ p: 4, mt: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>No clubs found. Start by creating your own club!</Typography>
                </Paper>
            )}
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => navigate("/clubs/create")}
                sx={{ textTransform: "none" }}
            >
                Create New Club
            </Button>
        </Container>
    );
};

export default withAuthenticationRequired(AllClubs, {
    onRedirecting: () => <CircularProgress />,
    returnTo: "/login",
});
