import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import authConfig from '../auth_config.json';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const UserProfile = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const resetPassword = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: authConfig.audience,
        scope: 'update:users' // Ensure this scope is authorized in Auth0
      });
      const response = await fetch(`https://${authConfig.domain}/dbconnections/change_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          client_id: authConfig.clientId,
          email: user.email,
          connection: 'Username-Password-Authentication'
        }),
      });

      if (response.ok) {
        alert('Password reset email sent!');
      } else {
        const errData = await response.json();
        console.error('Failed to send password reset email:', errData);
        alert(`Failed to send password reset email: ${errData.error_description || errData.message}`);
      }
    } catch (error) {
      console.error('Error during password reset:', error);
      alert('Error sending password reset email. Check console for more details.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ bgcolor: '#fff', padding: 3, marginTop: 3, borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="body1" gutterBottom>
          Email: {user.email}
        </Typography>
        <Button variant="contained" color="primary" fullWidth onClick={resetPassword}>
          Get Password Reset Email
        </Button>
      </Box>
    </Container>
  );
};

export default UserProfile;
