// getIconForEventType.js
import React from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";

export const getIconForEventType = (eventType, size = "small") => {
  console.log({ eventType });
  switch (eventType) {
    case "Run":
      return <DirectionsRunIcon fontSize={size} />;
    case "Ride":
      return <DirectionsBikeIcon fontSize={size} />;
    case "Swim":
      return <PoolIcon fontSize={size} />;
    case "Strength":
      return <FitnessCenterIcon fontSize={size} />;
    default:
      return null; // Or a default icon
  }
};
