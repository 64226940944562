import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { AccessTime as AccessTimeIcon, Warning as WarningIcon } from '@mui/icons-material';
import useSWR from 'swr';

const paperStyle = {
  padding: '20px',
  backgroundColor: '#FFFDE7',
  margin: '20px 0',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const PaymentPending = ({ email, accessToken }) => {
  const { data: settings, error } = useSWR(
    ['https://api2.enduroco.in/settings', email],
    async ([url, email]) => {
      const response = await fetch(`${url}?email=${encodeURIComponent(email)}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Accept': 'application/json'
        }
      });
      if (!response.ok) throw new Error('Failed to fetch subscription status');
      return response.json();
    }
  );

  if (!settings || error) return null;

  const subscription = settings.subscription?.currentPlan;

  const shouldShowBanner = () => {
    if (!subscription) return true;
    if (subscription.status !== 'active') return true;

    const expiryDate = new Date(subscription.expiryDate);
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

    return expiryDate <= sevenDaysFromNow;
  };

  if (!shouldShowBanner()) return null;

  const getBannerMessage = () => {
    if (!subscription) {
      return "No active subscription found. Please subscribe to continue using our services.";
    }

    if (subscription.status !== 'active') {
      return "Your subscription is currently inactive. Please renew to continue using our services.";
    }

    const expiryDate = new Date(subscription.expiryDate);
    return `Your subscription will expire on ${expiryDate.toLocaleDateString()}. Please renew to avoid service interruption.`;
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid xs={12} md={8}>
        <Paper elevation={3} style={paperStyle}>
          <div style={{ display: 'flex', alignItems: 'start', gap: '12px' }}>
            <WarningIcon style={{ color: '#F59E0B' }} />
            <div>
              <Typography variant="body1" style={{ color: '#333' }}>
                {getBannerMessage()}
              </Typography>
              {subscription?.tier && (
                <Typography variant="body2" style={{ marginTop: '8px', color: '#666' }}>
                  Current Plan: {subscription.tier}
                </Typography>
              )}
              {subscription?.expiryDate && (
                <Typography variant="body2" style={{ marginTop: '4px', color: '#666', display: 'flex', alignItems: 'center' }}>
                  <AccessTimeIcon style={{ fontSize: '16px', marginRight: '4px' }} />
                  Expires: {new Date(subscription.expiryDate).toLocaleDateString()}
                </Typography>
              )}
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PaymentPending;