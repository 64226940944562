import React from 'react';
import { Box, Typography, Alert, Radio, FormControlLabel, RadioGroup, Paper } from '@mui/material';
import { Info, Zap, Heart, Timer } from 'lucide-react';
import ThresholdInput from '../ThresholdInput';

const IntegratedRunningMetrics = ({ selectedMetric, thresholds, isEditing, onChange, onThresholdChange }) => {
  const currentMetric = Array.isArray(selectedMetric) ? selectedMetric[0] : selectedMetric;

  const getOptionIcon = (value) => {
    switch(value) {
      case 'Power':
        return <Zap size={20} />;
      case 'HR':
        return <Heart size={20} />;
      case 'Pace':
        return <Timer size={20} />;
      default:
        return null;
    }
  };

  const getOptionLabel = (value) => {
    switch(value) {
      case 'Power':
        return 'Power Based Training Only';
      case 'HR':
        return 'Heart Rate Based Training Only';
      case 'Pace':
        return 'Pace Based Training Only';
      default:
        return '';
    }
  };

  const renderThresholdInputs = (value) => {
    return (
      <Box sx={{ 
        backgroundColor: 'background.paper',
        borderRadius: 2,
        p: 2,
        mt: -2,
        border: '2px solid',
        borderColor: 'primary.main',
        borderTop: 'none',
        mb: 2,
        position: 'relative',
        zIndex: 0
      }}>
        {value === 'Power' && (
          <ThresholdInput
            sport="running"
            metric="ftp"
            label="Running Power Threshold"
            min={50}
            max={500}
            value={thresholds.ftp.threshold}
            onChange={(e) => onThresholdChange('ftp', 'threshold', e.target.value)}
            isEditing={isEditing}
            enabled={true}
            unit="watts"
          />
        )}
        {value === 'HR' && (
          <ThresholdInput
            sport="running"
            metric="lthr"
            label="Lactate Threshold Heart Rate (LTHR)"
            min={60}
            max={220}
            value={thresholds.lthr.threshold}
            onChange={(e) => onThresholdChange('lthr', 'threshold', e.target.value)}
            isEditing={isEditing}
            enabled={true}
            unit="bpm"
          />
        )}
        {value === 'Pace' && (
          <ThresholdInput
            sport="running"
            metric="thresholdPace"
            label="Threshold Pace"
            min={2}
            max={20}
            value={thresholds.thresholdPace.threshold}
            onChange={(e) => onThresholdChange('thresholdPace', 'threshold', e.target.value)}
            isEditing={isEditing}
            enabled={true}
            unit="min/km"
          />
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Typography 
        variant="subtitle1" 
        sx={{ 
          mb: 2,
          fontWeight: 500,
          color: 'text.primary'
        }}
      >
        Training Method
      </Typography>

      <RadioGroup
        value={currentMetric}
        onChange={onChange}
        sx={{ width: '100%' }}
      >
        {['Power', 'HR', 'Pace'].map((value) => (
          <Box key={value}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: 2,
                border: '2px solid',
                borderColor: value === currentMetric ? 'primary.main' : 'divider',
                backgroundColor: 'background.paper',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  borderColor: 'primary.main',
                },
                position: 'relative',
                zIndex: 1,
                mb: value === currentMetric ? 0 : 2
              }}
            >
              <FormControlLabel
                value={value}
                disabled={!isEditing}
                control={
                  <Radio 
                    sx={{
                      color: 'text.primary',
                      '&.Mui-checked': {
                        color: 'primary.main',
                      }
                    }}
                  />
                }
                label={
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1.5,
                    color: 'text.primary',
                  }}>
                    {getOptionIcon(value)}
                    <Typography sx={{ 
                      fontWeight: value === currentMetric ? 600 : 400,
                    }}>
                      {getOptionLabel(value)}
                    </Typography>
                  </Box>
                }
                sx={{
                  mx: 0,
                  px: 2,
                  py: 1.5,
                  width: '100%',
                }}
              />
            </Paper>
            {value === currentMetric && renderThresholdInputs(value)}
          </Box>
        ))}
      </RadioGroup>

      {!isEditing && currentMetric && (
        <Alert 
          severity="info"
          icon={<Info size={20} />}
          sx={{ 
            mt: 2,
            borderRadius: 2,
            '& .MuiAlert-message': {
              fontSize: '0.875rem'
            }
          }}
        >
          Training zones and workouts will be tailored to your {currentMetric === 'Power' ? 'power' : currentMetric === 'HR' ? 'heart rate' : 'pace'}-based training method.
        </Alert>
      )}
    </Box>
  );
};

export default IntegratedRunningMetrics;
