import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Edit2, Save, X } from 'lucide-react';

const SettingsEditButton = ({ isEditing, onEdit, onSave, onCancel, isSaving }) => {
  if (isEditing) {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          size="small"
          sx={{ mr: 1 }}
          disabled={isSaving}
          startIcon={isSaving ? <CircularProgress size={16} color="inherit" /> : <Save size={16} />}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCancel}
          size="small"
          disabled={isSaving}
          startIcon={<X size={16} />}
        >
          Cancel
        </Button>
      </>
    );
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onEdit}
      startIcon={<Edit2 size={16} />}
      size="small"
    >
      Edit
    </Button>
  );
};

export default SettingsEditButton;
