import React, { useState, useEffect } from "react";
import useSWR, { mutate } from "swr";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";

import { IconButton, Menu, MenuItem, Typography, Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import moment from "moment";
import axios from "axios";

const NotificationsBell = ({ accessToken, onTokenRefresh }) => {
  const { data: notifications, error } = useSWR(
    [`https://api2.enduroco.in/notifications`, accessToken, onTokenRefresh],
    fetcher,
    { refreshInterval: 30000 }
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [lastNotification, setLastNotification] = useState(null);

  useEffect(() => {
    const latestNotification = notifications && notifications[0]; // Assuming the latest notification comes first
    if (
      latestNotification &&
      lastNotification &&
      latestNotification.timestamp !== lastNotification.timestamp
    ) {
      setOpenSnackbar(true); // Open the Snackbar
      setLastNotification(latestNotification); // Update the last notification
    } else {
      if (!lastNotification) setLastNotification(latestNotification); // Update the last notification
    }
  }, [notifications]); // Depend on notifications to re-run this effect

  // Function to handle close event of Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false); // Close the Snackbar
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);

    // Function to mark notifications as read
    await markNotificationsRead(notifications, accessToken);

    // Re-fetch notifications after marking as read
    mutate([
      `https://api2.enduroco.in/notifications`,
      accessToken,
      onTokenRefresh,
    ]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (error) return null;
  if (!notifications) return null;

  const uniqueNotifications = removeDuplicates(notifications);
  if (!accessToken) {
    return <span>Please log in to view notifications.</span>;
  }
  const unreadCount = getUnreadCount(uniqueNotifications || []);

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={20000} // Increase the duration to 20 seconds
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          sx={{
            width: "100%",
            backgroundColor: "#d32f2f", // Change the background color to something more prominent
            color: "white", // Keep text color white for contrast
            fontSize: "1.1rem", // Increase font size for better readability
          }}
        >
          {lastNotification
            ? `${lastNotification.title}: ${lastNotification.message}`
            : "You have new notifications!"}
        </Alert>
      </Snackbar>

      <Badge
        badgeContent={unreadCount}
        color="error"
        overlap="circular" // Use 'circular' for circular icons like IconButton
        anchorOrigin={{
          vertical: "top", // Adjust vertical position
          horizontal: "right", // Adjust horizontal position
        }}
        sx={{
          // Adjust positioning using sx prop
          ".MuiBadge-badge": {
            top: 10, // Adjust vertical position
            right: 10, // Adjust horizontal position
          },
        }}
      >
        <IconButton color="inherit" onClick={handleClick}>
          <NotificationsIcon style={{ color: "white" }} />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{ style: menuStyles }}
        >
          {uniqueNotifications.length === 0 ? (
            <MenuItem>
              <Typography>No new notifications</Typography>
            </MenuItem>
          ) : (
            uniqueNotifications.map((notification, index) => (
              <MenuItem
                key={index}
                style={notification.read ? {} : unreadNotificationStyle}
              >
                <div>
                  <Typography variant="subtitle1" color="primary">
                    {notification.title}
                  </Typography>
                  <Typography variant="body2">
                    {notification.message}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {moment(notification.timestamp).fromNow()}
                  </Typography>
                </div>
              </MenuItem>
            ))
          )}
        </Menu>
      </Badge>
    </>
  );
};

export default NotificationsBell;

// Utilities
export const fetcher = async ([url, token, onTokenRefresh]) => {
  if (!token) {
    throw new Error("Access token is missing or invalid.");
  }

  try {
    return await axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => res.data);
  } catch (error) {
    if (error.response && error.response.status === 401 && onTokenRefresh) {
      const newToken = await onTokenRefresh();
      if (newToken) {
        return axios
          .get(url, { headers: { Authorization: `Bearer ${newToken}` } })
          .then((res) => res.data);
      }
    }
    throw error;
  }
};

export const removeDuplicates = (notifications) => {
  const uniqueNotifications = new Map();
  notifications.forEach((notification) => {
    const key = notification.title + notification.message;
    if (
      !uniqueNotifications.has(key) ||
      uniqueNotifications.get(key).timestamp < notification.timestamp
    ) {
      uniqueNotifications.set(key, notification);
    }
  });
  return Array.from(uniqueNotifications.values());
};

const markNotificationsRead = async (notifications, accessToken) => {
  // Extract the IDs or other identifiers of unread notifications
  const unreadNotifications = notifications.filter(
    (notification) => !notification.read
  );
  const notificationIds = unreadNotifications.map(
    (notification) => notification.id
  ); // Assuming each notification has an 'id'

  try {
    await axios.post(
      "https://api2.enduroco.in/notifications/markread",
      {
        notificationIds,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  } catch (error) {
    console.error("Error marking notifications as read:", error);
  }
};

const getUnreadCount = (notifications) => {
  return notifications.filter((notification) => !notification.read).length;
};

// Styles
export const menuStyles = {
  maxHeight: "50vh",
  maxWidth: "100%",
  overflow: "auto",
};

export const unreadNotificationStyle = {
  backgroundColor: "#e0f2f1",
};