import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Calendar, Clock } from 'lucide-react';

const TrainingApproachSelection = ({ onSelect }) => {
    return (
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', p: 2 }}>
            <Button
                onClick={() => onSelect('event')}
                variant="outlined"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    p: 2,
                    minWidth: 160,
                    height: 'auto'
                }}
            >
                <Calendar size={24} />
                <Typography>Event Based</Typography>
                <Typography variant="caption" color="text.secondary">
                    Train for a specific race or competition
                </Typography>
            </Button>
            <Button
                onClick={() => onSelect('custom')}
                variant="outlined"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    p: 2,
                    minWidth: 160,
                    height: 'auto'
                }}
            >
                <Clock size={24} />
                <Typography>Custom Schedule</Typography>
                <Typography variant="caption" color="text.secondary">
                    Flexible training for general fitness
                </Typography>
            </Button>
        </Box>
    );
};

export default TrainingApproachSelection;
