import React from 'react';
import { Container, Typography, Card, CardContent } from '@mui/material';

const Terms = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Terms and Conditions
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to EnduroCo. By accessing and using our services, you agree to comply with and be bound by the following terms and conditions.
          </Typography>
          
          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            1. Service Usage
          </Typography>
          <Typography variant="body1" paragraph>
            Our services are provided "as is" and are intended for personal training and fitness tracking purposes. You agree to use the services responsibly and in accordance with these terms.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. User Accounts
          </Typography>
          <Typography variant="body1" paragraph>
            You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Data Usage
          </Typography>
          <Typography variant="body1" paragraph>
            We collect and process your data in accordance with our Privacy Policy. By using our services, you consent to such processing.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Service Modifications
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify or discontinue our services at any time without notice.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. User Conduct
          </Typography>
          <Typography variant="body1" paragraph>
            You agree not to misuse our services or help anyone else do so.
          </Typography>

          <Typography variant="body1" sx={{ mt: 4 }}>
            These terms and conditions are subject to change without notice. Continued use of our services constitutes acceptance of any modifications to these terms.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Terms;
