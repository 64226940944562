import React, { useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../../config";
var qs = require("qs");

export const ThirdpartyauthStrava = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const location = useLocation();
  const [source, setsource] = useState();
  const [status, setstatus] = useState(false);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const fetchTokenAndProceed = async () => {
      const idToken = await getIdTokenClaims();
      const accessToken = idToken.__raw;

      if (
        params.state === "" &&
        params.scope === "read,activity:write,activity:read,activity:read_all"
      ) {
        setsource("Strava");
        updateUserCodeToBackend(params.code, "stravaauth", accessToken);
      } else if (!params.state) {
        if (params.code.length < 40) {
          setsource("Intervals.icu");
          updateUserCodeToBackend(params.code, "intervalsauth", accessToken);
        } else {
          setsource("TrainingPeaks");
          updateUserCodeToBackend(
            params.code,
            "trainingpeaksauth",
            accessToken
          );
        }
      }
    };

    fetchTokenAndProceed();
  }, [user, location.search, getIdTokenClaims]);

  function updateUserCodeToBackend(code, type, accessToken) {
    const requestBody = {
      type: type.replace("auth", ""),
      email: user.email,
      [type]: code,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    const config = getConfig();
    fetch(config.api2Endpoint + "/thirdpartyconnection/add", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setstatus(true);
      })
      .catch((error) => console.log("error", error));
  }

  return status ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <div className="connectionStatus fadeIn">Connecting to {source}...</div>
  );
};

export default withAuthenticationRequired(ThirdpartyauthStrava, {
  onRedirecting: () => <Loading />,
});
