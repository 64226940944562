import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { ButtonGroup } from '@mui/material';

const plans = {
  INR: [
    {
      title: "Starter",
      monthly: 0,
      yearly: 0,
      description: [
        "10 workouts per month",
        "Full workout calendar",
        "Power, HR and Pace based workouts",
        "Email support",
      ],
      buttonText: "Sign up for free",
      buttonVariant: "outlined",
      link: "/signup",
    },
    {
      title: "Full Access",
      subheader: "Most popular",
      monthly: 999,
      yearly: 9999,
      description: [
        "Cycling, Duathlon, Triathlon",
        "Get attached to a coach",
        "All other features from previous plan",
        "Phone support",
      ],
      buttonText: "Get Started",
      buttonVariant: "contained",
      link: "/signup",
    },
  ],
  USD: [
    {
      title: "Starter",
      monthly: 0,
      yearly: 0,
      description: [
        "10 workouts per month",
        "Full workout calendar",
        "Power, HR and Pace based workouts",
        "Email support",
      ],
      buttonText: "Sign up for free",
      buttonVariant: "outlined",
      link: "/signup",
    },
    {
      title: "Full Access",
      subheader: "Most popular",
      monthly: 12,
      yearly: 120,
      description: [
        "Cycling, Duathlon, Triathlon",
        "Get attached to a coach",
        "All other features from previous plan",
        "Phone support",
      ],
      buttonText: "Get Started",
      buttonVariant: "contained",
      link: "/signup",
    },
  ],
};

function PricingContent() {
  const [currency, setCurrency] = useState('USD');
  const [cycle, setCycle] = useState('monthly');

  return (
    <div className="price_section" id="pricing">
      <div className="container">
        <div className="row">
          <React.Fragment>
            <GlobalStyles
              styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
            />
            <CssBaseline />
            <Container
              disableGutters
              maxWidth="sm"
              component="main"
              sx={{ pt: 8, pb: 6 }}
            >
              <Typography
                component="h3"
                variant="h3"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Pricing
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4, gap: 2 }}>
                <ButtonGroup variant="contained" sx={{ mb: 2 }}>
                  <Button
                    onClick={() => setCurrency('USD')}
                    variant={currency === 'USD' ? 'contained' : 'outlined'}
                  >
                    USD
                  </Button>
                  <Button
                    onClick={() => setCurrency('INR')}
                    variant={currency === 'INR' ? 'contained' : 'outlined'}
                  >
                    INR
                  </Button>
                </ButtonGroup>

                <ButtonGroup variant="contained" sx={{ mb: 2 }}>
                  <Button
                    onClick={() => setCycle('monthly')}
                    variant={cycle === 'monthly' ? 'contained' : 'outlined'}
                  >
                    Monthly
                  </Button>
                  <Button
                    onClick={() => setCycle('yearly')}
                    variant={cycle === 'yearly' ? 'contained' : 'outlined'}
                  >
                    Yearly
                  </Button>
                </ButtonGroup>
              </Box>
            </Container>
            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
                {plans[currency].map((tier) => (
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    sm={tier.title === "Enterprise" ? 12 : 6}
                    md={4}
                  >
                    <Card>
                      <CardHeader
                        title={tier.title}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: "center" }}
                        action={tier.title === "Pro" ? <StarIcon /> : null}
                        subheaderTypographyProps={{
                          align: "center",
                        }}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                              ? theme.palette.grey[200]
                              : theme.palette.grey[700],
                        }}
                      />
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "baseline",
                            mb: 2,
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="h3"
                            color="text.primary"
                          >
                            {currency === 'USD' ? '$' : '₹'}
                            {cycle === 'monthly' ? tier.monthly : tier.yearly}
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                            /{cycle}
                          </Typography>
                        </Box>
                        <ul>
                          {tier.description.map((line) => (
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="center"
                              key={line}
                            >
                              {line}
                            </Typography>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions>
                        <Button
                          fullWidth
                          variant={tier.buttonVariant}
                          href={tier.link}
                        >
                          {tier.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}

export default function Pricing() {
  return <PricingContent />;
}