import { useState, useEffect } from 'react';
import axios from 'axios';
import { DEFAULT_THRESHOLDS, RUNNING_METRICS } from '../constants/defaultValues';

const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

const filterEnabledProperties = (sportData) => {
  const filtered = {
    enabled: sportData.enabled,
    metrics: sportData.metrics
  };

  // Only include threshold values that are enabled
  if (sportData.ftp?.enabled) {
    filtered.ftp = {
      enabled: true,
      threshold: sportData.ftp.threshold
    };
  }

  if (sportData.lthr?.enabled) {
    filtered.lthr = {
      enabled: true,
      threshold: sportData.lthr.threshold
    };
  }

  if (sportData.thresholdPace?.enabled) {
    filtered.thresholdPace = {
      enabled: true,
      threshold: sportData.thresholdPace.threshold
    };
  }

  return filtered;
};

export const useIntensityLogic = (workoutIntensity, accessToken, userEmail, onRefresh) => {
  const [localWorkoutIntensity, setLocalWorkoutIntensity] = useState(workoutIntensity || DEFAULT_THRESHOLDS);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [originalState, setOriginalState] = useState(null);

  useEffect(() => {
    if (workoutIntensity) {
      const updatedIntensity = {
        cycling: {
          ...DEFAULT_THRESHOLDS.cycling,
          ...workoutIntensity.cycling,
          ftp: { ...DEFAULT_THRESHOLDS.cycling.ftp, ...workoutIntensity.cycling?.ftp },
          lthr: { ...DEFAULT_THRESHOLDS.cycling.lthr, ...workoutIntensity.cycling?.lthr }
        },
        running: {
          ...DEFAULT_THRESHOLDS.running,
          ...workoutIntensity.running,
          metrics: Array.isArray(workoutIntensity.running?.metrics)
            ? workoutIntensity.running.metrics
            : [workoutIntensity.running?.metrics || 'HR'],
          ftp: { ...DEFAULT_THRESHOLDS.running.ftp, ...workoutIntensity.running?.ftp },
          lthr: { ...DEFAULT_THRESHOLDS.running.lthr, ...workoutIntensity.running?.lthr },
          thresholdPace: { ...DEFAULT_THRESHOLDS.running.thresholdPace, ...workoutIntensity.running?.thresholdPace }
        }
      };
      setLocalWorkoutIntensity(updatedIntensity);
      setOriginalState(updatedIntensity);
    }
  }, [workoutIntensity]);

  const validateThresholds = (sportData, sport) => {
    if (sport === 'running') {
      // Validate running metrics is an array with exactly one valid value
      if (!Array.isArray(sportData.metrics) ||
        sportData.metrics.length !== 1 ||
        !RUNNING_METRICS.includes(sportData.metrics[0])) {
        setSnackbar({
          open: true,
          message: `Invalid running metrics. Must be one of: ${RUNNING_METRICS.join(', ')}`,
          severity: 'error'
        });
        return false;
      }

      // Only validate enabled thresholds
      const metric = sportData.metrics[0];
      const isValid = value => !isNaN(value) && Number(value) >= 0;

      if (metric === 'Power' && sportData.ftp?.enabled && !isValid(sportData.ftp.threshold)) return false;
      if (metric === 'HR' && sportData.lthr?.enabled && !isValid(sportData.lthr.threshold)) return false;
      if (metric === 'Pace' && sportData.thresholdPace?.enabled && !isValid(sportData.thresholdPace.threshold)) return false;
    } else if (sport === 'cycling') {
      const isValid = value => !isNaN(value) && Number(value) >= 0;

      if (sportData.metrics.includes('Power') && sportData.ftp?.enabled && !isValid(sportData.ftp.threshold)) return false;
      if (sportData.metrics.includes('HR') && sportData.lthr?.enabled && !isValid(sportData.lthr.threshold)) return false;
    }

    return true;
  };

  const getModifiedSportsData = () => {
    const modifiedData = {};

    // Check if cycling data was modified
    if (!isEqual(localWorkoutIntensity.cycling, originalState.cycling)) {
      if (validateThresholds(localWorkoutIntensity.cycling, 'cycling')) {
        modifiedData.cycling = filterEnabledProperties(localWorkoutIntensity.cycling);
      } else {
        return null;
      }
    }

    // Check if running data was modified
    if (!isEqual(localWorkoutIntensity.running, originalState.running)) {
      if (validateThresholds(localWorkoutIntensity.running, 'running')) {
        modifiedData.running = filterEnabledProperties(localWorkoutIntensity.running);
      } else {
        return null;
      }
    }

    return modifiedData;
  };

  const handleSave = async () => {
    try {
      const modifiedData = getModifiedSportsData();

      if (!modifiedData) {
        return; // Validation failed
      }

      if (Object.keys(modifiedData).length === 0) {
        setIsEditing(false);
        return; // No changes to save
      }

      setIsSaving(true);

      const response = await axios.patch(
        'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
        { workoutIntensity: modifiedData },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: { email: userEmail }
        }
      );

      if (response.status === 200) {
        setIsEditing(false);
        setOriginalState(localWorkoutIntensity);
        setSnackbar({
          open: true,
          message: 'Workout intensity updated successfully',
          severity: 'success'
        });
        onRefresh?.();
      }
    } catch (error) {
      console.error('Error updating workout intensity:', error);
      const errorMessage = error.response?.data?.error || 'Failed to update workout intensity';
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  return {
    localWorkoutIntensity,
    setLocalWorkoutIntensity,
    isEditing,
    setIsEditing,
    isSaving,
    snackbar,
    setSnackbar,
    handleSave
  };
};

export default useIntensityLogic;