import React from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";

const CustomNavLink = ({ to, children }) => {
  // Define base styles
  const baseStyle = {
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    paddingBottom: "5px",
  };

  // Define active link styles
  const activeStyle = {
    color: "rgb(252, 76, 2)", // Orange color for active link
  };

  const inactiveStyle = {
    color: "grey", // White color for inactive links
  };

  return (
    <NavLink
      to={to}
      style={({ isActive }) => ({
        ...baseStyle,
        ...(isActive ? activeStyle : inactiveStyle),
      })}
    >
      <Typography sx={{ ...baseStyle, ":hover": { color: "rgb(252, 76, 2)" } }}>
        {children}
      </Typography>
    </NavLink>
  );
};

export default CustomNavLink;
