import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import WeekStartSelector from './components/WeekStartSelector';
import RampRateSelector from './components/RampRateSelector';
import DisciplineSelector from './sectionCustomWorkout/DisciplineSelector';
import { getRampRateInfo } from './utils/workoutConstants';
import { TrendingUp, Calendar } from 'lucide-react';

const CommonWorkoutSettings = ({
  discipline,
  onDisciplineChange,
  weekstart,
  onWeekStartChange,
  rampRate,
  onRampRateChange,
  isEditing,
  errors
}) => {
  console.log({ weekstart })
  const getDisciplineIcon = (disciplineType) => {
    switch (disciplineType?.toLowerCase()) {
      case 'running':
        return '🏃';
      case 'cycling':
        return '🚴';
      case 'triathlon':
        return '🏊';
      case 'duathlon':
        return '🏃🚴';
      default:
        return '🎯';
    }
  };

  const getDisciplineColor = (disciplineType) => {
    switch (disciplineType?.toLowerCase()) {
      case 'running':
        return '#2196f3';
      case 'cycling':
        return '#4caf50';
      case 'triathlon':
        return '#ff9800';
      case 'duathlon':
        return '#9c27b0';
      default:
        return '#757575';
    }
  };

  const getDisciplineDescription = (disciplineType) => {
    switch (disciplineType?.toLowerCase()) {
      case 'running':
        return 'Road & Trail Running';
      case 'cycling':
        return 'Road & Indoor Cycling';
      case 'triathlon':
        return 'Swim, Bike & Run';
      case 'duathlon':
        return 'Run, Bike & Run';
      default:
        return 'Not Set';
    }
  };

  const getCurrentWeekStart = () => {
    const weekstartStr = weekstart?.toString();
    switch (weekstartStr) {
      case '0': return 'Sunday';
      case '6': return 'Saturday';
      case '1': return 'Monday';
      case '2': return 'Tuesday';
      default: return weekstartStr ? `Not Set` : 'Not Set';
    }
  };

  if (!isEditing) {
    const rampRateInfo = getRampRateInfo(rampRate);

    return (
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{
              bgcolor: 'background.paper',
              p: 2,
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider',
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}>
              <Box sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                bgcolor: `${getDisciplineColor(discipline)}15`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5rem'
              }}>
                {getDisciplineIcon(discipline)}
              </Box>
              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Selected Discipline
                </Typography>
                <Typography variant="body1" sx={{
                  color: getDisciplineColor(discipline),
                  fontWeight: 500
                }}>
                  {discipline || 'Not Set'}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {getDisciplineDescription(discipline)}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{
              bgcolor: 'background.paper',
              p: 2,
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Calendar size={20} style={{ color: '#1976d2' }} />
                <Typography variant="subtitle2" color="text.secondary">
                  Training Week Starts On
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {getCurrentWeekStart()}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{
              bgcolor: `${rampRateInfo.color}10`,
              p: 2,
              borderRadius: 1,
              border: '1px solid',
              borderColor: rampRateInfo.color
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <TrendingUp size={20} style={{ color: rampRateInfo.color }} />
                <Typography variant="subtitle2" sx={{ color: rampRateInfo.color }}>
                  {rampRateInfo.label}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {rampRateInfo.description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Basic Settings
          </Typography>
          <DisciplineSelector
            selectedDiscipline={discipline}
            onChange={onDisciplineChange}
            disabled={!isEditing}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Week Start Day
          </Typography>
          <WeekStartSelector
            value={weekstart}
            onChange={onWeekStartChange}
            disabled={!isEditing}
          />
          {errors?.weekstart && (
            <Typography color="error" variant="caption" sx={{ mt: 0.5, display: 'block' }}>
              {errors.weekstart}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Workout Progression Rate
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Choose how quickly your workout intensity and duration will progress
          </Typography>
          <RampRateSelector
            value={rampRate}
            onChange={onRampRateChange}
            disabled={!isEditing}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommonWorkoutSettings;
