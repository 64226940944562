import React, { Component } from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Link from "@mui/material/Link";
import Moment from "react-moment";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import history from "../utils/history";
import { getConfig } from "../config";

function AudaxResults(props) {
  // const [search, setsearch] = useState();

  const location = useLocation();
  var searchString = location.search.substring(1);
  const urlParams = new URLSearchParams(searchString);
  const params = Object.fromEntries(urlParams);
  const [distance, setDistance] = useState();
  const [results, setResults] = useState([]);
  useEffect(() => {
    setResults([]);
    console.log(params.distance, distance);

    if (
      params.distance &&
      params.distance != undefined &&
      distance == undefined
    ) {
      setDistance(params.distance);
    } else if (
      params.distance &&
      params.distance != undefined &&
      distance != undefined
    ) {
    } else if (!params.distance && distance == undefined) {
      setDistance(200);
    }
    history.push(window.location.pathname + "?distance=" + distance);

    getEvents();
    document.title = "EnduroCo - Audax " + distance + "km Results";
    // setsearch(params);
    //all above is work in progress
  }, [distance]);
  const columns = [
    //add serial number hide serial number if result is same as previous row
    {
      name: "serial",
      label: "#",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var serial = tableMeta.rowIndex + 1;
          if (
            tableMeta.rowIndex > 0 &&
            results[tableMeta.rowIndex].result ==
              results[tableMeta.rowIndex - 1].result
          ) {
            return "";
          }
          return serial;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },

    {
      name: "date",
      label: "Date",
    },
    {
      name: "result",
      label: "Time",
    },
    {
      //event link
      name: "eventId",
      label: "Event",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link
              href={"https://audaxindia.in/event-e-" + value}
              target="_blank"
            >
              Link
            </Link>
          );
        },
      },
    },
  ];
  const options = {
    //rows per page 20
    // rowsPerPage: 20,
    //hide rows per page option
    rowsPerPageOptions: [],
    search: false,
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    pagination: false,
    selectableRows: "none",
    responsive: "standard",
    //blank message
    textLabels: {
      body: {
        noMatch: "Please wait while we load the results",
      },
    },

    // responsive: "scrollMaxHeight",
    // onFilterChange: (changedColumn, filterList) => {
    //     filterUpdate(changedColumn, filterList);
    // },
  };
  const getEvents = async () => {
    var distanceL = distance;
    const config = getConfig();
    const response = await fetch(
      `${config.apiEndpoint}/publicapi/audaxtopresults?distance=${distanceL}`
    );
    const body = await response.json();
    if (response.status !== 200) {
      throw Error(body.message);
    }
    setResults(body);
  };
  return results ? (
    <div className="container">
      <div className="container">
        <div className="section-title text-center">
          <div className="col-lg-12">
            <Typography variant="h4" component="h4" gutterBottom>
              {/*audax results {distance}k header*/}
              Audax Fastest Finishes {distance}k
            </Typography>
          </div>
        </div>
      </div>
      <div>
        {/* section align center and contained */}
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center">
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDistance(100)}
                    >
                      100k
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDistance(150)}
                    >
                      150k
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDistance(200)}
                    >
                      200k
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDistance(300)}
                    >
                      300k
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDistance(400)}
                    >
                      400k
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDistance(600)}
                    >
                      600k
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDistance(1000)}
                    >
                      1000k
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDistance(1200)}
                    >
                      1200k
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setDistance(1400)}
                    >
                      1400k
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <MUIDataTable data={results} columns={columns} options={options} />
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default AudaxResults;
