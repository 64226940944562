import React from "react";
import { Box, Typography, Alert, Paper, useTheme, useMediaQuery } from "@mui/material";
import { Download, Upload } from "lucide-react";
import ServiceCard from "./components/ServiceCard";

const ConnectionsSection = ({ connections, onRefresh, subscription }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSubscriptionActive = subscription?.currentPlan?.status?.toLowerCase() === 'active';

  const services = [
    {
      section: "Import Activities",
      icon: <Download size={24} />,
      requiresPremium: false,
      services: [
        {
          id: 'strava',
          name: 'Strava',
          description: 'Import your activities',
          icon: '/workswith/strava.png.webp',
          authUrl: 'https://www.strava.com/oauth/authorize?client_id=41820&response_type=code&redirect_uri=http://www.enduroco.in%2Fthirdpartyauth2&approval_prompt=force&scope=activity:read,activity:read_all,activity:write',
          isConnected: connections?.strava?.auth || false
        }
      ]
    },
    {
      section: "Export Workouts",
      icon: <Upload size={24} />,
      requiresPremium: true,
      services: [
        {
          id: 'garmin',
          name: 'Garmin',
          description: 'Sync with Garmin Connect',
          icon: '/workswith/garmin.png.webp',
          isConnected: connections?.garmin?.auth || false,
          useCustomAuth: true
        },
        {
          id: 'trainingpeaks',
          name: 'Training Peaks',
          description: 'Professional training platform',
          icon: '/workswith/trainingpeaks_logo_vert_2_color.png',
          authUrl: 'https://oauth.trainingpeaks.com/OAuth/Authorize?response_type=code&client_id=enduro-co&scope=workouts%3Aplan%20workouts%3Aread%20athlete%3Aprofile&redirect_uri=https%3A%2F%2Fenduroco.in%2Fthirdpartyauth2',
          isConnected: connections?.trainingpeaks?.auth || false
        },
        {
          id: 'intervals',
          name: 'Intervals.icu',
          description: 'Advanced training analytics',
          icon: '/workswith/intervals.png',
          authUrl: 'https://intervals.icu/oauth/authorize?client_id=9&redirect_uri=https%3A%2F%2Fwww.enduroco.in%2Fthirdpartyauth&scope=ACTIVITY%3AREAD,WELLNESS%3AWRITE,CALENDAR%3AWRITE,LIBRARY%3AWRITE,SETTINGS%3AWRITE',
          isConnected: connections?.intervals?.auth || false
        },
        {
          id: 'wahoo',
          name: 'Wahoo',
          description: 'Smart trainer workouts',
          icon: '/workswith/wahoo.png',
          authUrl: 'https://login.wahoofitness.com/saml/auth?SAMLRequest=fZFdS8MwFIb%2FSu9y1c%2BVfYS2UDaEwRTZ1AtvxmmabsE0qTmp039v2iJORG%2BT93nOyzkZQis7Wvb2rPb8tedovRKRGyu0WmuFfcvNgZs3wfjjfpeTs7Ud0jCETgQXOGstxQlUwHQbIkd0FIaD88hAygrYC%2FE2TioUDMZvXuqTUJOhEVY5dHI4NATXhnjbTU6OvJol88V84a%2BqKPXTulr5yyVL%2FBTihic1q%2BOkcVHEnm8VWlA2J0mUpH4c%2BfH8IVrRKKaz5Jl4T9zg2CAJIuK9t1IhHablpDeKakCBVEHLkVpGD%2BXtjrogha9dXCPd%2F0xntNVMS1JkQ5qO7Uzxe2NZeP2fTae4c77t5t6l2IdXSqkva8PB8pxY03Pi3WjTgv27QRzE44uo%2FWaMUt6CkGVdG7dkEhbT1J83Lz4B&RelayState=https%3A%2F%2Fapi.wahooligan.com%2Foauth%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3DSwPrHw3Nd_dHWHMyYnH5-LzrPL2EqySedG4PGpZfC6A%26redirect_uri%3Dhttps%253A%252F%252Fwww.enduroco.in%252Fthirdpartyauthwahoo%26scope%3Duser_read%2Buser_write%2Bemail%2Bworkouts_read%2Bworkouts_write%2Bplans_read%2Bplans_write%2Bpower_zones_read%2Bpower_zones_write%2Boffline_data',
          isConnected: connections?.wahoo?.auth || false
        }
      ]
    }
  ];

  return (
    <Paper
      elevation={0}
      sx={{
        p: { xs: 1, sm: 2, md: 3 },
        bgcolor: 'background.paper',
        borderRadius: 2,
        width: '100%'
      }}
    >
      {services.map((section) => (
        <Box
          key={section.section}
          sx={{
            mb: { xs: 3, sm: 4 },
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 2,
              px: { xs: 1, sm: 0 }
            }}
          >
            {section.icon}
            <Typography variant="h6">{section.section}</Typography>
          </Box>

          {!isSubscriptionActive && section.requiresPremium && (
            <Alert
              severity="warning"
              sx={{
                mb: 2,
                mx: { xs: 0, sm: 0 },
                '& .MuiAlert-message': {
                  width: '100%'
                }
              }}
            >
              <Typography variant="body2">
                While you can connect these services, exporting workouts requires a Premium subscription.
                Upgrade to Premium to enable automatic workout synchronization.
              </Typography>
            </Alert>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%'
            }}
          >
            {section.services.map((service) => (
              <ServiceCard
                key={service.id}
                service={service}
                onRefresh={onRefresh}
                disabled={false}
              /> ))}
          </Box>
        </Box>
      ))}
    </Paper>
  );
};

export default ConnectionsSection;
