import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import WorkoutChart from "./workoutChart/workoutChart";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  overflowX: "hidden",
  overflowY: "scroll",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  padding: "5px",
};

const zoneTimes = [
  {
    id: "Z1",
    zone: 1,
    name: "Z1",
    color: "#009e80",
    max: 55,
    minWatts: 1,
    maxWatts: 136,
    percentRange: "0% - 55%",
    secs: 2220,
  },
  {
    id: "Z2",
    zone: 2,
    name: "Z2",
    color: "#009e00",
    max: 75,
    minWatts: 137,
    maxWatts: 186,
    percentRange: "56% - 75%",
    secs: 1800,
  },
  {
    id: "Z3",
    zone: 3,
    name: "Z3",
    color: "#ffcb0e",
    max: 90,
    minWatts: 187,
    maxWatts: 223,
    percentRange: "76% - 90%",
    secs: 0,
  },
  {
    id: "Z4",
    zone: 4,
    name: "Z4",
    color: "#ff7f0e",
    max: 105,
    minWatts: 224,
    maxWatts: 260,
    percentRange: "91% - 105%",
    secs: 720,
  },
  {
    id: "Z5",
    zone: 5,
    name: "Z5",
    color: "#dd0447",
    max: 120,
    minWatts: 261,
    maxWatts: 297,
    percentRange: "106% - 120%",
    secs: 0,
  },
  {
    id: "Z6",
    zone: 6,
    name: "Z6",
    color: "#6633cc",
    max: 150,
    minWatts: 298,
    maxWatts: 372,
    percentRange: "121% - 150%",
    secs: 0,
  },
  {
    id: "Z7",
    zone: 7,
    name: "Z7",
    color: "#504861",
    max: 999,
    minWatts: 373,
    maxWatts: 9999,
    percentRange: "151% +",
    secs: 0,
  },
  {
    id: "SS",
    zone: 8,
    name: "Sweet Spot",
    color: "#ffa00e",
    gap: true,
    min: 84,
    minWatts: 208,
    max: 97,
    maxWatts: 240,
    percentRange: "84% - 97%",
    secs: 0,
  },
];

export default function WorkoutTextView({ open, setOpen, woObj, accessToken }) {
  if (woObj?.structure) {
    woObj.structure.zoneTimes = zoneTimes;
  }

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {woObj?.type ? (
            <WorkoutChart workout={woObj} accessToken={accessToken} />
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
