// Help.js

import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Divider, useTheme } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function HelpMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme(); // Access the theme

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: "Getting Started.",
      link: "https://forum.enduroco.in/c/getting-started/11",
      divider: false, // Display divider before this item
    },
    {
      label: "FAQs",
      link: "https://forum.enduroco.in/c/faq/9",
      divider: false, // Display divider before this item
    },
    {
      label: "How to do a FTP test.",
      link: "https://forum.enduroco.in/t/how-to-do-a-bike-ftp-test-and-calculate-threshold-power/209",
      divider: true, // Display divider before this item
    },
    {
      label: "What should be my fitness?",
      link: "https://forum.enduroco.in/t/suggested-fitness-targets/69",
      divider: false, // No divider before this item
    },
    {
      label: "Device integration help.",
      link: "https://forum.enduroco.in/t/device-integrations/40",
      divider: false, // No divider before this item
    },
    {
      label: "Email Us.",
      link: "mailto:rohan@enduroco.in",
      divider: true, // Display divider before this item
    },
    {
      label: "Ask Community.",
      link: "https://forum.enduroco.in/",
      divider: false, // No divider before this item
    },
  ];

  return (
    <>
      <IconButton
        aria-controls="help-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ marginRight: "10px", color: "white" }}
      >
        <HelpOutlineIcon />
      </IconButton>
      <Menu
        id="help-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => (
          <div key={index}>
            {item.divider && index !== 0 && (
              <Divider
                style={{ backgroundColor: theme.palette.primary.main }} // Use primary color
              />
            )}
            <MenuItem
              onClick={() => {
                window.open(item.link, "_blank");
                handleClose();
              }}
            >
              {item.label}
            </MenuItem>
          </div>
        ))}
      </Menu>
    </>
  );
}
