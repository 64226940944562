import moment from "moment";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { blueGrey, deepOrange, lime } from "@mui/material/colors";

export const CustomPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  boxShadow: "0 0 10px rgba(123, 45, 67, 0.3)",
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(0.2),
}));

export function displayHM(seconds) {
  var hours = Math.floor(seconds / 3600);
  if (isNaN(hours)) {
    return "";
  }
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  return hours + "h" + minutes + "m";
}

export function formatDate(date) {
  return moment(date, "YYYY-MM-DD").format("MMM D, YYYY");
}

export function transformTitle(text) {
  var retText = text;
  retText = retText.replace("Run ", "");
  retText = retText.replace("Bike ", "");
  retText = retText.replace("Running ", "");
  retText = retText.replace("Cycling ", "");
  retText = retText.replace("Ride ", "");
  return retText;
}

export function getBgColor(date, type, description) {
  const isToday = moment(date, "YYYY-MM-DD").isSame(moment(), "day");
  const isPast = moment(date, "YYYY-MM-DD").isBefore(moment(), "day");
  if (type === "Dayrow") {
    if (isToday) {
      return blueGrey[200]; // Green
    } else if (isPast) {
      return blueGrey[50]; // Slightly darker light grey
    } else {
      return blueGrey[100]; // Light grey
    }
  } else if (type === "workout") {
    if (isToday) {
      return blueGrey[300]; // Light green
    } else if (isPast) {
      return blueGrey[50]; // Slightly lighter grey
    } else {
      return blueGrey[100];
    }
  } else if (type === "activity") {
    if (isToday) {
      return lime[200];
    } else {
      return lime[50]; // Light blue
    }
  } else {
    return "#F5F5F5"; // Light grey
  }
}

export function getIconByActivityType(activityType) {
  if (activityType === "Run") {
    return (
      <DirectionsRunIcon
        style={{ color: deepOrange["A700"], fontSize: "30px" }}
      />
    );
  } else if (activityType === "Ride") {
    return (
      <DirectionsBikeIcon
        style={{ color: deepOrange["A700"], fontSize: "30px" }}
      />
    );
  } else if (activityType === "Swim") {
    return <PoolIcon style={{ color: deepOrange["A700"], fontSize: "30px" }} />;
  } else if (activityType === "Strength") {
    return (
      <FitnessCenterIcon
        style={{ color: deepOrange["A700"], fontSize: "30px" }}
      />
    );
  }
  return null;
}
