export const MetricButtons = ({
  currentMetric,
  setCurrentMetric,
  activityType,
}) => {
  return (
    <div className="metric-buttons">
      <button
        className={`metric-button ${
          currentMetric === "Power" ? "selected" : ""
        }`}
        onClick={() => setCurrentMetric("Power")}
      >
        Power
      </button>
      <button
        className={`metric-button ${currentMetric === "HR" ? "selected" : ""}`}
        onClick={() => setCurrentMetric("HR")}
      >
        HR
      </button>
      {activityType !== "Ride" && (
        <button
          className={`metric-button ${
            currentMetric === "Pace" ? "selected" : ""
          }`}
          onClick={() => setCurrentMetric("Pace")}
        >
          Pace
        </button>
      )}
    </div>
  );
};
