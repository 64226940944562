import React, { Component } from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Moment from "react-moment";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import history from "../utils/history";
import { getConfig } from "../config";

function Eventscycling(props) {
  // const [search, setsearch] = useState();
  const location = useLocation();
  var searchString = location.search.substring(1);
  const urlParams = new URLSearchParams(searchString);
  const params = Object.fromEntries(urlParams);
  console.log(params);
  useEffect(() => {
    getEvents();
    document.title = "EnduroCo - Upcoming Cycling Events / Brevets";

    // setsearch(params);
    //all above is work in progress
  }, []);
  function filterUpdate(changedColumn, filterList) {
    var paramObj = { city: filterList[0], distance: filterList[2] };
    var paramsUrl = serialize(paramObj);
    history.push(window.location.pathname + "?" + paramsUrl);
  }
  function serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  const columns = [
    {
      name: "City",
      label: "City",
      options: {
        filter: true,
        filterList: params["city"] ? params.city.split(",") : "",
        customFilterListOptions: { render: (v) => `City: ${v}` },
      },
    },
    {
      name: "startAt",
      label: "Date",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Moment format="ddd, DD MMM YY">{value}</Moment>;
        },
      },
    },
    {
      name: "distance",
      label: "KM",
      options: {
        filter: true,
        sort: false,
        filterList: params["distance"] ? params.distance.split(",") : "",
      },
    },
    {
      name: "url",
      label: "Link",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <Link href={value} target="_Blank">
              Open
            </Link>
          ) : null;
        },
      },
    },
    { name: "totalRiders", label: "Riders" },
    {
      name: "route",
      label: "Route",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <Link href={value} target="_Blank">
              View
            </Link>
          ) : null;
        },
      },
    },
  ];
  const [eventlist, seteventlist] = useState();
  const options = {
    filterType: "checkbox",
    fixedHeader: true,
    responsive: "standard",
    selectableRows: false,
    onFilterChange: filterUpdate,
    searchAlwaysOpen: true,
  };
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  function getEvents() {
    var requestOptions = {
      method: "GET",
    };
    const config = getConfig();
    fetch(
      config.apiEndpoint + "/publicapi/audaxindiaupcomingall/",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var resultJ = JSON.parse(result);
        seteventlist(resultJ);
        return;
      })

      .catch((error) => console.log("error", error));
  }
  return eventlist ? (
    <div className="App wrapper">
      <div className="event_section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <h4>Upcoming Brevets </h4>
            </div>
            {/* add a link to/audaxresults*/}
            <div className="col-lg-12">
              {/* //align center */}
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ textAlign: "center" }}
              >
                <Link
                  href="/audaxresults"
                  target="_Blank"
                  style={{ marginLeft: "10px" }}
                >
                  View Results
                </Link>
              </Typography>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="event_table">
                <div className="table-responsive">
                  <MUIDataTable
                    title={"Use filters to find the right event"}
                    data={eventlist}
                    columns={columns}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Typography>Loading...</Typography>
  );
}

export default Eventscycling;
