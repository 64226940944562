import React from 'react';
import { Box, Button } from '@mui/material';

const GenderSelection = ({ selectedGender, onSelect }) => {
    return (
        <Box sx={{
            display: 'flex',
            gap: 2,
            p: 2,
            justifyContent: 'center'
        }}>
            {['Male', 'Female'].map(gender => (
                <Button
                    key={gender}
                    variant="outlined"
                    onClick={() => onSelect(gender)}
                    sx={{
                        minWidth: '120px',
                        height: '40px',
                        borderRadius: '20px',
                        color: selectedGender === gender ? 'primary.main' : 'text.secondary',
                        borderColor: selectedGender === gender ? 'primary.main' : 'divider',
                        backgroundColor: selectedGender === gender ? 'primary.50' : 'transparent',
                        '&:hover': {
                            backgroundColor: 'primary.50',
                            borderColor: 'primary.main'
                        }
                    }}
                >
                    {gender}
                </Button>
            ))}
        </Box>
    );
};

export default GenderSelection;
