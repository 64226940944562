import { useState, useEffect, useRef } from "react";
import axios from "axios";

export const useSettingsReport = (accessToken) => {
    const [report, setReport] = useState(null);
    const reportRef = useRef(null);

    useEffect(() => {
        if (!accessToken) {
            return;  // Exit the effect early if the accessToken is not available
        }

        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api2.enduroco.in/settingsreport`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setReport(response.data);  // Set the entire response data
                reportRef.current = response.data;  // Store the data in useRef
            } catch (error) {
                console.error("Error fetching settings report:", error);
            }
        };

        fetchData();
    }, [accessToken]);  // Depend on accessToken

    const refresh = () => {
        if (accessToken) {
            axios.get(`https://api2.enduroco.in/settingsreport`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then(response => {
                setReport(response.data);
                reportRef.current = response.data;
            }).catch(error => {
                console.error("Error fetching settings report on refresh:", error);
            });
        }
    };

    const thresholds = reportRef.current ? reportRef.current[0] : null;

    return { thresholds, refresh };
};
