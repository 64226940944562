import useSWR from "swr";
import { useFetcher } from "../utils/fetcher";

export default function DataFetcher({ user, children }) {
  const { fetcher, accessToken } = useFetcher();

  const { data: dataV2, error } = useSWR(
    accessToken
      ? `https://api2.enduroco.in/getworkouts`
      : null,
    fetcher
  );

  // Function to add date and duration inside each workout
  const addDateAndDurationToWorkouts = (data) => {
    if (!data || !data.workouts) return data;

    const updatedWorkouts = data.workouts.map((day) => ({
      ...day,
      workouts: day.workouts.map((workout) => {
        // Extract duration from workout_doc and format it
        const durationValue = workout.workout_doc?.duration; // Use optional chaining in case workout_doc is undefined
        const formattedDuration =
          durationValue !== undefined
            ? `${Math.floor(durationValue / 3600)}h ${Math.floor(
              (durationValue % 3600) / 60
            )}m`
            : "";

        return {
          ...workout,
          date: day.workoutdate,
          readableDuration: formattedDuration,
          structure: workout.workout_doc,
        };
      }),
    }));

    return { ...data, workouts: updatedWorkouts };
  };

  // Updated data with dates and readable duration added to workouts
  const updatedDataV2 = dataV2 ? addDateAndDurationToWorkouts(dataV2) : dataV2;

  return children({ dataV2: updatedDataV2, error });
}
