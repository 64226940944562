import React from 'react';
import { Box, Typography, Paper, Collapse, useTheme, useMediaQuery } from '@mui/material';

const calculateZones = (threshold, type) => {
  if (type === 'power') {
    return [
      { name: 'Zone 1 - Recovery', range: `< ${Math.floor(threshold * 0.55)}`, percent: '0-55%' },
      { name: 'Zone 2 - Endurance', range: `${Math.floor(threshold * 0.55)}-${Math.floor(threshold * 0.75)}`, percent: '55-75%' },
      { name: 'Zone 3 - Tempo', range: `${Math.floor(threshold * 0.75)}-${Math.floor(threshold * 0.90)}`, percent: '75-90%' },
      { name: 'Zone 4 - Threshold', range: `${Math.floor(threshold * 0.90)}-${Math.floor(threshold * 1.05)}`, percent: '90-105%' },
      { name: 'Zone 5 - VO2 Max', range: `${Math.floor(threshold * 1.05)}-${Math.floor(threshold * 1.20)}`, percent: '105-120%' },
      { name: 'Zone 6 - Anaerobic', range: `> ${Math.floor(threshold * 1.20)}`, percent: '>120%' }
    ];
  } else if (type === 'hr') {
    return [
      { name: 'Zone 1 - Recovery', range: `< ${Math.floor(threshold * 0.82)}`, percent: '0-82%' },
      { name: 'Zone 2 - Endurance', range: `${Math.floor(threshold * 0.82)}-${Math.floor(threshold * 0.89)}`, percent: '82-89%' },
      { name: 'Zone 3 - Tempo', range: `${Math.floor(threshold * 0.89)}-${Math.floor(threshold * 0.94)}`, percent: '89-94%' },
      { name: 'Zone 4 - Threshold', range: `${Math.floor(threshold * 0.94)}-${Math.floor(threshold * 1.00)}`, percent: '94-100%' },
      { name: 'Zone 5 - VO2 Max', range: `${Math.floor(threshold * 1.00)}-${Math.floor(threshold * 1.03)}`, percent: '100-103%' },
      { name: 'Zone 6 - Anaerobic', range: `> ${Math.floor(threshold * 1.03)}`, percent: '>103%' }
    ];
  }
  return [];
};

const ZoneBreakdown = ({ threshold, type, unit, expanded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isVerySmall = useMediaQuery('(max-width:340px)');
  const zones = calculateZones(Number(threshold), type);

  return (
    <Collapse in={expanded}>
      <Paper 
        elevation={0}
        sx={{ 
          mt: 1,
          p: isVerySmall ? 1 : 2,
          borderRadius: 2,
          backgroundColor: 'background.default',
          border: '1px solid',
          borderColor: 'divider',
          width: '100%',
          minWidth: 0 // Enable flex shrink
        }}
      >
        <Typography 
          variant="subtitle2" 
          color="text.secondary" 
          sx={{ 
            mb: 2,
            fontSize: isVerySmall ? '0.75rem' : '0.875rem'
          }}
        >
          Training Zones Breakdown
        </Typography>
        <Box sx={{ 
          width: '100%', 
          overflow: 'hidden'
        }}>
          {zones.map((zone, index) => (
            <Box 
              key={index}
              sx={{ 
                display: 'flex',
                width: '100%',
                mb: 1,
                gap: isVerySmall ? 0.5 : 1,
                '&:last-child': { mb: 0 }
              }}
            >
              {/* Zone Name - Flexible width */}
              <Box sx={{ 
                flex: '1 1 auto',
                minWidth: 0 // Enable text truncation
              }}>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontWeight: 500,
                    color: 'text.primary',
                    fontSize: isVerySmall ? '0.7rem' : isMobile ? '0.75rem' : '0.875rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {zone.name}
                </Typography>
              </Box>

              {/* Range - Fixed width, but shrinkable */}
              <Box sx={{ 
                flex: '0 1 auto',
                minWidth: isVerySmall ? '70px' : '85px',
                textAlign: 'right'
              }}>
                <Typography 
                  variant="body2"
                  sx={{ 
                    color: 'primary.main',
                    fontWeight: 600,
                    fontSize: isVerySmall ? '0.7rem' : isMobile ? '0.75rem' : '0.875rem',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {zone.range} {unit}
                </Typography>
              </Box>

              {/* Percentage - Minimal fixed width */}
              <Box sx={{ 
                flex: '0 0 auto',
                minWidth: isVerySmall ? '40px' : '50px',
                textAlign: 'right'
              }}>
                <Typography 
                  variant="body2"
                  color="text.secondary"
                  sx={{ 
                    fontSize: isVerySmall ? '0.7rem' : isMobile ? '0.75rem' : '0.875rem',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {zone.percent}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>
    </Collapse>
  );
};

export default ZoneBreakdown;
