import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, useTheme } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const AuthButtons = () => {
  const { loginWithRedirect } = useAuth0();
  const theme = useTheme();

  const buttonStyle = (
    bgColor,
    hoverBgColor,
    startColor,
    endColor,
    isHighlighted = false
  ) => ({
    backgroundImage: `linear-gradient(45deg, ${startColor}, ${endColor})`,
    color: theme.palette.getContrastText(bgColor),
    borderRadius: "20px",
    boxShadow: isHighlighted
      ? "0 6px 8px rgba(0, 0, 0, .3)"
      : "0 4px 6px rgba(0, 0, 0, .2)",
    margin: theme.spacing(1),
    textTransform: "none",
    fontWeight: "bold",
    transform: isHighlighted ? "scale(1.1)" : "none",
    "&:hover": {
      backgroundColor: hoverBgColor,
      boxShadow: "0 8px 10px rgba(0, 0, 0, .4)",
      transform: "scale(1.05)",
    },
    padding: theme.spacing(1.5),
    fontSize: "1rem", // Increase font size for bigger buttons
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(2),
      }}
    >
      <Button
        onClick={() => loginWithRedirect({ screen_hint: "signup" })}
        sx={buttonStyle("#FF5722", "#E64A19", "#FF9800", "#FF5722", true)} // Strava Orange gradient, highlighted
      >
        Create Free Account
      </Button>
      <Button
        onClick={() => loginWithRedirect()}
        sx={buttonStyle("#43a047", "#388e3c", "#4caf50", "#43a047")} // Green gradient
      >
        Sign In
      </Button>
    </div>
  );
};

export default AuthButtons;
