import React, { useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../../config";
var qs = require("qs");

export const ThirdpartyauthGarmin = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const location = useLocation();
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const fetchTokenAndProceed = async () => {
      if (params.oauth_token && params.oauth_verifier) {
        const idToken = await getIdTokenClaims();
        const accessToken = idToken.__raw;
        updateUserCodeToBackend(params.oauth_token, params.oauth_verifier, accessToken);
      }
    };

    fetchTokenAndProceed();
  }, [user, location.search, getIdTokenClaims]);

  function updateUserCodeToBackend(oauthToken, oauthVerifier, accessToken) {
    const requestBody = {
      email: user.email,
      oauthToken,
      oauthVerifier,
      type: "garmin"
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    const config = getConfig();
    fetch(config.api2Endpoint + "/thirdpartyconnection/add", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log('Response from backend:', result);
        setStatus(true);
      })
      .catch((error) => {
        console.error("Error connecting to Garmin:", error);
        setStatus(false); // You might want to handle this differently
      });
  }

  return status ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <div className="connectionStatus fadeIn">Connecting to Garmin...</div>
  );
};

export default withAuthenticationRequired(ThirdpartyauthGarmin, {
  onRedirecting: () => <Loading />,
});
