export const timezones = [
  'GMT-12:00', 'GMT-11:00', 'GMT-10:00', 'GMT-09:30', 'GMT-09:00', 'GMT-08:00',
  'GMT-07:00', 'GMT-06:00', 'GMT-05:00', 'GMT-04:00', 'GMT-03:30', 'GMT-03:00',
  'GMT-02:00', 'GMT-01:00', 'GMT+00:00', 'GMT+01:00', 'GMT+02:00', 'GMT+03:00',
  'GMT+03:30', 'GMT+04:00', 'GMT+04:30', 'GMT+05:00', 'GMT+05:30', 'GMT+05:45',
  'GMT+06:00', 'GMT+06:30', 'GMT+07:00', 'GMT+08:00', 'GMT+08:45', 'GMT+09:00',
  'GMT+09:30', 'GMT+10:00', 'GMT+10:30', 'GMT+11:00', 'GMT+12:00', 'GMT+12:45',
  'GMT+13:00', 'GMT+14:00'
];

export const gender = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];
