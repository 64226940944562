import React from "react";
import FamewallEmbed from "react-famewall";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export const BikeFitFameWall = () => {
  return (
    <Box sx={{ maxWidth: "xl", mx: "auto", p: 4, textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        Sanjeev Sharma's Bike Fitting Excellence
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to the home of precision and performance in cycling. Sanjeev
        Sharma brings years of expertise and a meticulous approach to bike
        fitting, ensuring every rider achieves their optimal alignment for
        comfort, efficiency, and speed. Experience the difference that a
        professional bike fit can make in your cycling journey.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Real Stories from Satisfied Cyclists
      </Typography>
      <Typography variant="body1" paragraph mt={2}>
        Have you experienced the benefits of a bike fit by Sanjeev Sharma? We
        invite you to share your journey and inspire others. Your story is
        powerful, and your feedback is invaluable to us. Click below to add your
        testimonial to our wall.
      </Typography>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Button
          variant="contained"
          color="primary"
          component="a"
          href="https://page.famewall.io/bikefit"
          target="_blank"
          rel="noopener noreferrer"
        >
          Share Your Testimonial
        </Button>
      </Box>
      <FamewallEmbed wallUrl="bikefit" cardTheme="light" />
    </Box>
  );
};
