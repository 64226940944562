import Grid from "@mui/material/Unstable_Grid2";

import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

function FormSlider({ valueG, handleChangeTargetFormPct }) {
  const initialRampIndex = getNearestRampIndex(valueG);
  const [value, setValue] = React.useState(initialRampIndex);

  const handleSliderChange = (event, newValue) => {
    handleChangeTargetFormPct(trainingPhases[newValue].ramp);
    setValue(newValue);
    console.log("trainingPhases[newValue].ramp", trainingPhases[newValue].ramp);
  };

  return (
    <Box sx={{ display: "flex", paddingTop: "16px" }}>
      <Grid container spacing={2} alignItems="center" sx={{ height: "100%" }}>
        <Grid xs={5} sx={{ height: "100%" }}>
          <Slider
            orientation="vertical"
            value={typeof value === "number" ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={1}
            valueLabelDisplay="auto"
            marks={trainingPhases.map(({ value, label }) => ({ value, label }))}
            min={0}
            max={trainingPhases.length - 1}
            sx={{ height: "120px" }}
          />
        </Grid>
        <Grid xs={7}>
          <div style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
            <Typography
              variant="body2"
              sx={{ wordWrap: "break-word", fontStyle: 'italic', color: 'gray' }}
            >
              {trainingPhases[value].text}
            </Typography>

          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FormSlider;

// Utilities
const trainingPhases = [
  {
    ramp: 10,
    value: 0,
    text: "Reduces training intensity before a major event, slightly lowering fitness levels but enhancing form and event readiness.",
    label: "Tapering",
  },
  {
    ramp: -10,
    value: 1,
    text: "Maintains fitness between events to prevent burnout and overtraining, ideal for transitioning between competitions.",
    label: "Maintenance",
  },
  {
    ramp: -20,
    value: 2,
    text: "Gradually enhances fitness and strength, suitable for athletes aiming to boost overall performance steadily.",
    label: "Build-Up",
  },
  {
    ramp: -30,
    value: 3,
    text: "Rapidly increases fitness for upcoming events without a primary focus on strength, intended for short-term performance gains.",
    label: "Quick Build",
  },
  {
    ramp: -40,
    value: 4,
    text: "Aggressively boosts fitness with a significant injury and fatigue risk, recommended only for seasoned athletes under careful oversight.",
    label: "Intense Boost",
  },
];

const getNearestRampIndex = (targetRamp) => {
  return trainingPhases.reduce((prevIndex, currentPhase, currentIndex) => {
    return Math.abs(currentPhase.ramp - targetRamp) <
      Math.abs(trainingPhases[prevIndex].ramp - targetRamp)
      ? currentIndex
      : prevIndex;
  }, 0); // Initialize with the index of the first element
};
