import React from 'react';
import { Box, Typography } from '@mui/material';
import { 
  LinkOutlined,
  LinkOffOutlined,
  DirectionsBike,
  DirectionsRun,
} from '@mui/icons-material';

const SectionHeader = ({ categoryName, sectionData }) => {
  const getDisplayInfo = () => {
    if (!sectionData) return null;

    switch (categoryName) {
      case 'Profile & Preferences':
        return sectionData.profile?.email;
      case 'Subscription':
        const plan = sectionData.subscription?.currentPlan;
        return plan?.trial?.isTrialActive ? 'Trial Active' : plan?.status;
      case 'Connections':
        const isConnected = sectionData.connections?.strava?.auth;
        return (
          <Box sx={{
            bgcolor: 'rgba(0, 0, 0, 0.04)',
            px: 1,
            py: 0.25,
            borderRadius: 5,
            fontSize: '0.75rem',
            color: 'text.secondary',
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}>
            {isConnected ? (
              <LinkOutlined sx={{ fontSize: 16, color: 'success.main' }} />
            ) : (
              <LinkOffOutlined sx={{ fontSize: 16, color: 'text.disabled' }} />
            )}
            Strava
          </Box>
        );
      case 'Upcoming Event':
        return sectionData.event?.name || 'No Event';
      case 'Workouts Volume':
        const method = sectionData.workoutVolume?.volumeSelectionMethod;
        return method ? method.charAt(0).toUpperCase() + method.slice(1) : 'Not Set';
      case 'Workouts Type':
        const workoutIntensity = sectionData.workoutIntensity || {};
        
        // If no sports are enabled, show 'Not Set'
        if (!workoutIntensity.cycling?.enabled && !workoutIntensity.running?.enabled) {
          return 'Not Set';
        }

        // Show the sport icon(s)
        return (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            {workoutIntensity.cycling?.enabled && (
              <DirectionsBike sx={{ fontSize: 16, color: 'primary.main' }} />
            )}
            {workoutIntensity.running?.enabled && (
              <DirectionsRun sx={{ fontSize: 16, color: 'primary.main' }} />
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  const displayInfo = getDisplayInfo();
  const isProfile = categoryName === 'Profile & Preferences';
  const isConnections = categoryName === 'Connections';
  const isWorkoutsType = categoryName === 'Workouts Type';

  if (isProfile) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="body1" component="span">
          {categoryName}
        </Typography>
        {displayInfo && (
          <Box
            component="span"
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.04)',
              px: 1,
              py: 0.25,
              borderRadius: 5,
              fontSize: '0.75rem',
              color: 'text.secondary',
              width: 'fit-content'
            }}
          >
            {displayInfo}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    }}>
      <Typography variant="body1" component="span">
        {categoryName}
      </Typography>
      {displayInfo && (
        <Box
          component="span"
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: isConnections || isWorkoutsType ? 'transparent' : 'rgba(0, 0, 0, 0.04)',
            px: isConnections || isWorkoutsType ? 0 : 1,
            py: isConnections || isWorkoutsType ? 0 : 0.25,
            borderRadius: 5,
            fontSize: '0.75rem',
            color: 'text.secondary',
            ml: 1
          }}
        >
          {displayInfo}
        </Box>
      )}
    </Box>
  );
};

export default SectionHeader;