import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export const useFetcher = () => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const getToken = async () => {
      try {
        const idToken = await getIdTokenClaims();
        setAccessToken(idToken.__raw);
      } catch (error) {
        console.error("Error getting ID token claims:", error);
      }
    };

    getToken();
  }, []);

  const refreshToken = async () => {
    try {
      const newToken = await getAccessTokenSilently();
      setAccessToken(newToken);
      return newToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  // Enhanced fetcher function to support multiple HTTP methods and JSON payloads
  const fetcher = async (url, options = {}) => {
    const { method = 'GET', data = null, headers = {} } = options;

    const requestOptions = {
      method,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        ...headers
      },
      ...(data && { data }), // Include data in the request only if it's provided
    };

    try {
      return await axios(url, requestOptions).then(res => res.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newToken = await refreshToken();
        if (newToken) {
          requestOptions.headers['Authorization'] = `Bearer ${newToken}`;
          return axios(url, requestOptions).then(res => res.data);
        }
      }
      console.error("Error in fetcher:", error);
      throw error; // Re-throw the error if it's not a 401 or token refresh fails
    }
  };

  return { fetcher, accessToken };
};
