import React, { useState, createContext, useContext } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { saveChatUpdates } from '../services';
import ChatPopup from '../components/ChatPopup';

const ChatContext = createContext({});

export const useChatContext = () => useContext(ChatContext);

export const ChatProvider = ({ children, accessToken, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleSave = async (updates) => {
    setIsSaving(true);
    try {
      const success = await saveChatUpdates(updates, accessToken, user?.email);
      if (success) {
        setSnackbar({
          open: true,
          message: 'Profile updated successfully',
          severity: 'success'
        });
        return true;
      } else {
        throw new Error('Save failed');
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update profile',
        severity: 'error'
      });
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const openChat = () => setIsOpen(true);
  const closeChat = () => setIsOpen(false);

  return (
    <ChatContext.Provider value={{ openChat, closeChat }}>
      {children}
      <ChatPopup
        open={isOpen}
        onClose={closeChat}
        onSave={handleSave}
        accessToken={accessToken}
        profile={user}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ChatContext.Provider>
  );
};
