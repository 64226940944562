import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function LogoSlider() {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <div className="client_logo_slider" id="connections">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3">
            <Box>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                align="center"
              >
                <h2>Works With</h2>
              </Typography>
            </Box>
          </div>
          <div className="col-md-8">
            <Slider {...settings}>
              <div className="logo_item">
                <img src="workswith/clientlogo01.png" />
              </div>
              <div className="logo_item">
                <img src="workswith/clientlogo02.png" />
              </div>
              <div className="logo_item">
                <img src="workswith/clientlogo03.png" />
              </div>
              <div className="logo_item">
                <img src="workswith/clientlogo04.png" />
              </div>
              <div className="logo_item">
                <img src="workswith/clientlogo05.png" />
              </div>
              <div className="logo_item">
                <img src="workswith/clientlogo06.png" />
              </div>
              <div className="logo_item">
                <img src="workswith/clientlogo07.png" />
              </div>
              <div className="logo_item">
                <img src="workswith/clientlogo08.png" />
              </div>
              <div className="logo_item">
                <img src="workswith/clientlogo09.png" />
              </div>
              <div className="logo_item">
                <img src="workswith/clientlogo10.png" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
