import { useState } from 'react';
import { questions } from '../../../constants/chatConstants';
import axios from 'axios';

const formatSuccessMessage = (field, value) => {
    switch (field) {
        case 'event_details':
            return `Great! I've set up your training plan for the ${value.type} ${value.subtype} on ${new Date(value.date).toLocaleDateString()}. Let's get you ready for ${value.name}! 🎯`;
        case 'fixed_days_settings':
            return `Perfect! Your training schedule has been set up with ${value.days} training days per week. Your account is now ready! 🎯`;
        case 'time_based_settings':
            return `Perfect! Your custom training schedule has been set up. Your account is now ready! 🎯`;
        default:
            return typeof value === 'object' ? 
                'Settings saved successfully!' : 
                `Thanks! ${value} has been saved.`;
    }
};

const useInputHandling = ({
    currentStep,
    steps,
    updates,
    setUpdates,
    addMessage,
    onSave,
    currentProfile,
    setCurrentStep,
    setIsComplete,
    fieldValidators,
    successMessages,
    promptTemplates,
    accessToken
}) => {
    const [isProcessing, setIsProcessing] = useState(false);

    const saveTrainingSettings = async (settings, type) => {
        try {
            let payload = {
                workoutVolume: {
                    settingEnabled: true,
                    volumeSelectionMethod: type === 'fixed' ? 'manual' : 'timebased'
                }
            };

            if (type === 'fixed') {
                payload.workoutVolume.manual = {
                    weekstart: settings.weekstart,
                    numberofdaysweekly: settings.days,
                    targetformpct: settings.rampRate
                };
            } else {
                payload.workoutVolume.manual = {
                    weekstart: settings.weekstart
                };
                payload.workoutVolume.timeBased = {
                    volumeSelectionTimeBasedSettings: settings.durations
                };
            }

            const response = await axios.patch(
                'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
                payload,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                    params: { email: currentProfile.email }
                }
            );
            return response.status === 200;
        } catch (error) {
            console.error('Failed to save training settings:', error);
            return false;
        }
    };

    const saveEventSettings = async (eventDetails) => {
        try {
            const response = await axios.patch(
                'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
                {
                    event: {
                        name: eventDetails.name,
                        description: '',
                        startDateLocal: eventDetails.date,
                        type: eventDetails.type,
                        subtype: eventDetails.subtype || ''
                    }
                },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                    params: { email: currentProfile.email }
                }
            );
            return response.status === 200;
        } catch (error) {
            console.error('Failed to save event settings:', error);
            return false;
        }
    };

    const processInput = async (input) => {
        setIsProcessing(true);
        const currentField = steps[currentStep];

        try {
            if (currentField === 'strava_connect') {
                if (input === 'skip') {
                    await addMessage('user', "I'll skip Strava connection for now");
                    await addMessage('assistant', "No problem! You can always connect Strava later from your settings.");
                    setIsComplete(true);
                }
                return;
            }

            if (!questions.find(q => q.id === currentField)?.skipValidation) {
                const validation = fieldValidators[currentField](input);
                if (validation) {
                    await addMessage('assistant', validation);
                    return;
                }
            }

            const newUpdates = { ...updates, [currentField]: input };
            setUpdates(newUpdates);

            // Handle API calls for specific fields
            let apiSuccess = true;
            if (currentField === 'event_details') {
                await addMessage('user', `Setting up event: ${input.name}`);
                apiSuccess = await saveEventSettings(input);
            } else if (currentField === 'fixed_days_settings' || currentField === 'time_based_settings') {
                apiSuccess = await saveTrainingSettings(input, currentField === 'fixed_days_settings' ? 'fixed' : 'timebased');
                if (apiSuccess) {
                    let displayInput = currentField === 'fixed_days_settings' ? 
                        `Training days: ${input.days}, Intensity: ${input.rampRate}` : 
                        'Custom training schedule';
                    await addMessage('user', String(displayInput));
                    await addMessage('assistant', formatSuccessMessage(currentField, input));
                    setIsComplete(true);
                    return;
                }
            }

            if (!apiSuccess) {
                await addMessage('assistant', "There was an error saving your settings. Please try again.");
                return;
            }

            if (currentStep < steps.length - 1) {
                const nextStep = currentStep + 1;
                const nextQuestion = steps[nextStep];

                let displayInput = input;
                if (typeof input === 'object' && currentField === 'event_details') {
                    displayInput = `Event: ${input.name} (${input.type} ${input.subtype})`;
                }

                await addMessage('user', String(displayInput));
                await addMessage('assistant', formatSuccessMessage(currentField, input));
                await addMessage('assistant', promptTemplates[nextQuestion],
                    questions.find(q => q.id === nextQuestion)?.subtext);

                setCurrentStep(nextStep);
            } else {
                await addMessage('user', String(input));
                await addMessage('assistant', formatSuccessMessage(currentField, input));
                setIsComplete(true);
            }
        } finally {
            setIsProcessing(false);
        }
    };

    return {
        isProcessing,
        processInput
    };
};

export default useInputHandling;
