import React from 'react';
import { Container, Typography, Card, CardContent } from '@mui/material';

const Shipping = () => {
    return (
        <Container maxWidth="md" sx={{ py: 8 }}>
            <Card elevation={3}>
                <CardContent>
                    <Typography variant="h4" component="h1" gutterBottom align="center">
                        Shipping Policy
                    </Typography>

                    <Typography variant="body1" paragraph>
                        EnduroCo is a digital service provider. We do not ship physical products. All our services are delivered electronically through our web platform and mobile applications.
                    </Typography>

                    <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Digital Delivery
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Upon successful subscription or purchase:
                        - Immediate access to our online training platform
                        - Instant activation of premium features
                        - Real-time workout planning and analytics
                        - Immediate access to AI coaching features
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        Service Activation
                    </Typography>
                    <Typography variant="body1" paragraph>
                        All services are activated automatically after successful payment processing. You will receive a confirmation email with access details and next steps.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        Platform Availability
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our services are available globally through:
                        - Web browser at enduroco.in
                        - Mobile applications (iOS and Android)
                        - Integration with supported fitness devices and platforms
                    </Typography>

                    <Typography variant="body1" sx={{ mt: 4 }}>
                        For any questions about accessing our services, please contact us at rohan@enduroco.in
                    </Typography>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Shipping;