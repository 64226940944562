import React from "react";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import useSWR from "swr";
import { getConfig } from "../../../config";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import PaymentPending from "../../paymentPending";
import NotificationsSection from "./notificationsSection";
import Divider from "@mui/material/Divider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"; // Import an appropriate icon

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const fetcher = (url) => fetch(url).then((res) => res.json());
//function to pause unpause
function DisplayBanner({ bannerText }) {
  const theme = useTheme(); // Using theme for consistent styling across the app

  if (!bannerText) return null; // Return null immediately if there's no banner text

  return (
    <Grid container spacing={2} direction="column">
      <Typography
        variant="body1"
        component="div"
        sx={{
          color: theme.palette.getContrastText(theme.palette.error.light),
          backgroundColor: theme.palette.error.light,
          padding: theme.spacing(1),
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[1],
          marginBottom: theme.spacing(1), // Reduced margin bottom
        }}
      >
        {bannerText}
      </Typography>
    </Grid>
  );
}

function Invoices2({
  email,
  onSubscriptionStatusChange,
  handleChangeChecked,
  userProfile,
}) {
  const config = getConfig();
  const { data: status, mutate } = useSWR(
    `${config.apiEndpoint}/api/v1/user/subscriptionStatus?email=${email}`,
    fetcher
  );

  useEffect(() => {
    let currentStatus;
    if (status?.appSubscription?.usingInApp) {
      currentStatus = status.appSubscription.status ? "Active" : "Inactive";
    } else {
      switch (status?.status) {
        case "active":
          currentStatus = "Active";
          break;
        case "inactive":
          currentStatus = "Inactive";
          break;
        case "trialing":
          currentStatus = `Trialing until ${moment(status.trialEndDate).format(
            "DD MMM YYYY"
          )}`;
          break;
        default:
          currentStatus = "Unknown";
      }
    }
    onSubscriptionStatusChange(currentStatus);
  }, [status, onSubscriptionStatusChange]);

  const theme = useTheme();

  const hasBanner = Boolean(status?.displayBanner); // Determine if there is a banner
  if (!status) return null;
  return (
    <Container sx={{ py: 4 }}>
      <Grid container spacing={2}>
        {hasBanner && <DisplayBanner bannerText={status?.displayBanner} />}

        <Grid xs={12} sx={{ mt: hasBanner ? 1 : 0 }}>
          {status?.appSubscription?.usingInApp ? (
            <>
              <Typography variant="body2">
                <strong>Status:</strong>{" "}
                {status.appSubscription.status ? "Active" : "Inactive"}
              </Typography>
              <Typography variant="body2">
                <strong>Expires:</strong>{" "}
                {new Date(
                  status.appSubscription.expires_date
                ).toLocaleDateString("en-US")}
              </Typography>
              <Typography variant="body2">
                <strong>Type:</strong>{" "}
                {status.appSubscription.type.replace(/_/g, " ")}
              </Typography>
              <Typography variant="body2">
                <strong>Store:</strong> {status.appSubscription.store}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mt: 2,
                  p: 2,
                  color: grey[800],
                  backgroundColor: grey[100],
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: theme.shadows[1],
                }}
              >
                <InfoOutlinedIcon
                  sx={{ mr: 1, color: theme.palette.info.main }}
                />
                You're currently subscribed through the mobile app. Any changes
                to your subscription need to be made within the app store from
                which it was purchased.
              </Typography>
            </>
          ) : (
            <Grid xs={12} sx={{ mt: hasBanner ? 1 : 0 }}>
              <>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.primary }}
                >
                  Account Status:{" "}
                  {status?.status
                    ? `${status.status
                      .charAt(0)
                      .toUpperCase()}${status.status.slice(1)}`
                    : "Not Available"}
                </Typography>
                {status?.status === "trialing" && (
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.info.main, mt: 1 }}
                  >
                    Trial Period Ends:{" "}
                    {moment(status.trialEndDate).format("DD MMM YYYY")}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.secondary, mt: 1 }}
                >
                  {status?.status !== "new" &&
                    `Next Bill Date: ${moment(status.nextBillDate).format(
                      "DD MMMM YYYY"
                    )}`}
                </Typography>
              </>
            </Grid>
          )}
        </Grid>

        {status?.invoices && status.invoices.length > 0 ? (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            {/* Invoice table */}
          </TableContainer>
        ) : (
          <Typography sx={{ mt: 2, color: theme.palette.text.secondary }}>
            No invoices available.
          </Typography>
        )}
      </Grid>
      <Divider sx={{ my: 2 }} />
      <NotificationsSection
        userProfile={userProfile}
        handleChangeChecked={handleChangeChecked}
      />
    </Container>
  );
}

export default Invoices2;
