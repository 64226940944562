export const disciplines = [
  { value: "Running", label: "🏃 Road & Trail Running" },
  { value: "Cycling", label: "🚴 Road & Indoor Cycling" },
  { value: "Triathlon", label: "🏊 Swim, Bike & Run" },
  { value: "Duathlon", label: "🏃🚴 Run, Bike & Run" }
];

export const eventTypes = {
  Running: ["5k", "10k", "Half Marathon", "Marathon", "Ultra Run (60k+)", "Ultra Run (flat 60+)"],
  Cycling: ["Race", "Ultra"],
  Triathlon: ["Sprint", "Half", "Full"],
  Other: []
};

export const weekStartOptions = [
  { value: "0", label: "Sunday" },
  { value: "1", label: "Monday" },
  { value: "6", label: "Saturday" }
];

export const trainingDaysOptions = [
  { value: "3", label: "Light (3 days)" },
  { value: "4", label: "Balanced (4 days)" },
  { value: "5", label: "Intensive (5 days)" },
  { value: "6", label: "Advanced (6 days)" }
];

export const rampRateOptions = [
  { value: "-40", label: "Very Aggressive" },
  { value: "-20", label: "Aggressive" },
  { value: "0", label: "Conservative" },
  { value: "20", label: "Recovery" },
  { value: "40", label: "Very Easy" }
];

export const timeSlots = [
  { value: "0", label: "Rest" },
  { value: "-1", label: "No limit" },
  ...Array.from({ length: 8 }, (_, i) => ({
    value: String((i + 1) * 15),
    label: "${(i + 1) * 15} minutes"
  }))
];
