import React from 'react';
import { Chip, useTheme } from '@mui/material';
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PoolIcon from "@mui/icons-material/Pool";
import BoltIcon from "@mui/icons-material/Bolt";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SpeedIcon from "@mui/icons-material/AccessTimeFilledOutlined";

const RenderThresholds = ({ id, thresholdSettings, userProfileState, intensityMetricsMapping }) => {
    const theme = useTheme();

    // Adjust chip style based on the count of chips
    const getChipStyles = (count) => ({
        backgroundColor: "#f5f5f5",
        color: "#757575",
        fontWeight: "normal",
        padding: count > 2 ? '0px 0px' : '0px 0px',  // Reduced padding for more than 2 chips
        fontSize: count > 2 ? '0.5rem' : '1rem',  // Smaller font for more than 2 chips
        display: 'flex',
        alignItems: 'center',
        gap: '4px'  // Maintain a small gap between items
    });

    // Custom icon render to adjust icon size based on chip count
    const renderCustomIcon = (ActivityIcon, MetricIcon, labelValue, count) => (
        <div style={{ display: "flex", alignItems: "center", padding: "0" }}>
            <ActivityIcon style={{ fontSize: count > 2 ? "0.8rem" : "1.2rem" }} />
            <span style={{ fontSize: count > 2 ? '0.5rem' : '1rem' }}>{labelValue}</span>
            <MetricIcon style={{ fontSize: count > 2 ? "0.8rem" : "1.2rem", color: theme.palette.primary.main }} />
        </div>
    );

    // Function to convert mps to minutes per km
    const convertToMinutesPerKm = (mps) => {
        const zeroPad = (num, places) => String(num).padStart(places, "0");
        if (!mps) return null;
        var minutes = (16.666666667 / mps).toFixed(2);
        return `${Math.floor(minutes)}:${zeroPad(
            Math.round(0.6 * (minutes % 1).toFixed(2).substring(2)),
            2
        )}`;
    };

    if (id === "thresholds" && thresholdSettings?.report) {
        const relevantMetrics = Object.keys(thresholdSettings.report).filter(
            (key) => {
                const [workoutType, metricType] = key.split("_");
                let userProfileKey = `${workoutType}${metricType === "lthr" ? "hr" : metricType === "ftp" ? "power" : "pace"}wo`;
                return userProfileState[userProfileKey] === true &&
                    intensityMetricsMapping[userProfileKey]?.types.includes(userProfileState.athletetype);
            }
        );

        const chipStyles = getChipStyles(relevantMetrics.length);

        const chips = relevantMetrics.map((key) => {
            const [workoutType, metricType] = key.split("_");
            const workoutMetricKey = `${workoutType}${metricType === "lthr" ? "hr" : metricType === "ftp" ? "power" : "pace"}wo`;
            const mapping = intensityMetricsMapping[workoutMetricKey];
            if (!mapping) {
                console.log("No mapping found for key:", workoutMetricKey);
                return null;
            }

            let labelValue = thresholdSettings.report[key].enduroco.threshold || "N/A";
            if (metricType === "threholdpace" && workoutType === "run") {
                labelValue = convertToMinutesPerKm(parseFloat(labelValue)) || "N/A";
            }

            return (
                <Chip
                    key={key}
                    icon={renderCustomIcon(mapping.ActivityIcon, mapping.Icon, labelValue, relevantMetrics.length)}
                    label=""
                    size="small"
                    style={chipStyles}
                />
            );
        });

        return <>{chips}</>;
    }
    return null;
};

export default RenderThresholds;
