import React from 'react';
import { Box, Typography, alpha } from '@mui/material';
import { Clock, Ban, Infinity, Sun, Moon, AlertCircle } from 'lucide-react';

const DailyDurationCalendar = ({ settings = {}, weekStart }) => {
    const getOrderedDays = () => {
        const baseDays = [
            { key: 'Mon', label: 'Monday', angle: 270 },
            { key: 'Tue', label: 'Tuesday', angle: 315 },
            { key: 'Wed', label: 'Wednesday', angle: 0 },
            { key: 'Thu', label: 'Thursday', angle: 45 },
            { key: 'Fri', label: 'Friday', angle: 90 },
            { key: 'Sat', label: 'Saturday', angle: 135 },
            { key: 'Sun', label: 'Sunday', angle: 180 }
        ];

        const reorderDays = (startDay) => {
            const startIndex = baseDays.findIndex(day => day.key === startDay);
            if (startIndex === -1) return baseDays;
            return [
                ...baseDays.slice(startIndex),
                ...baseDays.slice(0, startIndex)
            ].map((day, index) => ({
                ...day,
                angle: ((index * 360) / 7 + 270) % 360
            }));
        };

        switch (weekStart) {
            case '0':
                return reorderDays('Sun');
            case '6':
                return reorderDays('Sat');
            case '1':
                return baseDays;
            default:
                return baseDays;
        }
    };

    const days = getOrderedDays();

    const getTimeInfo = (value) => {
        if (value === undefined || value === null) return {
            icon: Clock,
            label: 'Not Set',
            color: '#757575',
            intensity: 0
        };

        if (value === -1) return {
            icon: Infinity,
            label: 'Flexible',
            color: '#1976d2',
            intensity: 100
        };

        if (value === 0) return {
            icon: Moon,
            label: 'Rest',
            color: '#757575',
            intensity: 0
        };

        let intensity = (value / 120) * 100;
        if (value <= 30) return {
            icon: Clock,
            label: `${value}min`,
            color: '#4caf50',
            intensity
        };
        if (value <= 60) return {
            icon: Clock,
            label: `${value}min`,
            color: '#fb8c00',
            intensity
        };
        return {
            icon: Clock,
            label: `${value}min`,
            color: '#e53935',
            intensity
        };
    };

    const getRestDay = () => {
        switch (weekStart) {
            case '0': return 'Sun';
            case '6': return 'Sat';
            case '1': return 'Mon';
            case '2': return 'Tue';
            default: return null;
        }
    };

    const restDay = getRestDay();

    return (
        <Box sx={{
            width: '100%',
            height: { xs: 'auto', md: '400px' },
            position: 'relative',
            display: { xs: 'block', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            py: 4
        }}>
            <Box sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'column',
                gap: 1,
                px: 2
            }}>
                {days.map(({ key, label }) => {
                    const value = settings[key];
                    const { icon: Icon, label: timeLabel, color, intensity } = getTimeInfo(value);
                    const isRestDay = key === restDay;

                    return (
                        <Box
                            key={key}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                p: 2,
                                borderRadius: '12px',
                                bgcolor: alpha(color, 0.1),
                                border: '1px solid',
                                borderColor: alpha(color, 0.2),
                                transition: 'all 0.2s',
                                opacity: isRestDay ? 0.8 : 1,
                                '&:hover': {
                                    transform: 'translateX(8px)',
                                    bgcolor: alpha(color, 0.15)
                                }
                            }}
                        >
                            <Box sx={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: alpha(color, 0.2),
                                color: color
                            }}>
                                <Icon size={20} />
                            </Box>

                            <Box>
                                <Typography variant="subtitle2" sx={{
                                    fontWeight: 600,
                                    color: isRestDay ? 'text.secondary' : 'text.primary'
                                }}>
                                    {label}
                                    {isRestDay && ' (Rest Day)'}
                                </Typography>
                                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                    {timeLabel}
                                </Typography>
                            </Box>

                            {!isRestDay && (
                                <Box sx={{
                                    ml: 'auto',
                                    width: '60px',
                                    height: '4px',
                                    borderRadius: '2px',
                                    bgcolor: alpha(color, 0.2),
                                    position: 'relative',
                                    overflow: 'hidden'
                                }}>
                                    <Box sx={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        height: '100%',
                                        width: `${intensity}%`,
                                        bgcolor: color,
                                        transition: 'width 0.3s ease-in-out'
                                    }} />
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Box>

            <Box sx={{
                display: { xs: 'none', md: 'block' },
                width: '100%',
                height: '100%',
                position: 'relative'
            }}>
                <Box sx={{
                    width: '300px',
                    height: '300px',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '50%',
                    border: '2px solid',
                    borderColor: 'divider',
                }} />

                {days.map(({ key, label, angle }) => {
                    const value = settings[key];
                    const { icon: Icon, label: timeLabel, color, intensity } = getTimeInfo(value);
                    const isRestDay = key === restDay;
                    const radians = (angle - 90) * (Math.PI / 180);
                    const radius = 150;
                    const x = Math.cos(radians) * radius;
                    const y = Math.sin(radians) * radius;

                    return (
                        <Box
                            key={key}
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))`,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 1,
                                opacity: isRestDay ? 0.8 : 1
                            }}
                        >
                            <Box sx={{
                                width: '60px',
                                height: '60px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                bgcolor: alpha(color, 0.1),
                                border: '2px solid',
                                borderColor: color,
                                transition: 'all 0.2s',
                                cursor: 'pointer',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                    bgcolor: alpha(color, 0.2)
                                }
                            }}>
                                <Icon size={20} color={color} />
                                <Typography variant="caption" sx={{ color: color, fontWeight: 600 }}>
                                    {timeLabel}
                                </Typography>
                            </Box>

                            <Typography
                                variant="caption"
                                sx={{
                                    fontWeight: 500,
                                    color: isRestDay ? 'text.secondary' : 'text.primary',
                                    textAlign: 'center',
                                    width: '80px'
                                }}
                            >
                                {label}
                                {isRestDay && ' (Rest)'}
                            </Typography>
                        </Box>
                    );
                })}

                <Box sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    width: '120px'
                }}>
                    <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600 }}>
                        Weekly Plan
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        Training Schedule
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default DailyDurationCalendar;
