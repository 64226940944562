import React from 'react';
import { Grid, ButtonBase, Typography, styled, Box, Alert } from '@mui/material';
import { Calendar, AlertCircle } from 'lucide-react';

const DayButton = styled(ButtonBase)(({ theme, isSelected }) => ({
  width: '100%',
  padding: '12px',
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.divider}`,
  backgroundColor: isSelected ? `${theme.palette.primary.main}10` : theme.palette.background.paper,
  transition: 'all 0.2s ease',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&:hover': {
    backgroundColor: isSelected ? `${theme.palette.primary.main}15` : theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    backgroundColor: theme.palette.action.disabledBackground,
  }
}));

const WeekStartSelector = ({ value, onChange, disabled }) => {
  const startDays = [
    { value: '0', label: 'Sunday', shortLabel: 'Sun' },
    { value: '1', label: 'Monday', shortLabel: 'Mon' },
    { value: '2', label: 'Tuesday', shortLabel: 'Tue' },
    { value: '6', label: 'Saturday', shortLabel: 'Sat' }
  ];

  return (
    <Box>
      <Alert
        severity="info"
        icon={<AlertCircle size={20} />}
        sx={{ mb: 2 }}
      >
        The first day of your training week will automatically be set as a rest day to ensure proper recovery
      </Alert>
      <Grid container spacing={1.5}>
        {startDays.map((day) => (
          <Grid item xs={6} sm={3} key={day.value}>
            <DayButton
              onClick={() => !disabled && onChange(day.value)}
              isSelected={value === day.value}
              disabled={disabled}
            >
              <Calendar
                size={16}
                style={{
                  color: value === day.value ? '#1976d2' : '#666'
                }}
              />
              <Box sx={{ textAlign: 'left' }}>
                <Typography
                  variant="body2"
                  color={value === day.value ? 'primary' : 'text.primary'}
                  sx={{ fontWeight: 500, lineHeight: 1.2 }}
                >
                  {day.label}
                </Typography>
                {value === day.value && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                  >
                    Rest Day
                  </Typography>
                )}
              </Box>
            </DayButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WeekStartSelector;
