import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import about_img from "../assets/about_img.png";

export default function AboutUs() {
  return (
    <div className="about_section" id="aboutus">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img className="about_img" src={about_img} alt="About" />
          </div>
          <div className="col-md-6">
            <Box>
              <Typography variant="h3" component="div" gutterBottom>
                <h5>No More Junk Miles.</h5>
                <h2>We use AI to generate the perfect workout for you.</h2>
              </Typography>
              <Typography variant="p" component="div" gutterBottom>
                <p>
                  If you want compete in a century bike ride or a bike time
                  trial. Irrespective of your age. If you are a amateur, rookie
                  or a novice. We have you covered.
                </p>
                <p>
                  Adaptive and real time. Your calendar will regenerate
                  everytime you complete a workout.{" "}
                </p>
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
