import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import chat_img from "../assets/speedometer_battery.png";
import check from "../assets/check.svg";
export default function ImageWithText() {
    return (
        <div className="imagetext_section">
            <div className="container">
                <div className="row align-items-center">

                    <div className="col-md-6">
                        <Box>
                            <Typography variant="h3" component="div" gutterBottom>
                                <h5>You are in control.</h5>
                                <h2>You control the ramp rate and the kind of training you want. </h2>
                            </Typography>
                            <Typography variant="p" component="div" gutterBottom>
                                <p>Static plans don't work. They are too rigid and they don't adapt to missed workouts, extra workouts, other sports / activities etc. With AI based adaptive planning we are always doing the correct workout.</p>
                                <ul>
                                    <li><img src={check} alt="icon" /> <b>Adaptive training</b></li>
                                    <li><img src={check} alt="icon" /> <b>Running, Cycling, Duathlon, Triathlon</b></li>
                                    <li><img src={check} alt="icon" /> <b>Power, Pace, HR or Feel based</b></li>
                                </ul>
                            </Typography>
                        </Box>
                    </div>
                    <div className="col-md-6">
                        <img className="about_img" src={chat_img} alt="About" />
                    </div>
                </div>
            </div>
        </div>
    );
}
