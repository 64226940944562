import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

import * as d3 from "d3";
import { useD3 } from "../hooks/useD3";

function BarChart({
  ChartWidth,
  height,
  workoutDoc,
  yAxisUnit,
  currentMetric,
  showLegends = true,
}) {
  const containerRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);
  useLayoutEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setParentWidth(containerRef.current.getBoundingClientRect().width);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    updateWidth(); // Initial update

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);
  function calculateTotalDuration(workoutDoc) {
    let totalDuration = 0;

    if (workoutDoc && Array.isArray(workoutDoc.steps)) {
      workoutDoc.steps.forEach((step) => {
        if (step.reps && Array.isArray(step.steps)) {
          // If there are repetitions, calculate the total duration of all nested steps and multiply by the number of reps
          const nestedDuration = step.steps.reduce(
            (acc, nestedStep) => acc + nestedStep.duration,
            0
          );
          totalDuration += nestedDuration * step.reps;
        } else {
          // Otherwise, just add the duration of the step
          totalDuration += step.duration;
        }
      });
    }

    return totalDuration;
  }
  // Calculate total duration
  let totalDuration = calculateTotalDuration(workoutDoc);
  // console.log({ workoutDoc });
  let interval = 15 * 60; // Interval for ticks
  let numTicks = Math.ceil(totalDuration / interval); // Number of ticks

  // State for loaded data
  const [loadedData, setLoadedData] = useState([]);

  // Effect to process data
  useEffect(() => {
    if (!workoutDoc || !currentMetric) return;

    // Log current workout and metric

    // Process data set
    const newDataSet = [];
    const metricValueMultiplier = getMetricValueMultiplier(
      workoutDoc,
      currentMetric
    );

    let accumulatedDuration = 0;
    workoutDoc.steps.forEach((step) => {
      if (step.reps) {
        for (let i = 0; i < step.reps; i++) {
          step.steps.forEach((childStep) => {
            newDataSet.push([
              accumulatedDuration,
              (metricValueMultiplier *
                childStep[currentMetric.toLowerCase()].value) /
                100,
              childStep.duration,
            ]);
            accumulatedDuration += childStep.duration;
          });
        }
      } else {
        newDataSet.push([
          accumulatedDuration,
          (metricValueMultiplier * step[currentMetric.toLowerCase()].value) /
            100,
          step.duration,
        ]);
        accumulatedDuration += step.duration;
      }
    });

    // Set new data
    setLoadedData(newDataSet);
  }, [workoutDoc, currentMetric, totalDuration]);

  // Function to get metric value multiplier
  function getMetricValueMultiplier(structure) {
    switch (currentMetric) {
      case "Power":
        return structure.ftp;
      case "HR":
        return structure.thresholdHr;
      case "Pace":
        return 100; // Base of 100 for the chart when currentMetric is "Pace"
      case "Strength":
        return 1; // Base of 100 for the chart when currentMetric is "Pace"
      default:
        return 1000 / structure.thresholdPace;
    }
  }

  // D3 hook
  const ref = useD3(
    (svg) => {
      // Set up margins and dimensions
      let margin;
      if (showLegends) margin = { top: 10, right: 10, bottom: 30, left: 50 };
      else margin = { top: 0, right: 10, bottom: 0, left: 30 };

      const effectiveWidth = ChartWidth || parentWidth;
      const width = effectiveWidth - margin.left - margin.right;
      const adjustedHeight = height - 50 - margin.top - margin.bottom;
      // Clear previous SVG contents
      svg.selectChild("g").remove();

      // Create scales
      var xScale = d3.scaleLinear().domain([0, 100]).range([0, width]),
        yScale = d3.scaleLinear().domain([0, 200]).range([adjustedHeight, 0]);

      // Create SVG group
      var g = svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      // Update scales
      xScale.domain([0, totalDuration]);
      let maxY = d3.max(loadedData, (d) => d[1]);
      yScale.domain([0, maxY]);

      // Calculate tick values
      let tickValues = Array.from({ length: numTicks }, (_, i) => i * interval);

      // Conditionally render the axes and their labels
      if (showLegends) {
        g.append("g")
          .attr("transform", `translate(0, ${adjustedHeight})`)
          .style("font", "15px times")
          .call(
            d3
              .axisBottom(xScale)
              .tickValues(tickValues)
              .tickFormat((d) => secondsToTime(d))
          )
          .selectAll("text")
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", "rotate(-90)");

        g.append("text")
          .attr("transform", `translate(${width / 2}, ${adjustedHeight + 60})`)
          .style("text-anchor", "middle")
          .text("Elapsed Time (Hours:Minutes)");

        g.append("g")
          .style("font", "15px times")
          .call(d3.axisLeft(yScale).ticks(4));

        g.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - margin.left)
          .attr("x", 0 - adjustedHeight / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text(yAxisUnit);
      }

      // Render the bars
      g.selectAll(".bar")
        .data(loadedData)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]))
        .attr("width", (d) => countWidth(totalDuration, width, d[2]))
        .attr("style", (d) => determineBarStyle(d, workoutDoc))
        .attr("height", (d) => adjustedHeight - yScale(d[1]));
    },
    [ChartWidth, totalDuration, loadedData, showLegends] // Dependencies
  );

  // Convert seconds to time format
  function secondsToTime(e) {
    var h = Math.floor(e / 3600).toString(),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    return h > 0 ? h + ":" + m : m;
  }

  // Calculate bar width
  function countWidth(totalDuration, totalWidth, value) {
    return (value * totalWidth) / totalDuration;
  }

  // Determine the style of bars
  function determineBarStyle(d, workoutDoc) {
    let color = "";
    for (let i = 0; i < workoutDoc?.zoneTimes?.length; i++) {
      if (
        workoutDoc.zoneTimes[i].minWatts < d[1] &&
        workoutDoc.zoneTimes[i].maxWatts > d[1]
      ) {
        color = workoutDoc.zoneTimes[i].color;
        break;
      }
    }
    return `fill:${color};fill-opacity:.25;stroke:${color};stroke-width:1px;`;
  }

  // Render the component
  return (
    <div ref={containerRef}>
      <svg
        id="d3-bar-chart"
        ref={ref}
        width={ChartWidth || parentWidth}
        height={height}
      ></svg>
    </div>
  );
}

export default BarChart;
