import React from "react";
import { Switch, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Ensure correct import of unstable Grid version

const Label = styled(Typography)({
  color: "#757575",
  fontSize: "16px",
  textTransform: "uppercase",
  fontWeight: "bold",
  marginLeft: "8px", // Add space between the switch and the label
});

export default function NotificationsSection({
  userProfile,
  handleChangeChecked,
}) {
  // Check if userProfile is defined before accessing its properties
  const emailNotification = userProfile?.emailnotification;
  const updateStravaDescription = userProfile?.updateStravaDescription || false;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid xs={12} container alignItems="center">
        <Switch
          checked={emailNotification || false} // Default to false if undefined
          onChange={(e) => handleChangeChecked(e, "emailnotification")}
        />
        <Label>Email Notifications</Label>
      </Grid>
      <Grid xs={12} container alignItems="center">
        <Switch
          checked={updateStravaDescription} // Use state directly
          onChange={(e) => handleChangeChecked(e, "updateStravaDescription")}
        />
        <Label>Update Strava Description</Label>
      </Grid>
    </Grid>
  );
}
