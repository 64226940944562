import React from 'react';
import { Box, Stack, Typography, Button, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import DailyDurationSlider from './DailyDurationSlider';
import { Zap, AlertCircle } from 'lucide-react';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const TimeBasedSettings = ({ localWorkoutVolume, handleChange, isEditing, errors }) => {
    const weekstart = localWorkoutVolume.manual?.weekstart;

    const getRestDay = (weekstart) => {
        switch (weekstart) {
            case '0': return 'Sun';
            case '6': return 'Sat';
            case '1': return 'Mon';
            case '2': return 'Tue';
            default:
                console.error('Invalid weekstart:', weekstart);
                return 'Mon'; // Default to Monday if invalid
        }
    };

    const getPresets = (restDay) => {
        if (!restDay) return [];

        const getDaySequence = () => {
            const startIndex = daysOfWeek.indexOf(restDay);
            if (startIndex === -1) return daysOfWeek;
            return [
                ...daysOfWeek.slice(startIndex + 1),
                ...daysOfWeek.slice(0, startIndex)
            ];
        };

        const createPreset = (trainingDayCount) => {
            const baseSchedule = {
                Sun: 0, Mon: 0, Tue: 0, Wed: 0, Thu: 0, Fri: 0, Sat: 0
            };

            const sequence = getDaySequence();

            if (trainingDayCount === 3) {
                [0, 2, 4].forEach(index => {
                    if (sequence[index]) baseSchedule[sequence[index]] = 60;
                });
            } else if (trainingDayCount === 4) {
                [0, 1, 3, 4].forEach(index => {
                    if (sequence[index]) baseSchedule[sequence[index]] = 60;
                });
            } else if (trainingDayCount === 5) {
                [0, 1, 3, 4, 5].forEach(index => {
                    if (sequence[index]) baseSchedule[sequence[index]] = 60;
                });
            }

            baseSchedule[restDay] = 0;
            return baseSchedule;
        };

        return [
            {
                name: '3 Days',
                values: createPreset(3)
            },
            {
                name: '4 Days',
                values: createPreset(4)
            },
            {
                name: '5 Days',
                values: createPreset(5)
            }
        ];
    };

    const restDay = getRestDay(weekstart);
    const quickPresets = getPresets(restDay);

    const applyPreset = (preset) => {
        Object.entries(preset.values).forEach(([day, value]) => {
            if (daysOfWeek.includes(day)) { // Only apply for valid days
                handleChange({
                    target: {
                        name: day,
                        value: value
                    }
                });
            }
        });
    };

    const getValue = (day) => {
        if (!daysOfWeek.includes(day)) return 0;
        const settings = localWorkoutVolume.timeBased?.volumeSelectionTimeBasedSettings || {};
        return settings[day] ?? 0;
    };

    const getOrderedDays = () => {
        if (!weekstart) return daysOfWeek;

        const startIndex = parseInt(weekstart);
        if (isNaN(startIndex)) return daysOfWeek;

        const normalizedIndex = ((startIndex % 7) + 7) % 7;
        return [...daysOfWeek.slice(normalizedIndex), ...daysOfWeek.slice(0, normalizedIndex)];
    };

    const orderedDays = getOrderedDays();

    return (
        <Box sx={{ mt: 3 }}>
            {isEditing && restDay && (
                <Box sx={{ mb: 3 }}>
                    <Alert
                        severity="info"
                        icon={<AlertCircle size={20} />}
                    >
                        {restDay} is set as a rest day based on your Week Start Day setting
                    </Alert>
                </Box>
            )}

            {isEditing && quickPresets.length > 0 && (
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Quick Presets
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Set 60-minute training sessions with balanced rest day distribution
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        {quickPresets.map((preset) => (
                            <Button
                                key={preset.name}
                                variant="outlined"
                                size="small"
                                startIcon={<Zap size={16} />}
                                onClick={() => applyPreset(preset)}
                                disabled={!isEditing}
                            >
                                {preset.name}
                            </Button>
                        ))}
                    </Stack>
                </Box>
            )}

            <Typography variant="subtitle1" gutterBottom>
                Daily Training Duration
            </Typography>
            <Stack spacing={2}>
                {orderedDays.map((day) => (
                    <Box key={day}>
                        <DailyDurationSlider
                            day={day}
                            value={getValue(day)}
                            onChange={handleChange}
                            disabled={!isEditing}
                            isRestDay={day === restDay}
                        />
                        {errors?.[day] && (
                            <Typography color="error" variant="caption" sx={{ mt: 0.5, display: 'block' }}>
                                {errors[day]}
                            </Typography>
                        )}
                    </Box>
                ))}
            </Stack>
        </Box>
    );
};

export default TimeBasedSettings;
