const contentData = [
  {
    title: "Create a new account",
    link: "/signup",
    description:
      "Click on the Sign Up button on the top of the screen to sign up for a free account.",
  },
  {
    title: "Connect external services. ",
    link: "/dashboard",
    description:
      "Connect to your Strava, Intervals.icu and TrainingPeaks account. Login needed for this. ",
  },
  {
    title: "See your upcoming workouts",
    link: "/dashboard",
    description:
      "You can see all your upcoming workouts in the calendar section of the app",
  },
  {
    title: "Download the app",
    link: "/",
    description:
      "You can also download the mobile app on Android and iOS and have the same seamless training experience.",
  },
];

export default contentData;
