import React from "react";
import { useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";

import Loading from "../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../../config";
var qs = require("qs");

export const Thirdpartyauth = () => {
  const location = useLocation();
  const { user } = useAuth0();
  const [source, setsource] = useState();
  const [status, setstatus] = useState(0);
  useEffect(() => {
    console.log(params.scope);
    if (
      params.state === "" &&
      params.scope === "read,activity:write,activity:read,activity:read_all"
    ) {
      // console.log(params.code, user.email);
      setsource("Strava");
      updateUserCodeToBackend(params.code, "stravaauth");
    } else if (!params.state) {
      if (params.code.length < 40) {
        setsource("Intervals.icu");
        updateUserCodeToBackend(params.code, "intervalsauth");
      } else {
        setsource("TrainingPeaks");
        updateUserCodeToBackend(params.code, "trainingpeaksauth");
      }
      // console.log(params.code, user.email);
    }
  }, []);
  function updateUserCodeToBackend(code, type) {
    var urlencoded = new URLSearchParams();
    urlencoded.append(type, code);
    urlencoded.append("email", user.email);
    urlencoded.append("type", type);
    console.log(urlencoded);
    var requestOptions = {
      method: "PATCH",
      body: urlencoded,
      redirect: "follow",
    };
    const config = getConfig();
    fetch(
      config.apiEndpoint + "/coachApi/userprofile",
      // "http://localhost:8080/coachApi/userprofile",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        // setupdatedUserProfile({});
        setstatus(true);
      })
      .catch((error) => console.log("error", error));
  }
  var params = qs.parse(location.search, { ignoreQueryPrefix: true });

  return status ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <div>Connecting to {source}..</div>
  );
};

export default withAuthenticationRequired(Thirdpartyauth, {
  onRedirecting: () => <Loading />,
});
