import React from "react";
import { Container } from "@mui/material";
import ConnectionsSection from "./ConnectionsSection";

const Connections = ({ connections, onRefresh, subscription }) => (
  <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3 }, py: { xs: 2, sm: 3 } }}>
    <ConnectionsSection 
      connections={connections}
      onRefresh={onRefresh}
      subscription={subscription}
    />
  </Container>
);

export default Connections;
