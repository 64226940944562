import moment from "moment";
import { displayHM } from "./calendarHelpers";
export function createData(data, currentMonday) {
  var monday = currentMonday.clone();
  var rows = [];
  let totalTSS = 0;

  for (var i = 0; i < 7; i++) {
    var workoutsOnDate = data?.workouts.filter(
      (activity) =>
        moment(activity.workoutdate, "YYYY-MM-DD").format("MMMM D, YYYY") ===
        monday.format("MMMM D, YYYY")
    );
    rows.push({
      day: monday.format("dddd"),
      date: monday.format("YYYY-MM-DD"),
      today: monday.isSame(moment(), "day"),
      fitness: workoutsOnDate.length > 0 ? workoutsOnDate[0].fitness : null,
    });

    workoutsOnDate.forEach((workout) => {
      if (
        moment(workout.workoutdate, "YYYY-MM-DD").isBetween(
          currentMonday,
          currentMonday.clone().add(6, "days"),
          "day",
          "[]"
        )
      ) {
        totalTSS += Number(workout.tss) || 0;
      }

      workout.workouts.forEach((workout2) => {
        rows.push({
          activity: workout2?.title || workout2?.name,
          duration: displayHM(workout2?.workout_doc?.duration),
          load: Math.round(
            workout2.tssPlanned ||
            workout2.load_edited ||
            workout2.tss ||
            workout2.training_load ||
            workout2.pace_load ||
            workout2.heart_rate_load ||
            0
          ),
          loads: {
            power_load: workout2.tss,
            training_load: workout2.training_load,
            pace_load: workout2.pace_load,
            heart_rate_load: workout2.heart_rate_load,
          },
          type:
            workout.type === "pastworkout" || !workout.type
              ? "workout"
              : workout.type,
          description: workout2?.description,
          descriptionBlocks: workout2?.descriptionBlocks,
          structure: workout2?.workout_doc,
          date: workout.workoutdate,
          fitness: workout.fitness,
          activityType: workout2?.type,
          bestPowers: workout2?.bestPowers,
          secondsPerDataPoint: workout2?.secondsPerDataPoint || null
        });
      });
    });

    monday.add(1, "days");
  }

  const filteredRows = rows.filter((row) => row.fitness !== null);
  console.log({ filteredRows });
  return { rows: filteredRows, totalTSS };
}
