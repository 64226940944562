import React from 'react';
import { Typography, Box, Divider, Container, Paper, Chip, Grid } from '@mui/material';
import { CreditCard } from 'lucide-react';
import RazorpaySubscription from './RazorpaySubscription';

const SubscriptionSection = ({ subscription, accessToken, userEmail }) => {
  const { currentPlan, details } = subscription;
  const isInAppSubscription = currentPlan?.provider === 'inApp';
  const isExpired = currentPlan?.status?.toLowerCase() === 'expired';

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return 'success';
      case 'expired':
        return 'error';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  const handleSubscriptionComplete = async () => {
    if (typeof window !== 'undefined') {
      const event = new CustomEvent('subscription-updated');
      window.dispatchEvent(event);
    }
  };

  const renderInAppSubscription = () => {
    return (
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" component="span" sx={{ mr: 2 }}>
                {currentPlan.tier} Plan
              </Typography>
              <Chip 
                label={currentPlan.status || 'Inactive'}
                color={getStatusColor(currentPlan.status)}
                size="small"
              />
            </Box>
          </Grid>

          {currentPlan.expiryDate && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                Expiry Date
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                {formatDate(currentPlan.expiryDate)}
              </Typography>
            </Grid>
          )}

          {!isExpired && (
            <Grid item xs={12}>
              <Box sx={{ 
                bgcolor: 'primary.lighter', 
                p: 3, 
                borderRadius: 2,
                border: 1,
                borderColor: 'primary.light',
                mt: 2
              }}>
                <Typography variant="body1" sx={{ color: 'primary.dark', mb: 1 }}>
                  Your subscription is currently managed through the Enduroco mobile app.
                </Typography>
                <Typography variant="body2" sx={{ color: 'primary.dark', opacity: 0.9 }}>
                  To view or modify your subscription details, please use the mobile application.
                </Typography>
              </Box>
            </Grid>
          )}

          {currentPlan.trial && (
            currentPlan.trial.isTrialActive || 
            (currentPlan.trial.trialEndDate && new Date(currentPlan.trial.trialEndDate) > new Date())
          ) && (
            <Grid item xs={12}>
              <Box sx={{ 
                bgcolor: currentPlan.trial.isTrialActive ? 'success.lighter' : 'background.default',
                p: 2,
                borderRadius: 1,
                border: 1,
                borderColor: currentPlan.trial.isTrialActive ? 'success.light' : 'divider'
              }}>
                <Typography variant="subtitle2" gutterBottom color="success.dark">
                  Trial Status
                </Typography>
                <Typography variant="body2" color="success.dark">
                  {currentPlan.trial.isTrialActive ? 'Active' : 'Expired'}
                  {currentPlan.trial.trialEndDate && 
                    ` - Ends ${formatDate(currentPlan.trial.trialEndDate)}`}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  const renderSubscriptionStatus = () => {
    if (isInAppSubscription && !isExpired) {
      return renderInAppSubscription();
    }

    const isSubscriptionActive = currentPlan?.status?.toLowerCase() === 'active';
    const hasValidDetails = details && Object.keys(details).length > 0;

    return (
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" component="span" sx={{ mr: 2 }}>
                {currentPlan.tier || 'Free'} Plan
              </Typography>
              <Chip 
                label={currentPlan.status || 'Inactive'}
                color={getStatusColor(currentPlan.status)}
                size="small"
              />
            </Box>
          </Grid>

          {currentPlan.expiryDate && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                Expiry Date
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                {formatDate(currentPlan.expiryDate)}
              </Typography>
            </Grid>
          )}

          {isSubscriptionActive && hasValidDetails && (
            <>
              {details.id && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Subscription ID
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {details.id}
                  </Typography>
                </Grid>
              )}

              {details.planType && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Plan Type
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {details.planType?.replace('_inr', '').charAt(0).toUpperCase() + 
                     details.planType?.replace('_inr', '').slice(1)}
                  </Typography>
                </Grid>
              )}

              {details.startDate && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Start Date
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {formatDate(details.startDate)}
                  </Typography>
                </Grid>
              )}

              {details.paymentMethod && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Payment Method
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {details.paymentMethod?.toUpperCase()}
                  </Typography>
                </Grid>
              )}

              {details.paymentStatus && details.paymentStatus !== 'NaN' && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Payment Status
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {details.paymentStatus?.charAt(0).toUpperCase() + 
                     details.paymentStatus?.slice(1)}
                  </Typography>
                </Grid>
              )}

              {details.totalPayments && details.totalPayments.count > 0 && (
                <Grid item xs={12}>
                  <Box sx={{ 
                    bgcolor: 'background.default', 
                    p: 2, 
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'divider'
                  }}>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Payment Summary
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Total Payments
                        </Typography>
                        <Typography variant="body1">
                          {details.totalPayments.count}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Paid
                        </Typography>
                        <Typography variant="body1">
                          {details.totalPayments.paid}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Remaining
                        </Typography>
                        <Typography variant="body1">
                          {details.totalPayments.remaining}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </>
          )}

          {currentPlan.trial && (
            currentPlan.trial.isTrialActive || 
            (currentPlan.trial.trialEndDate && new Date(currentPlan.trial.trialEndDate) > new Date())
          ) && (
            <Grid item xs={12}>
              <Box sx={{ 
                bgcolor: currentPlan.trial.isTrialActive ? 'success.lighter' : 'background.default',
                p: 2,
                borderRadius: 1,
                border: 1,
                borderColor: currentPlan.trial.isTrialActive ? 'success.light' : 'divider'
              }}>
                <Typography variant="subtitle2" gutterBottom color="success.dark">
                  Trial Status
                </Typography>
                <Typography variant="body2" color="success.dark">
                  {currentPlan.trial.isTrialActive ? 'Active' : 'Expired'}
                  {currentPlan.trial.trialEndDate && 
                    ` - Ends ${formatDate(currentPlan.trial.trialEndDate)}`}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  const shouldShowSubscriptionOptions = () => {
    if (isInAppSubscription && !isExpired) return false;
    
    return (
      currentPlan.tier === 'Free' ||
      currentPlan.status?.toLowerCase() === 'expired' ||
      (currentPlan.trial?.isTrialActive && currentPlan.trial?.trialEndDate)
    );
  };

  return (
    <Container maxWidth="md" sx={{ p: { xs: 0, sm: 1 } }}>
      <Paper
        elevation={0}
        sx={{
          p: { xs: 2, sm: 3 },
          textAlign: { xs: 'left', sm: 'inherit' },
          width: '100%',
          margin: 'auto'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 2, sm: 3 } }}>
          <CreditCard size={32} style={{ marginRight: '12px', color: '#1976d2' }} />
          <Typography
            variant="h5"
            component="h1"
            sx={{
              fontSize: { xs: '1.2rem', sm: '1.5rem' },
              textAlign: { xs: 'left', sm: 'center' }
            }}
          >
            Subscription
          </Typography>
        </Box>
        <Divider sx={{ mb: { xs: 2, sm: 3 } }} />

        {renderSubscriptionStatus()}

        {shouldShowSubscriptionOptions() && (
          <RazorpaySubscription
            accessToken={accessToken}
            userEmail={userEmail}
            onSubscriptionComplete={handleSubscriptionComplete}
          />
        )}
      </Paper>
    </Container>
  );
};

export default SubscriptionSection;
