import React, { useState } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Box,
    Snackbar,
    Alert,
    Paper,
    Container,
} from '@mui/material';
import Loading from "../Loading";
import { ProfileHeader, BasicInfoCard, PersonalDetailsCard } from './components/ProfileComponents';
import { timezones, gender } from './utils/profileConstants';

const ProfileSection = ({ profile: initialProfile, accessToken, onRefresh }) => {
    const { user } = useAuth0();
    const [profile, setProfile] = useState(initialProfile);
    const [isEditing, setIsEditing] = useState(false);
    const [editedProfile, setEditedProfile] = useState(initialProfile);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (field) => (event) => {
        setEditedProfile({ ...editedProfile, [field]: event.target.value });
        setErrors(prev => ({ ...prev, [field]: '' }));
    };

    const handleSave = async (updatedProfile) => {
        setIsSaving(true);
        try {
            await axios.patch(
                'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
                { profile: updatedProfile },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    params: { email: user.email }
                }
            );
            setProfile(updatedProfile);
            setSnackbar({
                open: true,
                message: 'Profile updated successfully',
                severity: 'success'
            });
            setIsEditing(false);
            if (onRefresh) onRefresh();
        } catch (err) {
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.error;
                if (errorMessage && errorMessage.toLowerCase().includes('phone number')) {
                    setErrors(prev => ({
                        ...prev,
                        number: errorMessage
                    }));
                }
                
                setSnackbar({
                    open: true,
                    message: errorMessage || 'Failed to update profile',
                    severity: 'error'
                });
            } else {
                setSnackbar({
                    open: true,
                    message: 'Failed to update profile. Please try again.',
                    severity: 'error'
                });
            }
        } finally {
            setIsSaving(false);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    if (!profile) return <Loading />;

    return (
        <Container disableGutters sx={{ width: '100%', position: 'relative' }}>
            {snackbar.open && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        top: '100px',
                        width: '100%',
                        maxWidth: '500px',
                        zIndex: 1000
                    }}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity={snackbar.severity}
                        sx={{
                            width: '100%',
                            borderRadius: 1,
                            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                            ...(snackbar.severity === 'error' && {
                                backgroundColor: 'error.dark',
                                color: '#fff',
                                fontWeight: 500,
                                '& .MuiAlert-icon': {
                                    color: '#fff'
                                }
                            }),
                            ...(snackbar.severity === 'success' && {
                                backgroundColor: 'success.main',
                                color: '#fff',
                                fontWeight: 500,
                                '& .MuiAlert-icon': {
                                    color: '#fff'
                                }
                            })
                        }}
                    >
                        {snackbar.message}
                    </Alert>
                </Box>
            )}

            <Paper elevation={0}>
                <ProfileHeader
                    profile={profile}
                    user={user}
                    isEditing={isEditing}
                    onEdit={() => setIsEditing(true)}
                    onSave={() => handleSave(editedProfile)}
                    onCancel={() => {
                        setIsEditing(false);
                        setEditedProfile(profile);
                        setErrors({});
                    }}
                    isSaving={isSaving}
                />
                <BasicInfoCard
                    profile={profile}
                    editedProfile={editedProfile}
                    handleChange={handleChange}
                    errors={errors}
                    isEditing={isEditing}
                />
                <PersonalDetailsCard
                    profile={profile}
                    editedProfile={editedProfile}
                    handleChange={handleChange}
                    errors={errors}
                    isEditing={isEditing}
                    timezones={timezones}
                    gender={gender}
                />
            </Paper>
        </Container>
    );
};

ProfileSection.propTypes = {
    profile: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        number: PropTypes.string,
        age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        gender: PropTypes.string,
        timezone: PropTypes.string,
    }),
    accessToken: PropTypes.string.isRequired,
    onRefresh: PropTypes.func,
};

ProfileSection.defaultProps = {
    profile: {
        name: '',
        email: '',
        number: '',
        age: '',
        weight: '',
        gender: '',
        timezone: 'GMT+00:00',
    },
    onRefresh: () => { },
};

export default withAuthenticationRequired(ProfileSection, {
    onRedirecting: () => <Loading />,
});
