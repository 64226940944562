import React from "react";
import { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import useSWR from "swr";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { getConfig } from "../../../config";
import { useSettingsReport } from "../../../hooks/useSettingsReport";
const { fromJS } = require("immutable");

const fetcher = (url) => fetch(url).then((res) => res.json());
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "td,th": {
    borderStyle: "1px solid",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold', // Added bold font weight for headers
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16, // Slightly reduced for a more modern look
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const CustomTableHead = () => (
  <TableHead>
    <TableRow>
      <StyledTableCell>Type</StyledTableCell>
      <StyledTableCell>Threshold</StyledTableCell>
    </TableRow>
  </TableHead>
);
export function ThresholdSettingsReport({ userProfile, handleChangeChecked }) {
  const config = getConfig();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    getToken();
  }, [getAccessTokenSilently]);

  async function getToken() {
    try {
      const idToken = await getIdTokenClaims();
      setAccessToken(idToken.__raw);
    } catch (error) {
      console.error(error);
    }
  }
  const { thresholds, refresh } = useSettingsReport(accessToken);


  const [isUnsaved, setIsUnsaved] = useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);

  const [unsavedChanges, setunsavedChanges] = useState(
    fromJS({
      Ride: { ftp: null, lthr: null },
      Run: { ftp: null, lthr: null, threshold_pace: null },
      Swim: { ftp: null, threshold_pace: null },
      Other: { ftp: null },
    })
  );

  const handleErrorMessageClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessageOpen(false);
  };
  useEffect(() => {
    if (thresholds) {
      handleChangeChecked(thresholds); // Call the parent function with new data
    }
  }, [thresholds, handleChangeChecked]);
  function handleChange(event, name) {
    handleChangeChecked(event, name);
  }
  function convertToMinutesPerKm(mps) {
    const zeroPad = (num, places) => String(num).padStart(places, "0");
    if (!mps) return null;
    var minutes = (16.666666667 / mps).toFixed(2);
    return `${Math.floor(minutes)}:${zeroPad(
      Math.round(0.6 * (minutes % 1).toFixed(2).substring(2)),
      2
    )}`;
  }
  function getMpsFromMinutesSeconds(minsec) {
    var a = minsec.split(":");
    var seconds = +a[0] * 60 + +a[1];
    var mpkm = seconds / 60;
    var mps = 1000 / (mpkm * 60);
    return mps;
  }
  function updateUserZones() {
    var qs = require("qs");

    const config = getConfig();
    // console.log({ config });
    var url = config.api2Endpoint + "/zones";
    var zones = removeEmpty({
      bike_ftp: unsavedChanges.toJS().Ride.ftp,
      bike_lthr: unsavedChanges.toJS().Ride.lthr,
      run_ftp: unsavedChanges.toJS().Run.ftp,
      run_lthr: unsavedChanges.toJS().Run.lthr,
      run_threholdpace: unsavedChanges.toJS().Run.threshold_pace,
      swim_ftp: unsavedChanges.toJS().Swim.ftp,
      swim_thresholdpace: unsavedChanges.toJS().Swim.threshold_pace,
      other_ftp: unsavedChanges.toJS().Other.ftp,
      other_lthr: unsavedChanges.toJS().Other.lthr,
    });
    var data = {
      ...{ email: userProfile.email },
      ...zones,
    };
    axios
      .post(url, qs.stringify(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setIsUnsaved(false);
        setunsavedChanges(
          unsavedChanges.mergeDeep({
            Ride: { ftp: null, lthr: null },
            Run: { ftp: null, lthr: null, threshold_pace: null },
            Swim: { ftp: null, threshold_pace: null },
            Other: { ftp: null, lthr: null },
          })
        );
        refresh();
      })
      .catch((error) => {
        console.log(error);
        setErrorMessageOpen(true);
      });
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );
    }
  }
  function handleChangeText(event, type, unit) {
    var value = event.target.value;
    setIsUnsaved(false);
    if (type == "Ride" && unit == "Watts") {
      var valueOld = thresholds.report.bike_ftp.enduroco.threshold;
      if (value != valueOld) setIsUnsaved(true);
      setunsavedChanges(
        unsavedChanges.mergeDeep({
          Ride: { ftp: value },
        })
      );
    }
    if (type == "Ride" && unit == "BPM") {
      var valueOld = thresholds.report.bike_lthr.enduroco.threshold;
      if (value != valueOld) setIsUnsaved(true);
      setunsavedChanges(
        unsavedChanges.mergeDeep({
          Ride: { lthr: value },
        })
      );
    }
    if (type == "Run" && unit == "Watts") {
      var valueOld = thresholds.report.run_ftp.enduroco.threshold;
      if (value != valueOld) setIsUnsaved(true);
      setunsavedChanges(
        unsavedChanges.mergeDeep({
          Run: { ftp: value },
        })
      );
    }
    if (type == "Run" && unit == "BPM") {
      var valueOld = thresholds.report.run_lthr.enduroco.threshold;
      if (value != valueOld) setIsUnsaved(true);
      setunsavedChanges(
        unsavedChanges.mergeDeep({
          Run: { lthr: value },
        })
      );
    }
    if (type == "Run" && unit == "Pace") {
      value = getMpsFromMinutesSeconds(value);
      var valueOld = thresholds.report.run_threholdpace.enduroco.threshold;
      if (value != valueOld) setIsUnsaved(true);
      setunsavedChanges(
        unsavedChanges.mergeDeep({
          Run: { threshold_pace: value },
        })
      );
    }
    handleChangeChecked({
      ...unsavedChanges.toJS(),
      [type]: { ...unsavedChanges.toJS()[type], [unit]: value }
    });
  }

  if (!thresholds) {
    return <div>Loading...</div>;
  }
  if (thresholds.message) {
    return <div>{thresholds.message}</div>;
  }
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  if (!thresholds) {
    return <div>Loading...</div>;
  }
  return thresholds.report ? (
    <div className="">
      <TableContainer>
        <Table aria-label="simple table">
          <CustomTableHead>
            <TableRow>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Threshold</StyledTableCell>
            </TableRow>
          </CustomTableHead>
          <TableBody>
            {(userProfile.athletetype === "Bike" ||
              userProfile.athletetype === "Duathlete" ||
              userProfile.athletetype === "Triathlete") &&
              userProfile.bikepowerwo ? (
              <StyledTableRow>
                <StyledTableCell>Cycling FTP</StyledTableCell>

                <StyledTableCell>
                  <TextField
                    label="Watts"
                    onChange={(params) =>
                      handleChangeText(params, "Ride", "Watts")
                    }
                    // sx={{ fontSize: "x-large" }}
                    defaultValue={
                      thresholds.report.bike_ftp.enduroco.threshold
                    }
                    placeholder="FTP in watts"
                    variant="standard"
                  ></TextField>
                </StyledTableCell>
              </StyledTableRow>
            ) : null}
            {(userProfile.athletetype === "Bike" ||
              userProfile.athletetype === "Duathlete" ||
              userProfile.athletetype === "Triathlete") &&
              userProfile.bikehrwo ? (
              <StyledTableRow>
                <StyledTableCell>Cycling LTHR</StyledTableCell>

                {userProfile.bikehrwo ? (
                  <StyledTableCell>
                    <TextField
                      label="BPM"
                      onChange={(params) =>
                        handleChangeText(params, "Ride", "BPM")
                      }
                      // sx={{ fontSize: "x-large" }}
                      defaultValue={
                        thresholds.report.bike_lthr.enduroco.threshold
                      }
                      placeholder="LTHR in BPM"
                      variant="standard"
                    />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell />
                )}
              </StyledTableRow>
            ) : null}
            {(userProfile.athletetype === "Run" ||
              userProfile.athletetype === "Duathlete" ||
              userProfile.athletetype === "Triathlete") &&
              userProfile.runpowerwo ? (
              <StyledTableRow>
                <StyledTableCell>Running FTP</StyledTableCell>

                {userProfile.runpowerwo ? (
                  <StyledTableCell>
                    <TextField
                      label="Watts"
                      onChange={(params) =>
                        handleChangeText(params, "Run", "Watts")
                      }
                      // sx={{ fontSize: "x-large" }}
                      defaultValue={
                        thresholds.report.run_ftp.enduroco.threshold
                      }
                      placeholder="FTP in watts"
                      variant="standard"
                    ></TextField>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell />
                )}
              </StyledTableRow>
            ) : null}
            {(userProfile.athletetype === "Run" ||
              userProfile.athletetype === "Duathlete" ||
              userProfile.athletetype === "Triathlete") &&
              userProfile.runpacewo ? (
              <StyledTableRow>
                <StyledTableCell>Running Threshold Pace</StyledTableCell>

                {userProfile.runpacewo ? (
                  <StyledTableCell>
                    <TextField
                      label="MM:SS"
                      onChange={(params) =>
                        handleChangeText(params, "Run", "Pace")
                      }
                      // sx={{ fontSize: "x-large" }}
                      defaultValue={convertToMinutesPerKm(
                        thresholds.report.run_threholdpace.enduroco.threshold
                      )}
                      placeholder="LTHR in BPM"
                      variant="standard"
                    />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell />
                )}
              </StyledTableRow>
            ) : null}
            {(userProfile.athletetype === "Run" ||
              userProfile.athletetype === "Duathlete" ||
              userProfile.athletetype === "Triathlete") &&
              userProfile.runhrwo ? (
              <StyledTableRow>
                <StyledTableCell>Running LTHR</StyledTableCell>

                {userProfile.runhrwo ? (
                  <StyledTableCell>
                    <TextField
                      label="BPM"
                      onChange={(params) =>
                        handleChangeText(params, "Run", "BPM")
                      }
                      // sx={{ fontSize: "x-large" }}
                      defaultValue={
                        thresholds.report.run_lthr.enduroco.threshold
                      }
                      placeholder="LTHR in BPM"
                      variant="standard"
                    />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell />
                )}
              </StyledTableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Table aria-label="customized table">
        <TableBody>
          <StyledTableRow>
            <StyledTableCell />

            <StyledTableCell align="right">
              <Button
                color="primary"
                size="small"
                variant="contained"
                aria-label="Save"
                disabled={!isUnsaved}
                onClick={() => updateUserZones()}
              >
                Save
              </Button>
              <Snackbar
                open={errorMessageOpen}
                autoHideDuration={6000}
                onClose={handleErrorMessageClose}
              >
                <Alert
                  onClose={handleErrorMessageClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  An error as occurred. Please check your values.
                </Alert>
              </Snackbar>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>

    </div>
  ) : null;
}
export default ThresholdSettingsReport;
