import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import {
  CustomCard,
  CustomCardContent,
  Heading,
  InfoText,
} from "../style/CustomCard"; // Import styled components

const LastActivityCard = ({ data }) => {
  const [lastActivity, setLastActivity] = useState(null);

  useEffect(() => {
    if (data && data.workouts) {
      const activities = data.workouts
        .filter((workout) => workout.type === "activity")
        .sort((a, b) => moment(b.workoutdate) - moment(a.workoutdate));
      if (activities.length > 0) {
        setLastActivity(activities[0]);
      }
    }
  }, [data]);

  const formatDate = (date) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "day").startOf("day");

    if (moment(date).isSame(today, "d")) {
      return "Today";
    } else if (moment(date).isSame(yesterday, "d")) {
      return "Yesterday";
    } else {
      return moment(date).format("dddd, MMMM Do YYYY");
    }
  };

  const formatMovingTime = (seconds) => {
    if (!seconds) return "";
    const time = moment.duration(parseInt(seconds, 10), "seconds");
    return `${time.hours()}h ${time.minutes()}m ${time.seconds()}s`;
  };

  const formatDistance = (meters) => {
    if (!meters) return "";
    return (parseInt(meters, 10) / 1000).toFixed(2) + " km";
  };

  const formatBestPowers = (bestPowers) => {
    if (!bestPowers) return [];
    const formattedPowers = [];
    for (const [key, value] of Object.entries(bestPowers)) {
      if (value > 0) {
        const formattedKey = key
          .split("_")
          .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
          .join(" ");
        formattedPowers.push({ key: formattedKey, value });
      }
    }
    return formattedPowers;
  };

  return (
    <CustomCard>
      <CustomCardContent>
        <Heading>Last Activity</Heading> {/* Using Heading for title */}
        {lastActivity ? (
          <>
            <InfoText>{formatDate(lastActivity.workoutdate)}</InfoText>{" "}
            {/* Using InfoText for details */}
            <InfoText>
              <strong>{lastActivity.workouts[0].title}</strong>
            </InfoText>
            {lastActivity.workouts[0].distance && (
              <InfoText>
                Distance: {formatDistance(lastActivity.workouts[0].distance)}
              </InfoText>
            )}
            {lastActivity.workouts[0].moving_time && (
              <InfoText>
                Moving Time:{" "}
                {formatMovingTime(lastActivity.workouts[0].moving_time)}
              </InfoText>
            )}
            {lastActivity.workouts[0].tss > 0 && (
              <InfoText>Load: {lastActivity.workouts[0].tss}</InfoText>
            )}
            {/* Table for displaying power data */}
            <Table size="small">
              <TableBody>
                {formatBestPowers(lastActivity.workouts[0].bestPowers).map(
                  (power, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {power.key}
                      </TableCell>
                      <TableCell align="right">{power.value} W</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </>
        ) : (
          <InfoText>No recent activities found.</InfoText>
        )}
      </CustomCardContent>
    </CustomCard>
  );
};

export default LastActivityCard;
