import * as React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import { styled } from "@mui/material/styles";

const helpers = {
  daysperweek: {
    title: "Workout days per week",
    text: [
      "This will help us to calculate your weekly workout schedule. We will use this information to help you find the workout load distriution.",
    ],
  },
  restday: {
    title: "Rest Day",
    text: [
      "Select the day of the week that you want to take rest. This will always follow the longest workout of the week.",
    ],
  },
  method: {
    title: "Method for training",
    text: [
      "If you are unsure select Polarized.",
      "MAF: MAF is a training technique that helps you run better, recover faster and be free of injuries. Most of the work here will be of low intensity and workouts will be longer.",
      "Polarized: Most of the work here would be either Zone 2 or Zones 4 to 6. Its also known as 80-20 method.",
    ],
  },
  athletetype: {
    title: "Athlete Type",
    text: [
      "Bike: If you are only a cyclist",
      "Run: If you are only a runner",
      "Duathlete: If you want to improve/maintain both running and cycling",
      "Both Daily: This method uploads both cycling and running workouts everyday. Do only one on any given day. Select this only if you are sure about what you are doing.",
      "Triathlon: Select this if you want to balance all 3 running, cycling and swimming. Select this if you are preparing for an sprint triathlon, Half/Full Iron Man.",
    ],
  },
  ramprate: {
    title: "Ramp Rate",
    text: [
      "This setting helps determine how quickly your workouts will get harder. -30 to -10 is considered the most productive range.",
      "Depending on which stage of training are you in change this value.",
      "Stage Maintenance : Select 4 to 6.",
      "Stage Event minus 2 weeks: 2.",
      "Stage Event minus 1 weeks: 0 or 1.",
      "If the workout seems too short select 7 or 8 for a short period of time.",
    ],
  },
  workouttype: {
    title: "Type of workouts",
    text: [
      "Bike Power : If you have a indoor trainer and a bike power meter.",
      "Bike HR : If you don't have only one indoor trainer but no power meter for outdoors select both Bike Power and Bike HR. Do the indoor rides using power based workouts and outdoor rides using HR based workout.",
      "Run Power : If you have a STRYD or COROS watch then use Run Power.",
      "Run Pace : If you don't have a run power meter (mentioned above) then select this.",
      "Run HR: If you have undulating running terrain use Run HR.",
      "Note. For long runs and hill repeats Run HR can be used.",
    ],
  },
  stravaconnect: {
    title: "Connect with Strava",
    text: [
      "Strava is used to get realtime updates of any changes in your data. Everytime a new workout is added,removed or updated the system recalulates and updates the training calendar.",
    ],
  },
  intervalsconnect: {
    title: "Connect with Intervals.icu",
    text: [
      "Intervals.icu is used to get realtime updates of any changes in your data. Everytime a new workout is added,removed or updated the system recalulates and updates the training calendar.",
    ],
  },
  trainingpeaksconnect: {
    title: "Connect with TrainingPeaks",
    text: [
      "TrainingPeaks is used to push the workouts. It then connects with all your devices and pushes workouts to them.",
    ],
  },
  garminconnect: {
    title: "Connect with Garmin",
    text: [
      "Garmin Connect is a community-based website for runners, cyclists and outdoor enthusiasts who track their activities with Garmin devices. Connecting with Garmin allows for automatic syncing of workouts, routes, and personal records. This ensures that your training data is up to date and integrates seamlessly with your training plans and analyses.",
    ],
  },
  wahooconnect: {
    title: "Connect with Wahoo",
    text: [
      "Wahoo is a community-based website for runners, cyclists and outdoor enthusiasts who track their activities with Garmin devices. Connecting with Garmin allows for automatic syncing of workouts, routes, and personal records. This ensures that your training data is up to date and integrates seamlessly with your training plans and analyses.",
    ],
  }
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function HelperText({ name }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <HelpIcon />
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {helpers[name].title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {helpers[name].text.map((text, index) => (
            <Typography key={index} gutterBottom>
              {text}
            </Typography>
          ))}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
