import React, { Component } from "react";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "reactstrap";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import contentData from "../utils/contentData";
import Pricing from "../views/pricing";
import Testimonials from "../views/testimonials";
import WorksWith from "./WorksWith";
import Screenshots from "./screenshots";
import AboutUs from "./AboutUs";
import BannerText from "./BannerText";
import LogoSlider from "./LogoSlider";
import ServiceSlider from "./ServiceSlider";
import ImageWithText from "./ImageWithText";
import HowItWorks from "./HowItWorks";
import DataSafe from "./DataSafe";
import ClientReview from "./ClientReview";
import { TestimonialsHomePage } from "../views/testimonialsHomePage";
import step_img from "../assets/workout_calendar.png";
const handleClick = () => {
  window.open("https://wa.me/919899054441");
};
class Content extends Component {
  render() {
    return (
      <div>
        <BannerText />
        <AboutUs />
        <LogoSlider />
        <ImageWithText />
        <HowItWorks />
        {/* <ServiceSlider /> */}
        <Pricing />
        {/* <DataSafe /> */}

        <div className="next-steps custom_step_section">
          <Container>
            <h2 className="text-center">What can I do next?</h2>
            <Row className="align-items-center">
              <div className="col-md-6 download_content_box">
                <div className="row">
                  {contentData.map((col, i) => (
                    <Col key={i} md={6} className="download_box">
                      <div className="step_icon_box">
                        <h6>
                          <Link href={col.link}>
                            <FontAwesomeIcon icon="link" />
                            {col.title}
                          </Link>
                        </h6>
                        <p>{col.description}</p>
                      </div>
                    </Col>
                  ))}
                </div>
              </div>
              <div className="col-md-6 download_img">
                <img className="about_img" src={step_img} alt="About" />
              </div>
            </Row>
          </Container>
        </div>
        <TestimonialsHomePage></TestimonialsHomePage>
      </div>
    );
  }
}

export default Content;
