import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "react-phone-input-2/lib/style.css";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  IconButton,
  Typography,
  LinearProgress,
  Button,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import Connections from "./sections/connections/connections.js";
import Invoices2 from "./sections/invoices2.js";
import UserProfileSection from "./sections/settingsUserProfile.js";
import ThresholdSettingsReport from "./sections/thresholdSettingReport.js";
import axios from "axios";
import { getConfig } from "../../config.js";
import qs from "qs";
import useSWR from "swr";
import { useFetcher } from "../../utils/fetcher.js";
import PrecisionTuningSection from "./sections/precisionTuningSection.js";
import NotificationsSection from "./sections/notificationsSection.js";
import SelectWorkoutType from "./sections/selectWorkoutType.js";
import SelectDiscipline from "./sections/selectDiscipline.js";
import VolumeSelector from "./sections/volumeSelector.js";

import PersonIcon from "@mui/icons-material/Person";
import LinkIcon from "@mui/icons-material/Link";
import SpeedIcon from "@mui/icons-material/Speed";
import BarChartIcon from "@mui/icons-material/BarChart";
import TuneIcon from "@mui/icons-material/Tune";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import SettingsAccordionSummary from "./components/SettingsAccordionSummary.js";
import ErrorBoundary from "./components/errorBoundary.js";
const SettingsModal = ({ onClose }) => {
  const { user, logout } = useAuth0();
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.only("xs"));
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLarge = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isUltraWide = useMediaQuery(theme.breakpoints.up("lg"));
  const isShortScreen = useMediaQuery("(max-height:1000px)"); // Adjust 500px based on your needs

  let dynamicModalStyle = { ...modalStyle };
  const dynamicTopBandStyle = topBandStyle(theme);

  if (isXSmall) {
    dynamicModalStyle = {
      ...dynamicModalStyle,
      width: "100%", // Full width for extra small screens
      maxWidth: "360px",
      right: "0px",
    };
  }
  if (isSmall) {
    dynamicModalStyle = {
      ...dynamicModalStyle,
      width: "360px", // Fixed width for small screens
    };
  }
  if (isMedium) {
    dynamicModalStyle = {
      ...dynamicModalStyle,
      width: "400px", // Fixed width for medium screens
    };
  }
  if (isLarge) {
    dynamicModalStyle = {
      ...dynamicModalStyle,
      width: "400px", // Fixed width for large screens
      transform: "none",
    };
  }
  if (isUltraWide) {
    const maxWidth = 1200; // Maximum content width
    const windowWidth = window.innerWidth;
    const sideSpace = (windowWidth - maxWidth) / 2; // Space on each side of the content

    dynamicModalStyle = {
      ...dynamicModalStyle,
      right: `${sideSpace}px`, // Align with the right edge of the content
      width: "400px", // Percentage width for ultra wide screens
      transform: "none",
    };
  }
  if (isShortScreen) {
    dynamicModalStyle = {
      ...dynamicModalStyle,
      height: "100%",
    };
  } else {
    dynamicModalStyle = {
      ...dynamicModalStyle,
      height: "max-content",
      display: "flex",
      flexDirection: "column",
    };
  }
  const contentStyle = {
    overflowY: "auto",
    flexGrow: 1, // Allow this part to grow and shrink as needed
  };
  const settingsOptions = [
    { name: "Profile", Icon: PersonIcon },
    { name: "Connections", Icon: LinkIcon },
    { name: "Discipline", Icon: DirectionsBikeIcon },
    { name: "Intensity", Icon: SpeedIcon },
    { name: "Benchmarks", Icon: BarChartIcon },
    { name: "Volume", Icon: TuneIcon },
    { name: "Notifications", Icon: NotificationsIcon },
    { name: "Subscription", Icon: CreditCardIcon },
  ];

  const [expanded, setExpanded] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [thresholdSettings, setThresholdSettings] = useState({});

  const handleSubscriptionStatusChange = (status) => {
    setSubscriptionStatus(status);
  };
  const { fetcher, accessToken } = useFetcher();
  const config = getConfig();
  const userProfileUrl = `${config.api2Endpoint}/user`;
  const {
    data: userProfile,
    error,
    mutate,
  } = useSWR(accessToken ? userProfileUrl : null, fetcher);
  const [userProfileState, setUserProfileState] = useState(userProfile);
  const [isModified, setIsModified] = useState(false);
  const [originalUserProfile, setOriginalUserProfile] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    if (userProfile) {
      setContentVisible(true); // Trigger the animation when data is loaded
    }
    if (accessToken) {
      mutate();
    }
  }, [accessToken, mutate]);

  useEffect(() => {
    setOriginalUserProfile(userProfile);
    setUserProfileState(userProfile);
  }, [userProfile]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isModified && !isUpdating) {
        handleUpdate();
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [userProfileState, isModified, isUpdating]);
  const handleLogout = () => {
    const logoutUrl = "https://www.enduroco.in";
    // const logoutUrl = window.location.origin;
    logout({ returnTo: logoutUrl });
  };

  const handleRefresh = () => {
    mutate(userProfileUrl);
  };
  const getUpdatedFields = () => {
    const updates = {};
    for (const key in userProfileState) {
      if (userProfileState[key] !== originalUserProfile[key]) {
        updates[key] = userProfileState[key];
      }
    }
    return updates;
  };
  const setUserProfile = (newProfile) => {
    setUserProfileState(newProfile);
    setIsModified(true); // Set modification state when userProfile is changed
  };

  const handleChangeUserProfile = (updatedFields) => {
    setUserProfile({ ...userProfileState, ...updatedFields });
    setIsModified(true);
  };
  const handleThresholdChange = (newSettings) => {
    setThresholdSettings(newSettings);
  };
  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      setUpdateInProgress(true);
      const updatedFields = getUpdatedFields();
      const fieldsToSubmit = { ...updatedFields };

      // Strip out fields that should not be sent in the update request
      delete fieldsToSubmit.updatedAt;
      delete fieldsToSubmit.garminOauthTokenSecret;
      delete fieldsToSubmit.garminRedirectUrl;

      if (Object.keys(fieldsToSubmit).length > 0) {
        await updateProfile(fieldsToSubmit);
        setOriginalUserProfile({ ...originalUserProfile, ...updatedFields });
      }

      setIsModified(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setIsUpdating(false);
      setUpdateInProgress(false);
    }
  };

  const updateProfile = async (profileData) => {
    const config = getConfig();
    const updateUrl = `${config.api2Endpoint}/user`;

    // Convert profileData object to URL-encoded string
    const urlEncodedData = qs.stringify(profileData);

    try {
      const response = await axios.patch(updateUrl, urlEncodedData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Profile successfully updated", response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  };

  const handleChangeTargetFormPct = (value) => {
    var updatedFields = {
      targetformpct: value,
      email: userProfile.email,
    };
    handleChangeUserProfile(updatedFields);
  };
  const handleChangeRadio = (event, param) => {
    const updatedUserProfile = {
      ...userProfileState,
      [param]: event.target.value,
    };
    setUserProfile(updatedUserProfile);
  };

  const handleChangeChecked = (event, param) => {
    var updatedFields = {
      [param]: event.target.checked,
      email: userProfile.email,
    };
    handleChangeUserProfile(updatedFields);
  };

  if (!userProfileState) {
    return null;
  } if (!userProfileState.email) {
    return null;
  }
  const sections = [
    {
      id: "profile",
      title: "Profile",
      content: (
        <UserProfileSection
          userProfile={userProfileState}
          onChange={handleChangeUserProfile}
          user={user}
          onClose={onClose}
        />
      ),
    },
    {
      id: "connections",
      title: "Connections",
      content: (
        <Connections userProfile={userProfileState} onRefresh={handleRefresh} />
      ),
    },
    {
      id: "workoutDiscipline",
      title: "Discipline ",
      content: (
        <SelectDiscipline
          userProfile={userProfileState}
          handleChangeUserProfile={handleChangeUserProfile}
        />
      ),
    },
    {
      id: "volume",
      title: "Volume",
      content: (
        <VolumeSelector
          userProfile={userProfileState}
          handleChangeUserProfile={handleChangeUserProfile}
          handleChangeRadio={handleChangeRadio}
          handleChangeTargetFormPct={handleChangeTargetFormPct}
          accessToken={accessToken}
        />
      ),
    },
    {
      id: "workoutType",
      title: "Intensity",
      content: (
        <SelectWorkoutType
          userProfile={userProfileState}
          handleChangeUserProfile={handleChangeUserProfile}
        />
      ),
    },
    {
      id: "thresholds",
      title: "Benchmarks",
      content: (
        <ThresholdSettingsReport
          userProfile={userProfileState}
          handleChangeChecked={handleThresholdChange}
        />
      ),
    },

    {
      id: "billing",
      title: "Subscription",
      content: (
        <Invoices2
          email={userProfileState?.email}
          onSubscriptionStatusChange={handleSubscriptionStatusChange}
          handleChangeChecked={handleChangeChecked}
          userProfile={userProfileState}
        />
      ),
    },
  ];

  // Handle change of accordion
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const athleteTypeMapping = {
    Bike: "Cycling",
    Run: "Running",
    Duathlete: "Duathlon",
    Triathlete: "Triathlon",
    // Add more mappings as necessary
  };
  const sectionsWithIcons = sections.map((section) => {
    const sectionIcon = settingsOptions.find(
      (option) => option.name === section.title
    )?.Icon;
    return {
      ...section,
      Icon: sectionIcon,
    };
  });
  return (
    <div style={dynamicModalStyle} className={contentVisible ? "fadeIn" : ""}>
      <div style={dynamicTopBandStyle}>Settings</div>

      {updateInProgress && <LinearProgress />}
      <IconButton onClick={onClose} style={closeButtonStyle}>
        <CloseIcon />
      </IconButton>
      <div style={contentStyle}>
        {sectionsWithIcons.map(({ id, title, content, Icon }) => (
          <Accordion
            key={id}
            expanded={expanded === id}
            onChange={handleChangeAccordion(id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <ErrorBoundary>
                <SettingsAccordionSummary
                  id={id}
                  title={title}
                  Icon={Icon}
                  userProfileState={userProfileState}
                  subscriptionStatus={subscriptionStatus}
                  thresholdSettings={thresholdSettings}
                />
              </ErrorBoundary>
            </AccordionSummary>
            <AccordionDetails>{content}</AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div >
  );
};

const topBandStyle = (theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "50px",
  backgroundColor: theme.palette.primary.main, // Use theme's primary main color
  color: theme.palette.primary.contrastText, // Use theme's primary contrast text color
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  fontSize: "20px",
  fontWeight: "bold",
  zIndex: 2000,
});

const modalStyle = {
  position: "fixed",
  right: 0,
  top: 0,
  bottom: 0,
  height: "100%",
  width: "30%",
  maxWidth: "600px",
  overflowY: "auto",
  backgroundColor: "white",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  zIndex: 1000,
  padding: "0px",
  paddingTop: "70px",
  paddingBottom: "100px",
  boxSizing: "border-box",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  zIndex: 2000,
};

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  width: "100%",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "&$expanded": {
    margin: "auto",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: -1,
  minHeight: 56,
  "&$expanded": {
    minHeight: 56,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    "&$expanded": {
      margin: "12px 0",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#f7f7f7", // Light grey background
}));
const progressBarStyle = {
  height: "2px", // Make the progress bar thinner
  backgroundColor: "lightgrey", // Background color of the progress bar
};

const logoutSectionStyle = {
  position: "relative",
  bottom: "20px", // Position it at the bottom of the modal
  left: "50%", // Center it horizontally
  transform: "translateX(-50%)", // Adjust for exact centering
  width: "100%", // Full width to contain the button
  textAlign: "center", // Center the button within the section
  padding: "10px 20px", // Add some padding around the button
};

const logoutButtonStyle = {
  // Rest of the styles
  color: "black",
  borderColor: "white", // Same for the border color if it's an outlined button
  marginTop: "20px",
};

export default SettingsModal;
