import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, Box, Typography, Paper, IconButton, Tooltip } from '@mui/material';
import { ActivitySquare, ChevronDown, ChevronRight, HelpCircle } from 'lucide-react';
import ZoneBreakdown from './ZoneBreakdown';

const convertToMinutesPerKm = (mps) => {
  if (!mps || isNaN(mps)) return "";
  const minutes = (16.666666667 / parseFloat(mps));
  const mins = Math.floor(minutes);
  const secs = Math.round((minutes - mins) * 60);
  return `${mins}:${secs.toString().padStart(2, '0')}`;
};

const getMpsFromMinutesSeconds = (minsec) => {
  if (!minsec || !minsec.includes(':')) return "0";
  const [mins, secs] = minsec.split(':').map(num => parseInt(num, 10));
  if (isNaN(mins) || isNaN(secs)) return "0";
  const totalMinutes = mins + (secs / 60);
  const mps = (1000 / (totalMinutes * 60)).toFixed(2);
  return mps;
};

const validatePaceInput = (value) => {
  if (value === "" || value === ":") return true;
  const paceRegex = /^(\d{0,2}:)?(\d{0,2})$/;
  if (!paceRegex.test(value)) return false;

  const parts = value.split(':');
  if (parts[0] && parseInt(parts[0]) > 15) return false; // Max 15 minutes per km
  if (parts[1] && parseInt(parts[1]) >= 60) return false;

  return true;
};

const formatPaceInput = (value) => {
  if (!value) return "";

  // Remove non-digits and non-colons
  let cleanValue = value.replace(/[^\d:]/g, '');

  // Handle colon
  if (cleanValue.indexOf(':') === -1 && cleanValue.length > 2) {
    cleanValue = cleanValue.slice(0, 2) + ':' + cleanValue.slice(2);
  }

  // Split into minutes and seconds
  let [mins, secs] = cleanValue.split(':');

  // Pad seconds with zero if needed
  if (secs && secs.length === 1) {
    secs = `${secs}0`;
  }

  // Reconstruct the value
  return secs ? `${mins}:${secs}` : mins;
};

const ThresholdInput = ({
  sport,
  metric,
  label,
  min,
  max,
  value,
  onChange,
  isEditing,
  enabled,
  unit
}) => {
  const [showZones, setShowZones] = useState(false);
  const [paceError, setPaceError] = useState(false);
  const [localPaceValue, setLocalPaceValue] = useState('');
  const helperText = getHelperText(sport, metric);
  const isPaceInput = metric === 'thresholdPace';

  useEffect(() => {
    if (isPaceInput) {
      // Only update local pace value when value changes from outside
      const paceValue = convertToMinutesPerKm(value);
      setLocalPaceValue(paceValue);
    }
  }, [value, isPaceInput]);

  if (!enabled) return null;

  const handleCardClick = () => {
    if (!isEditing) {
      setShowZones(!showZones);
    }
  };

  const handlePaceChange = (event) => {
    const newValue = event.target.value;

    if (validatePaceInput(newValue)) {
      setPaceError(false);
      setLocalPaceValue(newValue);

      // Only convert to MPS when the format is complete (mm:ss)
      if (newValue.includes(':') && newValue.split(':')[1]?.length === 2) {
        const mpsValue = getMpsFromMinutesSeconds(newValue);
        onChange({ target: { value: mpsValue } });
      }
    } else {
      setPaceError(true);
    }
  };

  const handleNormalChange = (event) => {
    const newValue = event.target.value;
    onChange({ target: { value: newValue } });
  };

  const zoneType = metric === 'ftp' ? 'power' : metric === 'lthr' ? 'hr' : null;
  const displayValue = isPaceInput ? localPaceValue : value;

  // Read-only view
  if (!isEditing) {
    return (
      <>
        <Paper
          elevation={0}
          onClick={handleCardClick}
          sx={{
            mb: showZones ? 0 : 2,
            p: 2,
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            backgroundColor: 'background.default',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: zoneType ? 'pointer' : 'default',
            transition: 'all 0.2s ease-in-out',
            '&:hover': zoneType ? {
              backgroundColor: 'action.hover',
              borderColor: 'primary.main'
            } : {},
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ActivitySquare size={20} style={{ color: '#1976d2', marginRight: '12px' }} />
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                {label}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                <Typography variant="h6" fontWeight="600">
                  {displayValue}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {unit}
                </Typography>
              </Box>
            </Box>
          </Box>
          {zoneType && (
            showZones ?
              <ChevronDown size={20} style={{ color: '#1976d2' }} /> :
              <ChevronRight size={20} style={{ color: '#9e9e9e' }} />
          )}
        </Paper>
        {zoneType && (
          <ZoneBreakdown
            threshold={value}
            type={zoneType}
            unit={unit}
            expanded={showZones}
          />
        )}
      </>
    );
  }

  // Edit mode
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <ActivitySquare size={16} />
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
        <Tooltip title={helperText} placement="top" arrow>
          <IconButton size="small" color="primary">
            <HelpCircle size={16} />
          </IconButton>
        </Tooltip>
      </Box>
      <TextField
        size="small"
        type={isPaceInput ? "text" : "number"}
        value={displayValue}
        onChange={isPaceInput ? handlePaceChange : handleNormalChange}
        error={paceError}
        disabled={!isEditing}
        inputProps={{
          min: isPaceInput ? undefined : min,
          max: isPaceInput ? undefined : max,
          step: isPaceInput ? undefined : '1',
          placeholder: isPaceInput ? "5:30" : undefined,
          sx: {
            textAlign: 'center',
            fontSize: '1.1rem',
            fontWeight: 500,
            width: '80px'
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
          sx: {
            maxWidth: '160px',
            backgroundColor: 'background.paper',
          }
        }}
        helperText={paceError ? "Enter pace as mm:ss (e.g., 5:30)" : helperText}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused': {
              '& > fieldset': {
                borderColor: paceError ? 'error.main' : 'primary.main',
                borderWidth: 2,
              }
            }
          }
        }}
      />
    </Box>
  );
};

const getHelperText = (sport, metric) => {
  if (sport === 'cycling') {
    switch (metric) {
      case 'ftp':
        return "Your Functional Threshold Power (FTP) is the highest power you can maintain for an hour. Quick estimate: Take 95% of your best 20-minute power test. Typical values: Beginners 120-200W, Intermediate 200-280W, Advanced 280-350W+";
      case 'lthr':
        return "Lactate Threshold Heart Rate (LTHR) is your sustainable HR for a 40-60 minute hard effort. Quick estimate: Take your heart rate during the last 20 minutes of a 30-minute time trial. Typical range: 150-175 bpm for most adults";
      default:
        return '';
    }
  } else if (sport === 'running') {
    switch (metric) {
      case 'ftp':
        return "Running Power Threshold is similar to cycling FTP. Quick estimate: Take 90% of your best 20-minute running power. Typical values: Beginners 200-250W, Intermediate 250-300W, Advanced 300W+";
      case 'lthr':
        return "Running LTHR is typically 5-10 bpm higher than cycling LTHR. Quick estimate: Take your average HR from a hard 30-minute run. Typical range: 155-180 bpm for most adults";
      case 'thresholdPace':
        return "Threshold pace is your sustainable pace for about an hour (roughly your 10K race pace). Quick estimate: Take your recent 5K race pace and add 15-20 seconds per km. Example: 5K at 5:00/km → threshold ~5:15-5:20/km. Format: mm:ss (e.g., 5:30)";
      default:
        return '';
    }
  }
  return '';
};

export default ThresholdInput;