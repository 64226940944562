import React from 'react';
import { Paper, Typography, Box, Collapse, Button } from '@mui/material';
import { Bike, Activity, Edit2, Save, X } from 'lucide-react';

const SportCard = ({ title, children, expanded = true, isEditing, onSave, onCancel, isSaving }) => {
  const icon = title.toLowerCase() === 'cycling' ? (
    <Bike size={24} style={{ color: '#1976d2' }} />
  ) : (
    <Activity size={24} style={{ color: '#1976d2' }} />
  );

  return (
    <Paper
      elevation={1}
      sx={{
        position: 'relative',
        p: { xs: 2, sm: 3 },
        borderRadius: 2,
        border: '1px solid',
        borderColor: isEditing ? 'primary.main' : '#e0e0e0',
        backgroundColor: 'background.paper',
        transition: 'all 0.2s ease-in-out',
        cursor: isEditing ? 'default' : 'pointer',
        '&:hover': !isEditing ? {
          borderColor: 'primary.main',
          backgroundColor: 'action.hover',
          '& .edit-hint': {
            opacity: 1,
            transform: 'translateY(0)',
          }
        } : undefined
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'flex-start', 
        justifyContent: 'space-between',
        mb: 2,
        gap: 2
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          <Typography variant="h6" sx={{ ml: 1.5, fontWeight: 600 }}>
            {title}
          </Typography>
        </Box>
        
        {isEditing ? (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSave}
              disabled={isSaving}
              startIcon={<Save size={16} />}
              size="small"
            >
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onCancel}
              disabled={isSaving}
              startIcon={<X size={16} />}
              size="small"
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <Box
            className="edit-hint"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              color: 'primary.main',
              fontSize: '0.875rem',
              opacity: 0.7,
              transform: 'translateY(2px)',
              transition: 'all 0.2s ease-in-out',
              backgroundColor: 'action.hover',
              padding: '6px 12px',
              borderRadius: 1,
              '&:hover': {
                backgroundColor: 'action.selected'
              }
            }}
          >
            <Edit2 size={14} />
            Click to edit
          </Box>
        )}
      </Box>
      
      <Collapse in={expanded}>
        <Box>
          {children}
          {!isEditing && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent',
                cursor: 'pointer'
              }}
              onClick={onCancel}
            />
          )}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default SportCard;
