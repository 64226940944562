import { useState } from 'react';
import { verifyStravaConnection } from '../../../services/stravaService';

const useStravaHandling = ({ addMessage, accessToken, email, onComplete }) => {
    const [showStravaConfirmation, setShowStravaConfirmation] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);

    const handleStravaConnect = () => {
        window.open(
            'https://www.strava.com/oauth/authorize?client_id=41820&response_type=code&redirect_uri=http://www.enduroco.in%2Fthirdpartyauth2&approval_prompt=force&scope=activity:read,activity:read_all,activity:write',
            '_blank',
            'noopener,noreferrer'
        );
        addMessage('assistant', "I've opened Strava in a new window. Once you've completed the connection there, please confirm below if you successfully connected your Strava account.");
        setShowStravaConfirmation(true);
    };

    const handleConfirmStrava = async (userConfirmed) => {
        if (userConfirmed) {
            setIsVerifying(true);
            await addMessage('user', "Yes, I've connected Strava successfully");
            await addMessage('assistant', "Let me verify the connection...");
            
            try {
                const settings = await verifyStravaConnection(accessToken, email);
                const isConnected = settings?.connections?.strava?.auth === true;
                
                if (isConnected) {
                    await addMessage('assistant', "Great! Your Strava account has been connected! 🎉");
                    setShowStravaConfirmation(false);
                    onComplete && onComplete(true);
                    return true;
                } else {
                    await addMessage('assistant', "I couldn't verify the Strava connection. Please try connecting again from the settings page later.");
                }
            } catch (error) {
                await addMessage('assistant', "There was an error verifying the connection. Please try again later from the settings page.");
            } finally {
                setIsVerifying(false);
            }
        } else {
            await addMessage('user', "No, I haven't connected Strava yet");
            await addMessage('assistant', "No problem! You can always connect Strava later from your settings.");
            onComplete && onComplete(false);
        }
        setShowStravaConfirmation(false);
        return false;
    };

    return {
        showStravaConfirmation,
        isVerifying,
        handleStravaConnect,
        handleConfirmStrava
    };
};

export default useStravaHandling;
