import React, { useState } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from 'axios';
import { Button, Container, TextField, Typography, CircularProgress, Box, Snackbar } from '@mui/material';

const CreateClub = ({ accessToken }) => {
    const [clubData, setClubData] = useState({ name: '', description: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setClubData({ ...clubData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await axios.post('https://api2.enduroco.in/clubs', clubData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setOpen(true);
            setClubData({ name: '', description: '' }); // Reset form
        } catch (err) {
            setError(err.message || 'Failed to create club');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>Create a New Club</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    name="name"
                    value={clubData.name}
                    onChange={handleInputChange}
                    required
                />
                <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                    name="description"
                    value={clubData.description}
                    onChange={handleInputChange}
                    required
                />
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Create Club'}
                </Button>
            </form>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                message="Club created successfully"
            />
            {error && <Typography color="error">{error}</Typography>}
        </Container>
    );
};

export default withAuthenticationRequired(CreateClub, {
    onRedirecting: () => <CircularProgress />,
    returnTo: '/login'
});
