import React from 'react';
import { Box, Typography, useTheme, alpha } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';

const WeeklySummary = ({ weekStart, events, isLastWeek }) => {
  const theme = useTheme();

  const calculateWeeklyLoads = () => {
    const weekEnd = moment(weekStart).endOf('isoWeek');
    
    const weeklyEvents = events.filter(event => {
      const eventDate = moment(event.date);
      return eventDate.isBetween(weekStart, weekEnd, 'day', '[]');
    });

    let trainingLoad = 0;
    let activityLoad = 0;

    weeklyEvents.forEach(event => {
      if (event.eventtype === 'activity') {
        const loads = [
          event.load_edited || 0,
          event.tss || 0,
          event.power_load || 0,
          event.heart_rate_load || 0,
          event.pace_load || 0
        ];
        activityLoad += Math.max(...loads);
      } else if (event.eventtype !== 'plan') {
        trainingLoad += Number(event.training_load || 0);
      }
    });

    return {
      trainingLoad: Math.round(trainingLoad),
      activityLoad: Math.round(activityLoad)
    };
  };

  const getWeekInfo = () => {
    const weekEnd = moment(weekStart).endOf('isoWeek');
    const weeklyEvents = events.filter(event => {
      const eventDate = moment(event.date);
      return eventDate.isBetween(weekStart, weekEnd, 'day', '[]');
    });

    // Find training block info from any event in the week
    const eventWithBlock = weeklyEvents.find(event => event.trainingBlock);
    
    // Get plan data either from regular events or plan-only events
    const planData = weeklyEvents.find(event => event.planTotalLoad);
    
    // Show plan load for future weeks
    const isWeekInFuture = moment(weekStart).isAfter(moment(), 'day');
    
    return {
      trainingBlock: eventWithBlock?.trainingBlock,
      planTotalLoad: isWeekInFuture || weeklyEvents.some(e => e.eventtype === 'plan') 
        ? planData?.planTotalLoad 
        : null
    };
  };

  const { trainingBlock, planTotalLoad } = getWeekInfo();
  const { trainingLoad, activityLoad } = calculateWeeklyLoads();

  const getWeekTypeStyles = (weekType) => {
    if (!weekType) return {};
    
    const baseStyles = {
      backgroundColor: theme.palette.background.paper,
      borderLeft: '3px solid',
    };

    switch (weekType.toLowerCase()) {
      case 'active':
        return {
          ...baseStyles,
          borderLeftColor: theme.palette.primary.main,
          backgroundColor: alpha(theme.palette.primary.main, 0.03),
        };
      case 'recovery':
        return {
          ...baseStyles,
          borderLeftColor: theme.palette.info.main,
          backgroundColor: alpha(theme.palette.info.main, 0.03),
        };
      default:
        return baseStyles;
    }
  };

  const getWeekTypeBadgeStyles = (weekType) => {
    if (!weekType) return {};

    const isActive = weekType.toLowerCase() === 'active';
    return {
      backgroundColor: alpha(
        isActive ? theme.palette.primary.main : theme.palette.info.main,
        0.1
      ),
      color: isActive ? theme.palette.primary.main : theme.palette.info.main,
      border: `1px solid ${alpha(
        isActive ? theme.palette.primary.main : theme.palette.info.main,
        0.2
      )}`,
      px: 1,
      py: 0.25,
      borderRadius: '4px',
      fontSize: '0.75rem',
      fontWeight: 500,
      textTransform: 'capitalize',
    };
  };

  return (
    <Grid 
      container 
      sx={{
        ...getWeekTypeStyles(trainingBlock?.weekType),
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: isLastWeek ? `1px solid ${theme.palette.divider}` : 'none',
        py: 1,
        px: 2,
        height: '36px',
        marginTop: '-1px',
        marginBottom: isLastWeek ? 0 : '16px',
        transition: 'background-color 0.2s ease',
        '&:hover': {
          backgroundColor: alpha(theme.palette.action.hover, 0.05),
        },
        alignItems: 'center',
      }}
    >
      <Grid xs={8}>
        {trainingBlock && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 2
          }}>
            <Typography 
              variant="body2" 
              sx={{ 
                fontWeight: 500,
                color: theme.palette.text.primary,
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              {trainingBlock.trainingPhase} Phase
              <Box 
                component="span" 
                sx={{ 
                  px: 1,
                  py: 0.25,
                  borderRadius: '4px',
                  backgroundColor: alpha(theme.palette.grey[500], 0.1),
                  fontSize: '0.75rem',
                }}
              >
                Week {trainingBlock.currentWeek}/{trainingBlock.totalWeeks}
              </Box>
            </Typography>
            
            <Box component="span" sx={getWeekTypeBadgeStyles(trainingBlock.weekType)}>
              {trainingBlock.weekType}
            </Box>
          </Box>
        )}
      </Grid>

      <Grid xs={4}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
          alignItems: 'center',
          '& > span': {
            display: 'inline-flex',
            alignItems: 'center',
            gap: 1,
            fontSize: '0.75rem',
            fontWeight: 500
          }
        }}>
          {planTotalLoad !== undefined && planTotalLoad !== null && (
            <span>
              <Typography
                component="span"
                sx={{
                  color: theme.palette.text.secondary,
                }}
              >
                Planned
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontWeight: 600,
                  color: theme.palette.primary.main
                }}
              >
                {planTotalLoad}
              </Typography>
            </span>
          )}
          {(trainingLoad > 0 || activityLoad > 0) && (
            <>
              <span>
                <Typography
                  component="span"
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  Training
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.success.main
                  }}
                >
                  {trainingLoad || 0}
                </Typography>
              </span>
              <span>
                <Typography
                  component="span"
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  Activity
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.info.main
                  }}
                >
                  {activityLoad || 0}
                </Typography>
              </span>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default WeeklySummary;
