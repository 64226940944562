import React from 'react';
import { IconButton, Badge } from '@mui/material';
import { MessageCircle } from 'lucide-react';

const ChatButton = ({ onClick, hasUnreadMessages = false }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        color: 'inherit',
        mx: 1
      }}
    >
      <Badge
        color="error"
        variant="dot"
        invisible={!hasUnreadMessages}
      >
        <MessageCircle size={24} />
      </Badge>
    </IconButton>
  );
};

export default ChatButton;
