import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { Bot, User } from 'lucide-react';

const MessageBubble = ({ message, isTyping, isLastMessage }) => {
    const isAssistant = message.role === 'assistant';

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'flex-start',
                justifyContent: isAssistant ? 'flex-start' : 'flex-end',
                mb: 2
            }}
        >
            {isAssistant && (
                <Avatar
                    sx={{
                        width: 32,
                        height: 32,
                        bgcolor: 'primary.main',
                    }}
                >
                    <Bot size={20} />
                </Avatar>
            )}
            <Box
                sx={{
                    maxWidth: '70%',
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: isAssistant ? 'background.paper' : 'primary.main',
                    color: isAssistant ? 'text.primary' : 'white',
                    boxShadow: 1,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        whiteSpace: 'pre-line',
                        wordBreak: 'break-word'
                    }}
                >
                    {message.content}
                    {isTyping && isAssistant && isLastMessage && (
                        <span className="typing-indicator">▋</span>
                    )}
                </Typography>
                {isAssistant && message.subtext && (
                    <Typography
                        variant="caption"
                        sx={{
                            display: 'block',
                            mt: 1,
                            color: 'text.secondary',
                            fontStyle: 'italic'
                        }}
                    >
                        {message.subtext}
                    </Typography>
                )}
            </Box>
            {!isAssistant && (
                <Avatar
                    sx={{
                        width: 32,
                        height: 32,
                        bgcolor: 'primary.dark',
                    }}
                >
                    <User size={20} />
                </Avatar>
            )}
        </Box>
    );
};

export default MessageBubble;
