import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { RotateCw } from 'lucide-react';

const WorkoutUpdateStatus = ({ workoutUpdateStatus }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    if (workoutUpdateStatus && workoutUpdateStatus.nextAllowedUpdateTime) {
      const updateTimer = setInterval(() => {
        const now = new Date().getTime();
        const nextUpdate = new Date(workoutUpdateStatus.nextAllowedUpdateTime).getTime();
        const remaining = Math.max(0, Math.floor((nextUpdate - now) / 1000));
        setTimeRemaining(remaining);

        if (remaining <= 0) {
          clearInterval(updateTimer);
        }
      }, 1000);

      return () => clearInterval(updateTimer);
    }
  }, [workoutUpdateStatus]);

  if (!workoutUpdateStatus || timeRemaining <= 0) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
      <RotateCw size={24} style={{ marginRight: '12px', color: '#1976d2' }} />
      <Typography variant="body2">
        Workout calendar will be updated in {timeRemaining} seconds
      </Typography>
      <CircularProgress size={20} sx={{ ml: 2 }} />
    </Box>
  );
};

export default WorkoutUpdateStatus;
