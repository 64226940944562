import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled, useTheme } from "@mui/material/styles";

const Label = styled(Typography)({
  color: "#757575",
  fontSize: "16px",
  textTransform: "uppercase",
  fontWeight: "bold",
});

const EditableTextField = ({ label, value, onChange }) => {
  return (
    <Grid container spacing={0}>
      <Grid xs={3} alignSelf={"center"} paddingBottom={"10px"}>
        <Label>{label}</Label>
      </Grid>
      <Grid xs={9}>
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default EditableTextField;
