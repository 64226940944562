import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function HowItWorks() {
  return (
    <div className="works_section" id="features">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Box>
              <Typography variant="h3" component="div" gutterBottom>
                <h5>How it works</h5>
                <h2>
                  In just a few steps get perfect <br></br>workouts straight to
                  your device.
                </h2>
              </Typography>
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Box>
              <Typography variant="h3" component="div" gutterBottom>
                <h3>1. Track your workout</h3>
              </Typography>
              <Typography variant="p" component="div" gutterBottom>
                <p>
                  Record your training on your favourite device (watch / bike
                  computer) or app. Sync your device to strava and
                  intervals.icu.
                </p>
              </Typography>
            </Box>
          </div>
          <div className="col-md-4">
            <Box>
              <Typography variant="h3" component="div" gutterBottom>
                <h3>2. Adaptive Training Calendar</h3>
              </Typography>
              <Typography variant="p" component="div" gutterBottom>
                <p>
                  The training calendar is always current and it uses your
                  current fitness, form, kind of zones hit in the last few
                  workouts, training settings etc. The training will happen in
                  blocks of 4 weeks with regular test to check for improvements.
                </p>
              </Typography>
            </Box>
          </div>
          <div className="col-md-4">
            <Box>
              <Typography variant="h3" component="div" gutterBottom>
                <h3>3. Continous Messaging</h3>
              </Typography>
              <Typography variant="p" component="div" gutterBottom>
                <p>
                  The AI engine constantly processes your workouts in real time
                  and keeps updating the training plan. In case you miss a
                  workout or change any settings the adaptation will be insant
                  and workouts will be available on your favioure device.{" "}
                </p>
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
