const mapWorkoutsToEvents = (data) => {
  if (!data) return [];

  const allEvents = [];
  const trainingBlocks = data.plan || [];

  // Add all workout events
  if (data.workouts) {
    data.workouts.forEach(day => {
      const matchingBlock = trainingBlocks.find(block => 
        day.workoutdate >= block.startDate && 
        day.workoutdate <= block.endDate
      );

      day.workouts.forEach(workout => {
        allEvents.push({
          ...workout,
          date: day.workoutdate,
          woType: workout.woType || workout.type,
          completed: false,
          eventtype: day.type,
          load: Math.round(
            workout.load_edited ||
            workout.tss ||
            workout.pace_load ||
            workout.heart_rate_load ||
            workout.training_load
          ),
          duration:
            workout.duration !== undefined
              ? Math.round(workout.workout_doc?.duration / 60)
              : workout.duration,
          structure: workout.workout_doc,
          trainingBlock: matchingBlock?.trainingBlock || null,
          planTotalLoad: matchingBlock?.totalLoad || null
        });
      });
    });
  }

  // Add plan data for future weeks that don't have workouts yet
  trainingBlocks.forEach(block => {
    const blockStartDate = new Date(block.startDate);
    const blockEndDate = new Date(block.endDate);
    const today = new Date();

    // Only process future weeks
    if (blockStartDate > today) {
      // Create a placeholder event for the week
      allEvents.push({
        date: block.startDate,
        eventtype: 'plan',
        trainingBlock: block.trainingBlock,
        planTotalLoad: block.totalLoad
      });
    }
  });

  return allEvents;
};

export default mapWorkoutsToEvents;
