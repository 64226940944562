import axios from 'axios';

export const fetchUserSettings = async (accessToken, email) => {
  try {
    const response = await axios.get(
      'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        params: { email }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Failed to fetch user settings:', error);
    return null;
  }
};
