import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

const styles = {
  dial: {
    display: "inline-block",
    // width: `250px`,
    height: `auto`,
    color: "#000",
    border: "0.0px solid #fff",
    padding: "2px",
  },
  title: {
    fontSize: "1em",
    color: "#000",
  },
};

const Speedometer = ({ id, value, title, athletetype }) => {
  var segmentLables,
    customSegmentStops = [0, 20, 40, 60, 90, 120];
  if (athletetype == "Run") {
    customSegmentStops = [0, 20, 50, 70, 90, 120];

    segmentLables = [
      {
        text: "5k",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "10k",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "21k",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "42k",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "Ultra",
        position: "INSIDE",
        color: "#555",
      },
    ];
  } else if (athletetype == "Bike") {
    customSegmentStops = [0, 20, 40, 80, 120];
    segmentLables = [
      {
        text: "",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "100k",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "SR / Race",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "Ultra",
        position: "INSIDE",
        color: "#555",
      },
    ];
  } else {
    customSegmentStops = [0, 20, 40, 60, 90, 120];
    segmentLables = [
      {
        text: "",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "Sprint",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "Tri",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "70.3",
        position: "INSIDE",
        color: "#555",
      },
      {
        text: "IM",
        position: "INSIDE",
        color: "#555",
      },
    ];
  }

  return (
    <div style={styles.dial}>
      <ReactSpeedometer
        // position="OUTSIDE"
        forceRender={true}
        maxValue={120}
        minValue={0}
        width={300}
        height={200}
        value={value}
        needleTransition="easeQuadIn"
        needleTransitionDuration={1000}
        needleColor="red"
        startColor="orange"
        segments={segmentLables.length}
        // endColor="blue"
        needleHeightRatio={0.7}
        maxSegmentLabels={5}
        hi
        customSegmentLabels={segmentLables}
        customSegmentStops={customSegmentStops}
      />
    </div>
  );
};

export default Speedometer;
