import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const bikePowerZones = [30, 55, 75, 90, 105, 140];
const runPowerZones = [65, 80, 90, 100, 115, 150];
const hrZones = [65, 85, 90, 95, 100, 130];
const paceZones = [75, 80, 90, 95, 100, 150];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  'td, th': {
    borderStyle: '1px solid',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0),
  paddingLeft: theme.spacing(1),
  textAlign: 'center',
  [`&.${tableCellClasses.head}`]: {
    fontSize: 20,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// Dummy sports settings for testing
const dummySportsSettings = [
  {
    types: ['Run', 'Bike'],
    ftp: 300,
    threshold_pace: 3.5, // m/s
    lthr: 160
  }
];

function ZoneBreakup({ sportsSettings = dummySportsSettings }) {
  function getMinutesSecondsFormat(mps) {
    if (!mps) return null;
    var mpkm = 1000 / (mps * 60);
    var n = new Date(0, 0);
    n.setMinutes(+mpkm * 60);
    return n.toTimeString().slice(0, 5);
  }

  return (
    <div className="zone_section">
      <div className="container">
        <div className="row">
          {sportsSettings.map((e, indx) =>
            e.types.map((type, index) => (
              <div key={`zbdiv${indx}${index}`}>
                <Typography variant="h5" align="left">{type}</Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" sx={{ marginBottom: '15px', tableLayout: 'fixed' }}>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Power</StyledTableCell>
                        <StyledTableCell>{type === 'Run' ? 'Pace' : null}</StyledTableCell>
                        <StyledTableCell>HR</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>Threshold</StyledTableCell>
                        <StyledTableCell sx={{ color: 'red' }}>{e.ftp}</StyledTableCell>
                        <StyledTableCell sx={{ color: 'red' }}>{getMinutesSecondsFormat(e.threshold_pace)}</StyledTableCell>
                        <StyledTableCell sx={{ color: 'red' }}>{e.lthr}</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {[1, 2, 3, 4, 5].map(zone => (
                        <StyledTableRow key={`zone${zone}`}>
                          <StyledTableCell>{`Zone ${zone}`}</StyledTableCell>
                          <StyledTableCell>
                            {`${Math.round((bikePowerZones[zone - 1] * e.ftp) / 100)} - ${Math.round((bikePowerZones[zone] * e.ftp) / 100) - 1}`}
                          </StyledTableCell>
                          <StyledTableCell>
                            {type === 'Run' ? `${getMinutesSecondsFormat((paceZones[zone] * e.threshold_pace) / 100)} - ${getMinutesSecondsFormat((paceZones[zone - 1] * e.threshold_pace) / 100)}` : null}
                          </StyledTableCell>
                          <StyledTableCell>
                            {`${Math.round((hrZones[zone - 1] * e.lthr) / 100)} - ${Math.round((hrZones[zone] * e.lthr) / 100) - 1}`}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default ZoneBreakup;
