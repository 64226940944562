import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  Avatar,
  Card,
  CardContent,
  Grid,
  Button,
  CircularProgress,
  Stack,
  alpha,
  Chip
} from '@mui/material';
import {
  User,
  Mail,
  Phone,
  Calendar,
  Weight,
  Clock,
  UserCircle2,
  CheckCircle2,
  AlertCircle,
  Save,
  Edit2,
  X
} from 'lucide-react';

// Consistent styling for form fields
const fieldStyles = {
  backgroundColor: alpha('#fff', 0.6),
  '& .MuiInputBase-input': {
    fontSize: '0.875rem',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'background.paper',
  },
  '& .MuiSelect-select': {
    fontSize: '0.875rem',
  }
};

// Edit button component
const SettingsEditButton = ({ isEditing, onEdit, onSave, onCancel, isSaving }) => {
  if (isEditing) {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          size="small"
          sx={{ mr: 1 }}
          disabled={isSaving}
          startIcon={isSaving ? <CircularProgress size={16} /> : <Save size={16} />}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCancel}
          size="small"
          disabled={isSaving}
          startIcon={<X size={16} />}
        >
          Cancel
        </Button>
      </>
    );
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onEdit}
      startIcon={<Edit2 size={16} />}
      size="small"
    >
      Edit
    </Button>
  );
};

// ProfileField component with adjusted spacing
export const ProfileField = ({ icon: Icon, label, value, error, children, isEditing, compact = false }) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'flex-start', 
      gap: 1.5,
      mb: compact ? 1.5 : 2,
      transition: 'all 0.3s ease'
    }}>
      <Box sx={{ 
        p: 0.75,
        borderRadius: 1,
        bgcolor: 'primary.lightest',
        color: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0
      }}>
        <Icon size={16} />
      </Box>
      <Box sx={{ flex: 1, minWidth: 0 }}>
        <Typography 
          variant="caption" 
          component="div"
          sx={{ 
            color: 'text.secondary',
            mb: 0.25,
            fontWeight: 500,
            textTransform: 'uppercase',
            fontSize: '0.6875rem',
            letterSpacing: '0.5px'
          }}
        >
          {label}
        </Typography>
        {isEditing ? children : (
          <Typography
            variant="body2"
            sx={{
              p: 1,
              bgcolor: 'background.default',
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider',
              fontSize: '0.875rem',
              minHeight: '2.5rem',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {value || '-'}
          </Typography>
        )}
        {error && (
          <Typography color="error" variant="caption" sx={{ mt: 0.25, display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <AlertCircle size={12} />
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

// Profile Header with adjusted size and email display
export const ProfileHeader = ({ profile, user, isEditing, onEdit, onSave, onCancel, isSaving }) => (
  <Box sx={{ 
    display: 'flex', 
    flexDirection: 'column',
    gap: 2,
    mb: 2.5,
    position: 'relative',
    zIndex: 1,
    pb: 2,
    borderBottom: '1px solid',
    borderColor: 'divider'
  }}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <Avatar
          src={user?.picture}
          alt={profile.name}
          sx={{ 
            width: 48, 
            height: 48,
            border: '2px solid',
            borderColor: 'primary.main'
          }}
        >
          {profile.name?.charAt(0)}
        </Avatar>
        <Box>
          <Typography 
            variant="h6" 
            component="h1" 
            sx={{ 
              fontSize: '1.25rem',
              fontWeight: 600,
              color: 'text.primary',
              lineHeight: 1.2,
              mb: 0.5
            }}
          >
            {profile.name}
          </Typography>
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              fontSize: '0.75rem'
            }}
          >
            <CheckCircle2 size={12} />
            Active Member
          </Typography>
        </Box>
      </Box>
      <SettingsEditButton
        isEditing={isEditing}
        onEdit={onEdit}
        onSave={onSave}
        onCancel={onCancel}
        isSaving={isSaving}
      />
    </Box>
    {/* Email display moved here */}
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      bgcolor: 'background.default',
      p: 1.5,
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider'
    }}>
      <Mail size={16} style={{ color: '#666' }} />
      <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
        Email:
      </Typography>
      <Typography variant="body2">{profile.email}</Typography>
      <Chip 
        label="Non-editable" 
        size="small" 
        sx={{ 
          ml: 'auto',
          bgcolor: 'action.hover',
          '& .MuiChip-label': {
            fontSize: '0.625rem',
          }
        }}
      />
    </Box>
  </Box>
);

// Basic Information Card
export const BasicInfoCard = ({ profile, editedProfile, handleChange, errors, isEditing }) => (
  <Card variant="outlined" sx={{ mb: 2 }}>
    <CardContent sx={{ '&:last-child': { pb: 2 } }}>
      <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
        Basic Information
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProfileField
            icon={User}
            label="Full Name"
            value={profile.name}
            error={errors.name}
            isEditing={isEditing}
            compact
          >
            <TextField
              fullWidth
              value={editedProfile.name}
              onChange={handleChange('name')}
              variant="outlined"
              error={Boolean(errors.name)}
              size="small"
              placeholder="Enter your full name"
              sx={fieldStyles}
            />
          </ProfileField>
        </Grid>

        <Grid item xs={12}>
          <ProfileField
            icon={Phone}
            label="Phone Number"
            value={profile.number}
            error={errors.number}
            isEditing={isEditing}
            compact
          >
            <TextField
              fullWidth
              value={editedProfile.number}
              onChange={handleChange('number')}
              variant="outlined"
              error={Boolean(errors.number)}
              size="small"
              placeholder="+1234567890"
              sx={fieldStyles}
            />
          </ProfileField>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

// Personal Details Card
export const PersonalDetailsCard = ({ profile, editedProfile, handleChange, errors, isEditing, timezones, gender }) => (
  <Card variant="outlined">
    <CardContent sx={{ '&:last-child': { pb: 2 } }}>
      <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600, color: 'primary.main' }}>
        Personal Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1.5}>
            <ProfileField
              icon={Calendar}
              label="Age"
              value={`${profile.age} years`}
              error={errors.age}
              isEditing={isEditing}
              compact
            >
              <TextField
                fullWidth
                type="number"
                value={editedProfile.age}
                onChange={handleChange('age')}
                variant="outlined"
                error={Boolean(errors.age)}
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">years</InputAdornment>
                }}
                sx={fieldStyles}
              />
            </ProfileField>

            <ProfileField
              icon={Weight}
              label="Weight"
              value={`${profile.weight} kg`}
              error={errors.weight}
              isEditing={isEditing}
              compact
            >
              <TextField
                fullWidth
                type="number"
                value={editedProfile.weight}
                onChange={handleChange('weight')}
                variant="outlined"
                error={Boolean(errors.weight)}
                size="small"
                InputProps={{
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>
                }}
                sx={fieldStyles}
              />
            </ProfileField>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack spacing={1.5}>
            <ProfileField
              icon={UserCircle2}
              label="Gender"
              value={profile.gender}
              error={errors.gender}
              isEditing={isEditing}
              compact
            >
              <FormControl 
                fullWidth 
                error={Boolean(errors.gender)} 
                size="small"
                sx={fieldStyles}
              >
                <Select
                  value={editedProfile.gender || ''}
                  onChange={handleChange('gender')}
                >
                  {gender.map((g) => (
                    <MenuItem key={g.value} value={g.value}>{g.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ProfileField>

            <ProfileField
              icon={Clock}
              label="Timezone"
              value={profile.timezone}
              error={errors.timezone}
              isEditing={isEditing}
              compact
            >
              <FormControl 
                fullWidth 
                error={Boolean(errors.timezone)} 
                size="small"
                sx={fieldStyles}
              >
                <Select
                  value={editedProfile.timezone}
                  onChange={handleChange('timezone')}
                >
                  {timezones.map((tz) => (
                    <MenuItem key={tz} value={tz}>{tz}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ProfileField>
          </Stack>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default {
  ProfileField,
  ProfileHeader,
  BasicInfoCard,
  PersonalDetailsCard,
  SettingsEditButton
};
