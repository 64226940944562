import React, { useState, useEffect, useMemo } from "react";
import { Card, CardContent, Typography, Box, Link } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import TodayIcon from "@mui/icons-material/Today";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"; // Importing MUI Icon

import {
  CustomCard,
  CustomCardContent,
  Heading,
  InfoText,
  iconStyle,
  WorkoutSubCard,
  IconContainer,
  WorkoutSubCardContent,
  WorkoutNameTypography,
  WorkoutTypeTypography,
  IconTypography,
  LoadBadge,
  ActivityIcon,
  LinkBox,
} from "../style/CustomCard";

const WorkoutCard = ({ data, dayOffset = 0, onClick }) => {
  const theme = useTheme();
  const [workouts, setWorkouts] = useState([]);
  const handleCardClick = (workoutData) => {
    if (onClick) {
      onClick(workoutData);
    }
  };
  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  };
  const daySpecificStyle = useMemo(() => {
    const primaryColor = theme.palette.primary.main;
    const shadowColor = `rgba(${hexToRgb(primaryColor)}, 0.25)`; // Convert hex color to rgb and apply alpha for shadow
    if (dayOffset === 0) {
      // Vibrant color for Today's Workouts with primary color shadow
      return {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText,
        boxShadow: `0px 4px 20px ${shadowColor}`, // Shadow with primary color
      };
    } else {
      // Muted color for Tomorrow's Workouts
      return {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.primary,
        // Optional inner shadow for a subtle depth, if needed
      };
    }
  }, [theme, dayOffset]);

  useEffect(() => {
    if (data && data.workouts) {
      const targetDate = moment().add(dayOffset, "days").format("YYYY-MM-DD");
      const filteredWorkouts = data.workouts.filter(
        (workout) =>
          workout.workoutdate === targetDate && workout.type !== "activity"
      );
      setWorkouts(filteredWorkouts);
    }
  }, [data, dayOffset]);

  // Memoize the title based on dayOffset to avoid unnecessary recalculations
  const title = useMemo(() => {
    return dayOffset === 0 ? "Today's Workouts" : "Tomorrow's Workouts";
  }, [dayOffset]);

  const renderActivityIcon = (type) => {
    let IconComponent;
    switch (type) {
      case "Run":
        IconComponent = DirectionsRunIcon;
        break;
      case "Ride":
        IconComponent = DirectionsBikeIcon;
        break;
      case "Swim":
        return <PoolIcon color="info" />;
      case "Strength":
        return <FitnessCenterIcon color="error" />;
      default:
        return null;
    }
    return <ActivityIcon as={IconComponent} type={type} />;
  };
  return (
    <LinkBox
      component={Link}
      to="/calendar"
      sx={{ textDecoration: "none", paddingBottom: "0px" }}
    >
      <CustomCard sx={daySpecificStyle}>
        <CustomCardContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {renderActivityIcon(dayOffset === 0 ? "today" : "tomorrow")}
            <Heading
              gutterBottom
              sx={{ fontWeight: dayOffset === 0 ? "bold" : "normal" }}
            >
              {dayOffset === 0 ? (
                <WbSunnyIcon color="primary" style={iconStyle} />
              ) : (
                <CalendarTodayIcon color="secondary" style={iconStyle} />
              )}

              {title}
            </Heading>
          </Box>
          {workouts.length > 0 ? (
            workouts.map((workout, index) => (
              <Box key={index}>
                {workout.workouts.map((w, idx) => (
                  <WorkoutSubCard key={idx} onClick={() => handleCardClick(w)}>
                    <WorkoutSubCardContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {renderActivityIcon(w.type)}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <WorkoutNameTypography>
                              {w.title}
                            </WorkoutNameTypography>
                            <WorkoutTypeTypography>
                              {w.type}
                            </WorkoutTypeTypography>
                          </Box>
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mt: 1 }}
                        >
                          {w.duration && (
                            <>
                              <AccessTimeIcon fontSize="small" />
                              <IconTypography sx={{ ml: 0.5 }}>
                                {w.duration} min
                              </IconTypography>
                            </>
                          )}

                          {w.training_load !== null &&
                            w.training_load !== undefined &&
                            w.training_load > 0 && (
                              <>
                                <Typography component="span" sx={{ ml: 5 }}>
                                  Load
                                </Typography>
                                <LoadBadge sx={{ ml: 0.5 }}>
                                  {Math.round(w.training_load)}
                                </LoadBadge>
                              </>
                            )}
                        </Box>
                      </Box>
                    </WorkoutSubCardContent>
                    <IconContainer>
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconContainer>
                  </WorkoutSubCard>
                ))}
              </Box>
            ))
          ) : (
            <Typography>
              No workouts planned for {dayOffset === 0 ? "today" : "tomorrow"}.
            </Typography>
          )}
        </CustomCardContent>
      </CustomCard>
    </LinkBox>
  );
};

export default WorkoutCard;

//styles
