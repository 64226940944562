import React from 'react';
import {
  Box,
  Snackbar,
  Alert,
  Grid,
  Paper,
  Typography
} from '@mui/material';
import { useIntensityLogic } from './hooks/useIntensityLogic';
import IntegratedCyclingMetrics from './components/integrated/IntegratedCyclingMetrics';
import IntegratedRunningMetrics from './components/integrated/IntegratedRunningMetrics';
import SportCard from './components/layout/SportCard';

const IntensitySection = ({ workoutIntensity, accessToken, userEmail, onRefresh }) => {
  const {
    localWorkoutIntensity,
    setLocalWorkoutIntensity,
    isEditing,
    setIsEditing,
    isSaving,
    snackbar,
    setSnackbar,
    handleSave
  } = useIntensityLogic(workoutIntensity, accessToken, userEmail, onRefresh);

  const handleCyclingMetricsChange = (event, newMetrics) => {
    if (newMetrics?.length === 0) return;
    setLocalWorkoutIntensity(prev => ({
      ...prev,
      cycling: {
        ...prev.cycling,
        metrics: newMetrics,
        ftp: {
          ...prev.cycling.ftp,
          enabled: newMetrics.includes('Power')
        },
        lthr: {
          ...prev.cycling.lthr,
          enabled: newMetrics.includes('HR')
        }
      }
    }));
  };

  const handleRunningMetricChange = (event) => {
    const value = event.target.value;
    if (!['Power', 'HR', 'Pace'].includes(value)) return;

    setLocalWorkoutIntensity(prev => ({
      ...prev,
      running: {
        ...prev.running,
        metrics: [value],
        ftp: {
          ...prev.running.ftp,
          enabled: value === 'Power'
        },
        lthr: {
          ...prev.running.lthr,
          enabled: value === 'HR'
        },
        thresholdPace: {
          ...prev.running.thresholdPace,
          enabled: value === 'Pace'
        }
      }
    }));
  };

  const handleThresholdChange = (sport, field, subfield, value) => {
    setLocalWorkoutIntensity(prev => ({
      ...prev,
      [sport]: {
        ...prev[sport],
        [field]: {
          ...prev[sport][field],
          [subfield]: value
        }
      }
    }));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: 2,
          backgroundColor: 'background.default'
        }}
      >
        <Box sx={{
          p: { xs: 2, sm: 3 },
          borderBottom: '1px solid',
          borderColor          : 'divider'
        }}>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Training Zones
          </Typography>
        </Box>

        <Box sx={{ p: { xs: 2, sm: 3 } }}>
          <Grid container spacing={3}>
            {localWorkoutIntensity.cycling.enabled && (
              <Grid item xs={12} md={6}>
                <SportCard 
                  title="Cycling" 
                  isEditing={isEditing}
                  onSave={handleSave}
                  onCancel={() => setIsEditing(!isEditing)}
                  isSaving={isSaving}
                >
                  <IntegratedCyclingMetrics
                    selectedMetrics={localWorkoutIntensity.cycling.metrics}
                    thresholds={localWorkoutIntensity.cycling}
                    isEditing={isEditing}
                    onMetricsChange={handleCyclingMetricsChange}
                    onThresholdChange={(field, subfield, value) => 
                      handleThresholdChange('cycling', field, subfield, value)}
                  />
                </SportCard>
              </Grid>
            )}

            {localWorkoutIntensity.running.enabled && (
              <Grid item xs={12} md={6}>
                <SportCard 
                  title="Running" 
                  isEditing={isEditing}
                  onSave={handleSave}
                  onCancel={() => setIsEditing(!isEditing)}
                  isSaving={isSaving}
                >
                  <IntegratedRunningMetrics
                    selectedMetric={localWorkoutIntensity.running.metrics}
                    thresholds={localWorkoutIntensity.running}
                    isEditing={isEditing}
                    onChange={handleRunningMetricChange}
                    onThresholdChange={(field, subfield, value) => 
                      handleThresholdChange('running', field, subfield, value)}
                  />
                </SportCard>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default IntensitySection;
