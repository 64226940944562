import React, { useEffect, useState } from 'react';
import { Dialog, IconButton, Box, CircularProgress } from '@mui/material';
import { X } from 'lucide-react';
import ProfileChatInterface from './ProfileChatInterface';
import { fetchUserSettings } from '../services/settingsService';

const ChatPopup = ({ open, onClose, onSave, accessToken, profile }) => {
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getSettings = async () => {
      if (open && profile?.email) {
        setIsLoading(true);
        try {
          const userSettings = await fetchUserSettings(accessToken, profile.email);
          // Pass both profile and connections data
          setSettings({
            ...userSettings.profile,
            stravaConnected: userSettings.connections?.strava?.auth || false,
            // Add other connection statuses if needed
          });
        } catch (error) {
          console.error('Error fetching settings:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    getSettings();
  }, [open, profile?.email, accessToken]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          height: '80vh',
          maxHeight: '700px'
        }
      }}
    >
      <Box sx={{ position: 'absolute', right: 8, top: 8, zIndex: 1 }}>
        <IconButton onClick={onClose} size="small">
          <X size={20} />
        </IconButton>
      </Box>
      {isLoading ? (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '100%'
        }}>
          <CircularProgress />
        </Box>
      ) : (
        <ProfileChatInterface 
          onSave={onSave}
          onSwitchView={onClose}
          profile={settings}
          accessToken={accessToken}
        />
      )}
    </Dialog>
  );
};

export default ChatPopup;
