export const getTimeBasedGreeting = () => {
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 12) return "Good morning";
  if (hour >= 12 && hour < 17) return "Good afternoon";
  if (hour >= 17 && hour < 22) return "Good evening";
  return "Hello";
};

export const getInitialGreeting = () => {
  const greeting = getTimeBasedGreeting();
  return {
    message: `${greeting}! 👋 How can I help you today?`,
    options: [
      { id: 'profile_setup', label: 'Help me set up my profile' },
      { id: 'update_settings', label: 'Update my existing settings' },
      { id: 'connect_services', label: 'Connect fitness services' },
      { id: 'general_help', label: 'General help and information' }
    ]
  };
};
