import React, { useRef, useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import useChatState from './chat/useChatState';
import useMessageHandling from './chat/useMessageHandling';
import useStravaHandling from './chat/useStravaHandling';
import useInputHandling from './chat/useInputHandling';
import useProfileUpdates from './chat/useProfileUpdates';
import MessageBubble from '../components/messages/MessageBubble';
import InitialMenu from '../components/InitialMenu';
import GenderSelection from '../components/inputs/GenderSelection';
import ChatProgress from '../components/ChatProgress';
import { getInitialGreeting } from '../utils/timeGreeting';
import { questions } from '../constants/chatConstants';
import {
    TrainingApproachSelection,
    EventDetailsForm,
    TrainingMethodSelection,
    FixedDaysForm,
    TimeBasedForm,
    DisciplineSelector
} from '../components/inputs/workout';

const useChat = ({
    promptTemplates,
    fieldValidators,
    successMessages,
    steps,
    onSave,
    currentProfile,
    accessToken
}) => {
    const messagesContainerRef = useRef(null);
    const [showInitialMenu, setShowInitialMenu] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const [localStravaConnected, setLocalStravaConnected] = useState(false);
    const [setupStarted, setSetupStarted] = useState(false);

    const initialGreeting = getInitialGreeting();

    const {
        messages,
        setMessages,
        updates,
        setUpdates,
        currentStep,
        setCurrentStep,
        isComplete,
        setIsComplete
    } = useChatState(
        steps,
        currentProfile,
        promptTemplates,
        successMessages
    );

    const { isTyping, simulateTyping } = useMessageHandling(setMessages);

    const addMessage = async (role, content, subtext = null) => {
        if (typeof content === 'string') {
            let formattedContent = content;
            Object.keys(updates).forEach(key => {
                formattedContent = formattedContent.replace(`{${key}}`, updates[key] || 'Not set');
            });

            if (role === 'assistant') {
                setMessages(prev => [...prev, { role, content: '', subtext }]);
                await simulateTyping(formattedContent);
            } else {
                setMessages(prev => [...prev, { role, content: formattedContent, subtext }]);
            }
            if (messagesContainerRef.current) {
                messagesContainerRef.current.scrollTo({
                    top: messagesContainerRef.current.scrollHeight,
                    behavior: 'smooth'
                });
            }
        }
    };

    const { isProcessing, processInput } = useInputHandling({
        currentStep,
        steps,
        updates,
        setUpdates,
        addMessage,
        onSave,
        currentProfile,
        setCurrentStep,
        setIsComplete,
        fieldValidators,
        successMessages,
        promptTemplates,
        accessToken
    });

    const handleInitialMenuSelect = async (option) => {
        switch (option) {
            case 'profile_setup':
                setShowInitialMenu(false);
                await addMessage('user', 'I need help setting up my profile');
                await addMessage('assistant', "I'll help you set up your profile. Let's get started! 👋");
                break;
            case 'update_settings':
                await addMessage('user', 'I want to update my settings');
                await addMessage('assistant', "I'll help you update your settings. What would you like to change?");
                break;
            case 'connect_services':
                await addMessage('user', 'I want to connect my fitness services');
                await addMessage('assistant', "I can help you connect your fitness services. Which service would you like to connect?");
                break;
            case 'general_help':
                await addMessage('user', 'I need general help');
                await addMessage('assistant', "I'm here to help! What questions do you have?");
                break;
        }
    };

    const handleStravaComplete = (success) => {
        if (success) {
            setLocalStravaConnected(true);
            setIsComplete(true);
        }
    };

    const {
        showStravaConfirmation,
        isVerifying,
        handleStravaConnect,
        handleConfirmStrava
    } = useStravaHandling({
        addMessage,
        accessToken,
        email: currentProfile?.email,
        onComplete: handleStravaComplete
    });

    const {
        selectedGender,
        handleGenderSelection: handleGenderSelectionBase
    } = useProfileUpdates();

    const handleGenderSelection = (gender) => handleGenderSelectionBase(gender, processInput);

    const handleTrainingApproachSelect = async (approach) => {
        await processInput(approach);
        const disciplineStepIndex = steps.findIndex(step => step === 'discipline');
        if (disciplineStepIndex !== -1) {
            setCurrentStep(disciplineStepIndex);
            await addMessage('assistant', promptTemplates['discipline']);
        }
    };

    const handleDisciplineSelect = async (discipline) => {
        await processInput(discipline);
        if (updates.training_approach === 'event') {
            const eventDetailsIndex = steps.findIndex(step => step === 'event_details');
            if (eventDetailsIndex !== -1) {
                setCurrentStep(eventDetailsIndex);
                await addMessage('assistant', promptTemplates['event_details']);
            }
        } else if (updates.training_approach === 'custom') {
            const trainingMethodIndex = steps.findIndex(step => step === 'training_method');
            if (trainingMethodIndex !== -1) {
                setCurrentStep(trainingMethodIndex);
                await addMessage('assistant', promptTemplates['training_method']);
            }
        }
    };

    const handleEventDetailsSubmit = async (details) => {
        await processInput(details);
    };

    const handleTrainingMethodSelect = async (method) => {
        await processInput(method);
    };

    const handleFixedDaysSubmit = async (settings) => {
        await processInput(settings);
    };

    const handleTimeBasedSubmit = async (settings) => {
        await processInput(settings);
    };

    // Initialize with the time-based greeting
    useEffect(() => {
        if (!isInitialized) {
            addMessage('assistant', initialGreeting.message);
            setIsInitialized(true);
        }
    }, [isInitialized, initialGreeting.message]);

    const shouldSkipQuestion = (questionId) => {
        const question = questions.find(q => q.id === questionId);
        if (question?.conditional) {
            return !question.conditional(updates);
        }
        return false;
    };

    const getCurrentField = () => {
        const currentField = steps[currentStep];
        if (shouldSkipQuestion(currentField)) {
            setCurrentStep(prev => prev + 1);
            return steps[currentStep + 1];
        }
        return currentField;
    };

    const renderInput = () => {
        if (showInitialMenu) {
            return <InitialMenu onSelect={handleInitialMenuSelect} />;
        }

        if (isComplete) return null;

        const currentField = getCurrentField();

        if (!currentField) return null;

        switch (currentField) {
            case 'gender':
                return (
                    <GenderSelection
                        selectedGender={selectedGender}
                        onSelect={handleGenderSelection}
                    />
                );
            case 'training_approach':
                return (
                    <TrainingApproachSelection
                        onSelect={handleTrainingApproachSelect}
                    />
                );
            case 'discipline':
                return (
                    <DisciplineSelector
                        onSelect={handleDisciplineSelect}
                        selectedDiscipline={updates.discipline}
                    />
                );
            case 'event_details':
                return (
                    <EventDetailsForm
                        onSubmit={handleEventDetailsSubmit}
                    />
                );
            case 'training_method':
                return (
                    <TrainingMethodSelection
                        onSelect={handleTrainingMethodSelect}
                    />
                );
            case 'fixed_days_settings':
                return (
                    <FixedDaysForm
                        onSubmit={handleFixedDaysSubmit}
                    />
                );
            case 'time_based_settings':
                return (
                    <TimeBasedForm
                        onSubmit={handleTimeBasedSubmit}
                    />
                );
            default:
                return null;
        }
    };

    const renderedMessages = messages?.map((message, index) => (
        <MessageBubble
            key={index}
            message={message}
            isTyping={isTyping}
            isLastMessage={index === messages.length - 1}
        />
    )) || [];

    return {
        messages: renderedMessages,
        input: (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, px: 2, pb: 2 }}>
                {setupStarted && !isComplete && currentStep < steps.length && !['strava_connect'].includes(getCurrentField()) && (
                    <ChatProgress
                        currentStep={currentStep + 1}
                        totalSteps={steps.length}
                        currentField={getCurrentField()}
                    />
                )}
                {renderInput()}
                {showStravaConfirmation && (
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'center',
                        p: 2,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                    }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleConfirmStrava(true)}
                            disabled={isVerifying}
                        >
                            Yes, Connected Successfully
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleConfirmStrava(false)}
                            disabled={isVerifying}
                        >
                            No, Not Yet
                        </Button>
                    </Box>
                )}
            </Box>
        ),
        messagesContainerRef,
        isComplete,
        isProcessing,
        currentStep,
        handleGenderSelection
    };
};

export default useChat;