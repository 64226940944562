import React from 'react';
import { Box, Typography, ButtonBase, Grid, styled } from '@mui/material';

const DayButton = styled(ButtonBase)(({ theme, isSelected }) => ({
  width: '100%',
  padding: '12px',
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.divider}`,
  backgroundColor: isSelected ? `${theme.palette.primary.main}10` : theme.palette.background.paper,
  transition: 'all 0.2s ease',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '4px',
  '&:hover': {
    backgroundColor: isSelected ? `${theme.palette.primary.main}15` : theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    backgroundColor: theme.palette.action.disabledBackground,
  }
}));

const DaysPerWeekSelector = ({ value, onChange, disabled }) => {
  const daysOptions = ['3', '4', '5', '6'];

  const getRecommendation = (days) => {
    switch(days) {
      case '3': return 'Light';
      case '4': return 'Balanced';
      case '5': return 'Intensive';
      case '6': return 'Advanced';
      default: return '';
    }
  };

  return (
    <Grid container spacing={1.5}>
      {daysOptions.map((days) => (
        <Grid item xs={6} sm={3} key={days}>
          <DayButton
            onClick={() => !disabled && onChange(days)}
            isSelected={value === days}
            disabled={disabled}
          >
            <Typography variant="h6" color={value === days ? 'primary' : 'text.primary'}>
              {days}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {getRecommendation(days)}
            </Typography>
          </DayButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default DaysPerWeekSelector;
