import React, { useState } from 'react';
import {
    Box,
    TextField,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Select,
    CircularProgress
} from '@mui/material';
import { eventTypes } from '../../../constants/workoutConstants';

const EventDetailsForm = ({ onSubmit }) => {
    const [eventDetails, setEventDetails] = useState({
        name: '',
        date: '',
        type: '',
        subtype: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            await onSubmit(eventDetails);
        } finally {
            setIsSubmitting(false);
        }
    };

    const minDate = new Date().toISOString().split('T')[0];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
            <TextField
                label="Event Name"
                value={eventDetails.name}
                onChange={(e) => setEventDetails({ ...eventDetails, name: e.target.value })}
                fullWidth
                disabled={isSubmitting}
            />
            <TextField
                label="Event Date"
                type="date"
                value={eventDetails.date}
                onChange={(e) => setEventDetails({ ...eventDetails, date: e.target.value })}
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: minDate }}
                disabled={isSubmitting}
            />
            <FormControl fullWidth>
                <InputLabel>Event Type</InputLabel>
                <Select
                    value={eventDetails.type}
                    onChange={(e) => setEventDetails({
                        ...eventDetails,
                        type: e.target.value,
                        subtype: ''
                    })}
                    label="Event Type"
                    disabled={isSubmitting}
                >
                    {Object.keys(eventTypes).map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {eventDetails.type && eventTypes[eventDetails.type].length > 0 && (
                <FormControl fullWidth>
                    <InputLabel>Event Subtype</InputLabel>
                    <Select
                        value={eventDetails.subtype}
                        onChange={(e) => setEventDetails({
                            ...eventDetails,
                            subtype: e.target.value
                        })}
                        label="Event Subtype"
                        disabled={isSubmitting}
                    >
                        {eventTypes[eventDetails.type].map((subtype) => (
                            <MenuItem key={subtype} value={subtype}>{subtype}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!eventDetails.name || !eventDetails.date || !eventDetails.type || isSubmitting}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
                {isSubmitting ? 'Saving...' : 'Save Event Details'}
            </Button>
        </Box>
    );
};

export default EventDetailsForm;
