import { styled } from "@mui/system";
import { Box, Card, CardContent, Typography } from "@mui/material";

export const CustomCard = styled(Card)(({ theme }) => ({
  boxShadow: `0 4px 8px ${theme.palette.grey[400]}`,
  borderRadius: 12,
  background: "linear-gradient(145deg, #e6e6e6, #ffffff)",
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: `0 6px 12px ${theme.palette.grey[500]}`,
  },
  paddingBottom: "0px",
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));
export const CustomCardContent = styled(CardContent)({
  textAlign: "left",
  paddingBottom: "0px",
  padding: 8,
  flexGrow: 1
});
export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "1.4rem",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
}));
export const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginBottom: "4px",
})).withComponent("div");

export const iconStyle = {
  verticalAlign: "middle",
  marginRight: "8px",
  fontSize: "2rem",
};
export const Subheading = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem", // Slightly smaller than Heading
  fontWeight: "bold", // Keeps it bold, but can be adjusted to 'normal' if desired
  color: theme.palette.secondary, // Optional: Use secondary color or keep primary
  marginBottom: theme.spacing(0.5), // Less margin than Heading
}));

export const WorkoutSubCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: `0 4px 8px rgba(0,0,0,0.2)`,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s, box-shadow 0.3s, transform 0.3s",
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between", // To align content and icon
  alignItems: "center",
  // padding: theme.spacing(1), // Ensuring padding for inner spacing
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
    boxShadow: `0 4px 8px rgba(0,0,0,0.2)`,
    transform: "scale(1.03)",
  },
  paddingBottom: "0px",
}));

// For the icon at the end of the card
export const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const WorkoutSubCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
});

export const WorkoutNameTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.2rem",
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(1),
}));

export const WorkoutTypeTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(1),
}));

export const IconTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "1rem",
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(1),
}));

export const LoadBadge = styled("div")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.info.main,
  color: theme.palette.info.contrastText,
  borderRadius: "12px",
  padding: "2px 8px",
  fontSize: "0.9rem", // Slightly larger font for load
  fontWeight: "bold",
  marginLeft: theme.spacing(1),
}));

export const ActivityIcon = styled("span")(({ theme, type }) => ({
  color: type === "Run" ? theme.palette.info.main : theme.palette.warning.main, // Adjusted icon colors
  marginRight: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));
// Adjust the DateTypography if used for consistency

export const LinkBox = styled(Box)({
  "&:hover": {
    textDecoration: "none", // or change color
  },
});
